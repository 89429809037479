<div class="l-center-membership-card" [class.cancelled]="paymentItem['status'] == 'cancelled'">
    <div class="l-indicator">
        <rwa-svg-circle-bold width="15px" height="15px" color="var(--red-100)"></rwa-svg-circle-bold>
        <div class="l-line" [class.is-last]="isLast">
            <div class="line"></div>
        </div>
    </div>

    <div class="tag" *ngIf="showTag">
        <span class="tag-text">{{ '자동 결제에 실패했어요! 결제 수단을 확인해 주세요.' }}</span>
        <rwa-text-button fontWeight="700" [showUnderLine]="false"> 자세히 보기 </rwa-text-button>
    </div>

    <div class="center-membership-card">
        <div class="cmc-top">
            <div class="cmc-top__left">
                <div class="membership-name">
                    <span>{{ membershipName }}</span>
                </div>

                <div class="membership-duration">
                    <span>
                        {{ paymentItem?.start_date | dateFormat : 'YY년 MM월 DD일' }} ~
                        {{ paymentItem?.end_date | dateFormat : 'YY년 MM월 DD일' }}
                        {{ isUsedLater ? '사용 예정' : '' }}
                    </span>
                    <rwa-badge
                        class="badge"
                        *ngIf="badgeState != 'normal'"
                        [color]="badgeStateObj[badgeState].color"
                        [bgColor]="badgeStateObj[badgeState].bgColor"
                    >
                        {{
                            badgeState == 'expirationExpected'
                                ? badgeStateObj[badgeState]['text1'] +
                                  badgeStateObj[badgeState]['day'] +
                                  badgeStateObj[badgeState]['text2']
                                : badgeStateObj[badgeState]['text']
                        }}
                    </rwa-badge>
                </div>
            </div>

            <div class="cmc-top__right">
                <ng-container *ngIf="paymentItem['status'] == 'cancelled'">
                    <div class="cmc-top__right-cancelled">
                        {{ '환불 완료' }}
                    </div>
                </ng-container>
                <ng-container *ngIf="paymentItem['status'] == 'paid'">
                    <rwa-ghost-button
                        *ngIf="showCancelPaymentButton"
                        [borderWidth]="'1px'"
                        [padding]="'5px 11px 3px'"
                        [status]="buttonLoading"
                        [loadingName]="'cancel-payment'"
                        [loadingColor]="'var(--gray-90)'"
                        (onClick)="openCancelPaymentModal()"
                    >
                        <ng-template gbtIdleContent>
                            <span class="button-text"> {{ '환불' }} </span>
                        </ng-template>
                        <ng-template gbtPendingContent>
                            <span class="button-text"> {{ '환불' }} </span>
                        </ng-template>
                    </rwa-ghost-button>
                </ng-container>
                <ng-container *ngIf="paymentItem['schedule_at']">
                    <rwa-ghost-button
                        [borderWidth]="'1px'"
                        [padding]="'5px 11px 3px'"
                        [status]="buttonLoading"
                        [loadingName]="'cancel-reserved-payment'"
                        [loadingColor]="'var(--gray-90)'"
                        (onClick)="openCancelReservedPaymentModal()"
                    >
                        <ng-template gbtIdleContent>
                            <span class="button-text"> {{ '해지' }} </span>
                        </ng-template>
                        <ng-template gbtPendingContent>
                            <span class="button-text"> {{ '해지' }} </span>
                        </ng-template>
                    </rwa-ghost-button>
                </ng-container>
            </div>
        </div>

        <div class="cmc-bottom">
            <div class="l-payment-date">
                <div class="payment-category-name">
                    {{ paidDateText }}
                </div>
                <div class="payment-category-value">
                    <span *ngIf="paymentItem['status'] == 'cancelled'">
                        {{ paymentItem['cancelled_at'] | dateFormat : 'YY년 MM월 DD일' }}
                    </span>
                    <span *ngIf="paymentItem['status'] != 'cancelled'">
                        {{ paymentItem['schedule_at'] || paymentItem['paid_at'] | dateFormat : 'YY년 MM월 DD일' }}
                    </span>
                </div>
            </div>
            <div class="divider"></div>
            <div class="l-payment-method">
                <div class="payment-category-name">{{ paymentMethodText }}</div>
                <div class="payment-category-value">
                    <span
                        class="payment-method-text"
                        rwEllipsisDropdown
                        [lineClamp]="1"
                        [edText]="paymentItem.card_name"
                        [additionalEdText]="cardNumber"
                        [additionalDropDownWidth]="'50px'"
                    ></span>
                    <span>
                        {{ cardNumber }}
                    </span>
                </div>
            </div>
            <div class="divider"></div>
            <div class="l-payment-price">
                <div class="payment-category-name">{{ priceText }}</div>
                <div class="payment-category-value">
                    <span *ngIf="paymentItem.amount != paymentItem.product_price" class="origin-price">
                        {{ paymentItem.product_price | numberWithCommas }}원
                    </span>
                    <div class="product-price" (mouseenter)="getPaymentPromotion(); setTooltipPos()">
                        <span>{{ paymentItem.amount | numberWithCommas }}원</span>
                        <div class="l-caret-circle-down-icon">
                            <div class="caret-circle-down-icon" #caret_down_icon></div>
                            <div class="l-tooltip" #l_tooltip>
                                <rwa-vacant-tooltip [tail]="isLast ? 'top' : 'bottom'" borderRadius="9px">
                                    <div class="tooltip">
                                        <ng-container *ngIf="promotionLoading == 'pending'">
                                            <div class="d-flex flex-column">
                                                <ngx-skeleton-loader
                                                    *ngFor="let __ of [1, 2]"
                                                    count="1"
                                                    appearance="line"
                                                    [theme]="{
                                                        width: '203px',
                                                        height: '22px',
                                                        'border-radius': '5px',
                                                        margin: '0 0 0 0'
                                                    }"
                                                ></ngx-skeleton-loader>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="promotionLoading == 'idle'">
                                            <ng-container *ngIf="paymentItem['status'] == 'cancelled'">
                                                <div class="tooltip__item">
                                                    <span class="category">환불 금액</span>
                                                    <span class="value">
                                                        {{ paymentItem.amount | numberWithCommas }}원
                                                    </span>
                                                </div>
                                                <div class="divider--white"></div>
                                                <div class="tooltip__item">
                                                    <span class="category">결제 금액</span>
                                                    <span class="value">
                                                        {{ paymentItem.amount | numberWithCommas }}원
                                                    </span>
                                                </div>
                                                <div class="divider--gray"></div>
                                            </ng-container>
                                            <div class="tooltip__item">
                                                <span class="category">상품 금액</span>
                                                <span class="value">
                                                    {{ paymentItem.product_price | numberWithCommas }}원
                                                </span>
                                            </div>
                                            <div class="d-flex flex-column" *ngIf="totalDiscount > 0">
                                                <div class="tooltip__item">
                                                    <span class="category"> 할인 금액 </span>
                                                    <span class="value"
                                                        >- {{ totalDiscount | numberWithCommas }}원</span
                                                    >
                                                </div>
                                                <div class="tooltip__sub-item" *ngFor="let item of promotionData">
                                                    <span class="sub-category">
                                                        <div class="ellipse-icon"></div>
                                                        {{ item.fe_title }}
                                                    </span>
                                                    <span class="sub-value">
                                                        - {{ item.discount | numberWithCommas }}원
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="tooltip__item">
                                                <span class="category">부가세 (10%)</span>
                                                <span class="value">{{ tax | numberWithCommas }}원</span>
                                            </div>
                                        </ng-container>
                                    </div>
                                </rwa-vacant-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<rwa-vacant-modal
    [(visible)]="showCancelPaymentModal"
    [blockClickOutside]="true"
    [width]="'345px'"
    [padding]="'30px 25px 25px'"
>
    <div class="l-cancel-payment-modal">
        <div class="title">
            레드웨일 {{ membershipName }}을<br />
            환불하시겠어요?
        </div>

        <div class="l-refund">
            <div class="refund__item">
                <span class="category">환불 예정 금액</span>
                <span class="value"> {{ paymentItem.amount | numberWithCommas }}원 </span>
            </div>
            <div class="divider--gray-100"></div>
            <div class="refund__item">
                <span class="category">결제 금액</span>
                <span class="value"> {{ paymentItem.amount | numberWithCommas }}원 </span>
            </div>
            <div class="divider--gray-60"></div>
            <div class="refund__item">
                <span class="category">상품 금액</span>
                <span class="value"> {{ paymentItem.product_price | numberWithCommas }}원 </span>
            </div>
            <div class="d-flex flex-column" *ngIf="totalDiscount > 0">
                <div class="refund__item">
                    <span class="category"> 할인 금액 </span>
                    <span class="value">- {{ totalDiscount | numberWithCommas }}원</span>
                </div>
                <div class="l-refund__sub-item">
                    <div class="refund__sub-item" *ngFor="let item of promotionData">
                        <span class="sub-category"> · {{ item.fe_title }} </span>
                        <span class="sub-value"> - {{ item.discount | numberWithCommas }}원 </span>
                    </div>
                </div>
            </div>
            <div class="refund__item">
                <span class="category">부가세 (10%)</span>
                <span class="value">{{ tax | numberWithCommas }}원</span>
            </div>
        </div>

        <div class="desc">
            <div class="desc__item" *ngFor="let item of cancelDesc">
                {{ item }}
            </div>
        </div>
        <div class="l-button">
            <rwa-button
                [padding]="'9px 25px 7px'"
                [height]="'45px'"
                [fontColor]="'var(--gray-90)'"
                [bgColor]="'var(--white)'"
                [hoverBgColor]="'var(--gray-30)'"
                [loadingColor]="'var(--font-color)'"
                [loadingName]="'cmc-refund-button'"
                (onClick)="showCancelPaymentModal = false"
            >
                <ng-template btIdleContent><span class="button-text">취소</span></ng-template>
            </rwa-button>
            <rwa-button
                [padding]="'9px 25px 7px'"
                [height]="'45px'"
                [status]="cancelModalButtonLoading"
                (onClick)="onCancelPaymentButtonClick('payment')"
            >
                <ng-template btIdleContent><span class="white-button-text">환불 신청</span></ng-template>
                <ng-template btPendingContent><span class="white-button-text">환불 신청</span></ng-template>
            </rwa-button>
        </div>
    </div>
</rwa-vacant-modal>

<rwa-vacant-modal
    [(visible)]="showCancelReservedPaymentModal"
    [blockClickOutside]="true"
    [width]="'345px'"
    [padding]="'30px 25px 25px'"
>
    <div class="l-cancel-payment-modal">
        <div class="title">
            레드웨일 {{ membershipName }}을<br />
            해지하시겠어요?
        </div>

        <div class="available-date">
            <span class="available-date__category"> 해지 후 사용 가능한 기간 </span>
            <span class="available-date__value">
                {{ lastAvailablePayment?.end_date | dateFormat : 'YY년 MM월 DD일' }}{{ '까지 사용 가능' }}
            </span>
        </div>

        <div class="desc">
            <div class="desc__item" *ngFor="let item of cancelDesc">
                {{ item }}
            </div>
        </div>
        <div class="l-button">
            <rwa-button
                [padding]="'9px 25px 7px'"
                [height]="'45px'"
                [fontColor]="'var(--gray-90)'"
                [bgColor]="'var(--white)'"
                [hoverBgColor]="'var(--gray-30)'"
                [loadingColor]="'var(--font-color)'"
                [loadingName]="'cmc-refund-button'"
                (onClick)="showCancelReservedPaymentModal = false"
            >
                <ng-template btIdleContent><span class="button-text">취소</span></ng-template>
            </rwa-button>
            <rwa-button
                [padding]="'9px 25px 7px'"
                [height]="'45px'"
                [status]="cancelModalButtonLoading"
                (onClick)="onCancelPaymentButtonClick('payment-scheduled')"
            >
                <ng-template btIdleContent><span class="white-button-text">해지 신청</span></ng-template>
                <ng-template btPendingContent><span class="white-button-text">해지 신청</span></ng-template>
            </rwa-button>
        </div>
    </div>
</rwa-vacant-modal>
