<div class="l-payment-card-list-item" [class.is-desktop]="isDeskTop">
    <div class="card-left">
        <div class="card-icon"></div>
        <span
            class="card-name"
            rwEllipsisDropdown
            [lineClamp]="1"
            [edText]="paymentCard.card_name"
            [additionalEdText]="cardNumber"
            [additionalDropDownWidth]="'50px'"
        >
        </span>
        <span class="card-number">{{ cardNumber }}</span>
    </div>

    <div class="card-right">
        <rwa-ghost-button
            height="37px"
            padding="5px 11px 3px"
            disableBorderColor="var(--gray-50)"
            disableFontColor="var(--gray-60)"
            fontColor="var(--gray-90)"
            [disable]="paymentCard?.checked"
            (onClick)="onRegister.emit(paymentCard)"
        >
            <ng-template gbtIdleContent><span class="button-text">자동 결제 등록</span></ng-template>
        </rwa-ghost-button>
        <rwa-icon-button
            class="pci-remove-icon"
            rwTooltip
            rwTooltipPlacement="bottom"
            rwTooltipTitle="카드 삭제하기"
            [sizeType]="'sm'"
            borderRadius="12px"
            bgColor="var(--white)"
            hoverBgColor="var(--gray-30)"
            (onClick)="onRemove.emit(paymentCard)"
        >
            <ng-template iconBtIdleContent>
                <div class="trash-icon"></div>
            </ng-template>
        </rwa-icon-button>
    </div>
</div>
