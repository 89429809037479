<ng-scrollbar [track]="'vertical'" class="rw-scroll">
    <div scrollViewport>
        <div class="l-center-info">
            <div class="l-center-avatar">
                <rwm-center-photo
                    [pictureSrc]="center?.picture"
                    (pictureChange)="onCenterPictureChange($event)"
                    (pictureRemove)="onCenterPictureRemove($event)"
                ></rwm-center-photo>

                <div class="l-center-code">
                    <div class="center-code">
                        <div class="title">센터 코드</div>
                        <div class="code">
                            {{ center.code }}
                        </div>
                    </div>
                    <rwa-text-button
                        [showUnderLine]="true"
                        [fontWeight]="'700'"
                        [fontSize]="'1.5rem'"
                        [lineHeight]="'23px'"
                        [color]="'var(--gray-90)'"
                        (onClick)="copyCenterCode()"
                    >
                        복사하기
                    </rwa-text-button>
                </div>

                <rwa-text-button
                    class="leave-center"
                    [showUnderLine]="true"
                    [fontWeight]="'700'"
                    [fontSize]="'1.5rem'"
                    [lineHeight]="'23px'"
                    [color]="'var(--gray-90)'"
                    (onClick)="openLeaveCenterModal()"
                >
                    이 센터에서 나가기
                </rwa-text-button>
            </div>

            <div class="center-info">
                <div class="center-info-header">
                    <div class="header-tag"></div>
                    <div class="header-title">기본 정보</div>
                    <div class="header-desc">
                        <div class="info-icon"></div>
                        <span>‘사진, 이름, 전화번호, 주소’는 회원에게 공개돼요.</span>
                    </div>
                </div>
                <div class="center-info-body">
                    <div class="center-info-body-item" *ngFor="let item of centerInfoList">
                        <div class="item-name">{{ item.name }}</div>
                        <div class="l-item-value">
                            <div
                                *ngIf="item.type == 'phone-number'"
                                class="item-value"
                                [class.change-button-exist]="item.changeAvailable"
                            >
                                {{ item.value }}
                            </div>
                            <div
                                *ngIf="item.type != 'phone-number'"
                                class="item-value line-ellipsis"
                                [class.change-button-exist]="item.changeAvailable"
                            >
                                <span *ngIf="item.type != 'business-license'">{{ item.value ?? '-' }}</span>
                                <span *ngIf="item.type == 'business-license' && businessLicenseLoading != 'pending'">
                                    {{ item.value ?? '-' }}
                                </span>
                                <ngx-skeleton-loader
                                    *ngIf="item.type == 'business-license' && businessLicenseLoading == 'pending'"
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                        width: '120px',
                                        height: '28px',
                                        'border-radius': '5px',
                                        margin: '8px 0 0'
                                    }"
                                ></ngx-skeleton-loader>
                            </div>

                            <rwa-ghost-button
                                *ngIf="item.changeAvailable"
                                height="37px"
                                padding="5px 11px 3px"
                                (onClick)="item.onChangeClick()"
                            >
                                <ng-template gbtIdleContent><span class="change-button-text">변경</span></ng-template>
                            </rwa-ghost-button>
                        </div>
                    </div>
                    <input
                        type="file"
                        accept=".png, .jpg, .pdf"
                        #businessLicense
                        (click)="onBusinessLicenseClicked($event)"
                        (change)="setBusinessLicenseFile(businessLicense)"
                        hidden
                    />
                </div>
            </div>
        </div>
    </div>
</ng-scrollbar>

<rwm-change-center-name-modal
    [(visible)]="showChangeNameModal"
    (close)="showChangeNameModal = false"
    (onNameConfirm)="onChangeName($event)"
    [name]="center?.name"
></rwm-change-center-name-modal>
<rwm-change-center-phone-number-modal
    [(visible)]="showChangePhoneNumberModal"
    (close)="showChangePhoneNumberModal = false"
    (onPhoneNumberConfirm)="onChangePhoneNumber($event)"
    [phoneNumber]="center?.phone_number"
></rwm-change-center-phone-number-modal>
<rwm-change-center-address-modal
    [zoneCode]="center?.zip_no"
    [detailedAddress]="center?.addr_detail"
    [roadAddress]="center?.road_full_addr"
    [(visible)]="showChangeAddressModal"
    (close)="showChangeAddressModal = false"
    (onAddressConfirm)="onAddressConfirm($event)"
></rwm-change-center-address-modal>

<rwa-modal
    [(visible)]="showLeaveCenterModal"
    [data]="leaveCenterModaData"
    (cancel)="showLeaveCenterModal = false"
    (confirm)="onLeaveCenterConfirm($event)"
></rwa-modal>
<rwa-modal
    [(visible)]="showLeaveCenterFailedModal"
    [data]="leaveCenterFailedModalData"
    (cancel)="onYieldCenterClick()"
    (confirm)="showLeaveCenterFailedModal = false"
></rwa-modal>
