<div class="rw-modal-background" #modalBackgroundElement></div>
<div
    class="rw-modal-wrapper justify-content-center align-items-center"
    (mouseup)="!isMouseModalDown && !blockClickOutside && onClose(false)"
    (mousedown)="resetMouseModalDown()"
    #modalWrapperElement
>
    <div class="rw-modal user-select-none" (mousedown)="onMouseModalDown(); $event.stopPropagation()">
        <div class="header">
            <div class="header-title">
                <span>직원 정보 수정</span>
                <div class="title-divider"></div>
                <span class="header-title__user-name line-ellipsis-one">{{ _employee?.name }}</span>
            </div>

            <div class="header-button d-flex align-items-center">
                <rwa-icon-ghost-button
                    *ngIf="user?.email != _employee?.email && _employee.role_code != 'owner'"
                    sizeType="md"
                    borderRadius="14px"
                    borderColor="var(--gray-60)"
                    (onClick)="onDeleteClick()"
                    rwTooltip
                    rwTooltipPlacement="bottom"
                    rwTooltipTitle="직원 삭제하기"
                >
                    <ng-template iconGhostBtIdleContent>
                        <div class="trash-icon"></div>
                    </ng-template>
                </rwa-icon-ghost-button>
                <rwa-icon-ghost-button
                    sizeType="md"
                    borderRadius="14px"
                    borderColor="var(--gray-60)"
                    (onClick)="onEditCancelClick()"
                >
                    <ng-template iconGhostBtIdleContent>
                        <rwa-svg-close width="22px" height="22px" color="var(--gray-100)"></rwa-svg-close>
                    </ng-template>
                </rwa-icon-ghost-button>
            </div>
        </div>
        <ng-scrollbar class="rw-scroll body-height">
            <div class="body" #body>
                <div class="update-employee-desc">✋ 잠깐! 직원의 사진과 이름은 회원에게도 동일하게 보여져요.</div>
                <div class="update-employee-content">
                    <div class="update-employee-content__left">
                        <span class="tag"> 직원 사진 </span>
                        <rwm-employee-photo
                            [pictureSrc]="employeePictureSrc"
                            (pictureChange)="onCenterPictureChange($event)"
                        ></rwm-employee-photo>
                    </div>
                    <form [formGroup]="centerForm" class="update-employee-content__right">
                        <div class="update-employee-content__item">
                            <span class="tag">권한<span class="required">*</span></span>
                            <rwa-tab
                                *ngIf="center.role_code == 'owner' && user?.email == _employee?.email"
                                [width]="308"
                                [tabs]="positionTabForSameOwner"
                                (onTabItemSelected)="positionTabForSameOwner = $event"
                            ></rwa-tab>
                            <rwa-tab
                                *ngIf="center.role_code == 'owner' && user?.email != _employee?.email"
                                [width]="308"
                                [tabs]="positionTabsForOwner"
                                (onTabItemSelected)="positionTabsForOwner = $event"
                            ></rwa-tab>
                            <rwa-tab
                                *ngIf="center.role_code != 'owner'"
                                [width]="308"
                                [tabs]="positionTabs"
                                (onTabItemSelected)="positionTabs = $event"
                            ></rwa-tab>
                        </div>
                        <div class="update-employee-content__item">
                            <rwa-text-field
                                [width]="'308px'"
                                [label]="'이름'"
                                [placeholder]="'이름'"
                                [isImportant]="true"
                                [type]="'normal'"
                                [inputLimit]="30"
                                [formControlName]="'employeeName'"
                            ></rwa-text-field>
                        </div>
                        <div class="update-employee-content__item">
                            <rwa-text-field
                                [width]="'308px'"
                                [label]="'전화번호'"
                                [placeholder]="'-없이 숫자만 입력'"
                                [isImportant]="true"
                                [type]="'normal'"
                                [inputLimit]="13"
                                [formControlName]="'phoneNumber'"
                            ></rwa-text-field>
                        </div>
                        <div class="update-employee-content__item">
                            <div class="l-email-field">
                                <rwa-text-field
                                    [width]="'308px'"
                                    [label]="'이메일 주소'"
                                    [placeholder]="'이메일 주소'"
                                    [isImportant]="false"
                                    [type]="'normal'"
                                    [inputLimit]="50"
                                    [formControlName]="'email'"
                                    [(status)]="emailStatus"
                                    [statusText]="'이미 센터에 등록되어 있는 이메일 주소예요.'"
                                ></rwa-text-field>
                                <div
                                    *ngIf="showTag"
                                    class="email-tag"
                                    [ngClass]="
                                        _employee?.connection_status_code == 'employee_connection_status_connected'
                                            ? 'connected'
                                            : _employee?.connection_status_code ==
                                              'employee_connection_status_disconnected'
                                            ? 'disconnected'
                                            : 'pending'
                                    "
                                >
                                    {{ statusText }}
                                </div>
                            </div>
                            <div class="email-terms">
                                <span class="terms-title"> ✨ 이메일 주소로 계정을 연동할 수 있어요! </span>
                                <span class="terms-desc">
                                    레드웨일 계정이 있는 직원을 등록하고 계신가<br />
                                    요? 직원의 계정과 동일한 이메일 주소를 입력<br />
                                    한 후, 계정 연동을 선택해 보세요.<br />
                                    계정 연동을 선택하면 직원에게 초대가 가고,<br />
                                    초대를 수락한 직원은 센터 계정이 아닌 본인<br />
                                    계정으로 센터에 입장할 수 있어요.
                                </span>
                            </div>
                        </div>
                        <div class="update-employee-content__item">
                            <span class="tag">이메일 주소로 계정 연동</span>
                            <rwa-toggle-button
                                [disable]="!email.value || email.disabled"
                                [checked]="linkEmailAccount"
                                (onClick)="linkEmailAccount = !linkEmailAccount"
                                [label]="
                                    linkEmailAccount ? '직원 계정을 연동할게요.' : '직원 계정을 연동하지 않을게요.'
                                "
                            ></rwa-toggle-button>
                        </div>
                        <rwa-button
                            padding="9px 25px 7px"
                            loadingName="update-employee-button"
                            [status]="updateButtonLoading"
                            [disable]="phoneNumber.invalid || employeeName.invalid || (email.value && email.invalid)"
                            (onClick)="checkYieldOwner()"
                        >
                            <ng-template btIdleContent>
                                <span class="update-employee-button-text">저장하기</span>
                            </ng-template>
                            <ng-template btPendingContent>
                                <span class="update-employee-button-text">저장하기</span>
                            </ng-template>
                        </rwa-button>
                    </form>
                </div>
            </div>
        </ng-scrollbar>
    </div>
</div>

<rwa-modal
    [(visible)]="deleteEmployeeOpen"
    [data]="deleteEmployeeData"
    [blockClickOutside]="true"
    (cancel)="onDeleteCancel()"
    (confirm)="onDeleteConfirm($event)"
></rwa-modal>

<rwa-modal
    [(visible)]="cancelEditEmployeeOpen"
    [data]="cancelEditEmplyeeData"
    [blockClickOutside]="true"
    (cancel)="onCancelModalCancel()"
    (confirm)="onCancelConfirm()"
></rwa-modal>

<rwa-modal
    [(visible)]="yieldOwnerOpen"
    [data]="yieldOwnerData"
    [blockClickOutside]="true"
    (cancel)="onYieldCancel()"
    (confirm)="onYieldConfirm($event)"
></rwa-modal>

<rwa-modal
    [type]="'oneButton'"
    [(visible)]="disableToYieldModalOpen"
    [data]="disableToYieldModalData"
    [blockClickOutside]="true"
    (confirm)="onDisableToYieldCancel()"
></rwa-modal>
