<div class="rw-modal-background" #modalBackgroundElement></div>
<div
    class="rw-modal-wrapper justify-content-center align-items-center"
    (mouseup)="!isMouseModalDown && !blockClickOutside && onClose()"
    (mousedown)="resetMouseModalDown()"
    #modalWrapperElement
>
    <div class="rw-modal user-select-none" (mousedown)="onMouseModalDown(); $event.stopPropagation()">
        <div class="header">
            <div class="header-title">성별</div>
            <rwa-icon-ghost-button sizeType="md" borderRadius="14px" borderColor="var(--gray-60)" (onClick)="onClose()">
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-close width="22px" height="22px" color="var(--gray-100)"></rwa-svg-close>
                </ng-template>
            </rwa-icon-ghost-button>
        </div>
        <div class="body">
            <div class="l-gender-radio">
                <rwa-radio-button [checked]="isMale" label="남성" (onClick)="onMaleClick()"></rwa-radio-button>
                <rwa-radio-button [checked]="isFeMale" label="여성" (onClick)="onFemaleClick()"></rwa-radio-button>
            </div>
            <rwa-button
                [disable]="!isMale && !isFeMale"
                class="save-button"
                padding="9px 25px 7px"
                (onClick)="onConfirm()"
                [loadingName]="'gender-save'"
                [status]="confirmButtonLoading"
            >
                <ng-template btIdleContent><span class="save-button-text">저장하기</span></ng-template>
                <ng-template btPendingContent><span class="save-button-text">저장하기</span></ng-template>
            </rwa-button>
        </div>
    </div>
</div>
