<div id="l-rwp-component" class="d-flex flex-column">
    <p class="v-padding"></p>
    <h1>- Typograph</h1>
    <p class="v-padding"></p>
    <div class="d-flex flex-column">
        <h2 class="rw-type-b32">B32 Pretendard Bold / 32</h2>
        <p class="rw-type-b32">장문 문구 테스트 입력 문구 입력 장문 문구 테스트 입력 문구 입력 문구 입력</p>
        <p class="v-padding"></p>
        <h2 class="rw-type-b27">B27 Pretendard Bold / 27</h2>
        <p class="rw-type-b27">장문 문구 테스트 입력 문구 입력 장문 문구 테스트 입력 문구 입력 문구 입력</p>
        <p class="v-padding"></p>
        <h2 class="rw-type-b20">B20 Pretendard Bold / 20</h2>
        <p class="rw-type-b20">장문 문구 테스트 입력 문구 입력 장문 문구 테스트 입력 문구 입력 문구 입력</p>
        <p class="v-padding"></p>
        <h2 class="rw-type-b17">B17 Pretendard Bold / 17</h2>
        <p class="rw-type-b17">장문 문구 테스트 입력 문구 입력 장문 문구 테스트 입력 문구 입력 문구 입력</p>
        <p class="v-padding"></p>
        <h2 class="rw-type-b15">B15 Pretendard Bold / 15</h2>
        <p class="rw-type-b15">장문 문구 테스트 입력 문구 입력 장문 문구 테스트 입력 문구 입력 문구 입력</p>
        <p class="v-padding"></p>
        <h2 class="rw-type-r17">R17 Pretendard Regular / 17</h2>
        <p class="rw-type-r17">장문 문구 테스트 입력 문구 입력 장문 문구 테스트 입력 문구 입력 문구 입력</p>
        <p class="v-padding"></p>
        <h2 class="rw-type-r15">R15 Pretendard Regular / R15</h2>
        <p class="rw-type-r15">장문 문구 테스트 입력 문구 입력 장문 문구 테스트 입력 문구 입력 문구 입력</p>
        <p class="v-padding"></p>
        <h2 class="rw-type-r14">R14 Pretendard Regular / 14</h2>
        <p class="rw-type-r14">장문 문구 테스트 입력 문구 입력 장문 문구 테스트 입력 문구 입력 문구 입력</p>
        <p class="v-padding"></p>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h1>- tooltip</h1>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <div rwTooltip rwTooltipPlacement="bottom" rwTooltipTitle="수정하기 / 채팅">툴팁 bottom</div>
        <div class="h-padding"></div>
        <div class="h-padding"></div>
        <div class="h-padding"></div>
        <div rwTooltip rwTooltipPlacement="top" rwTooltipTitle="수정하기 / 채팅">툴팁 top</div>
        <div class="h-padding"></div>
        <div class="h-padding"></div>
        <div class="h-padding"></div>
        <div rwTooltip rwTooltipPlacement="left" rwTooltipTitle="수정하기 / 채팅">툴팁 left</div>
        <div class="h-padding"></div>
        <div class="h-padding"></div>
        <div class="h-padding"></div>
        <div rwTooltip rwTooltipPlacement="right" rwTooltipTitle="수정하기 / 채팅">툴팁 right</div>
    </div>

    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h1>- tooltip component</h1>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-tooltip [textObj]="tooltip1" minWidth="70px"></rwa-tooltip>
        <p class="h-padding"></p>
        <rwa-tooltip [textObj]="tooltip1" [colorType]="'white'" minWidth="70px"></rwa-tooltip>
        <p class="h-padding"></p>
        <rwa-tooltip [textObj]="tooltip2" minWidth="91px" [tail]="'bottom'"></rwa-tooltip>
        <p class="h-padding"></p>
        <rwa-tooltip [textObj]="tooltip2" [colorType]="'white'" minWidth="91px" [tail]="'bottom'"></rwa-tooltip>
        <p class="h-padding"></p>
        <rwa-tooltip [textObj]="tooltip2" minWidth="91px" [tail]="'top'"></rwa-tooltip>
        <p class="h-padding"></p>
        <rwa-tooltip [textObj]="tooltip2" [colorType]="'white'" minWidth="91px" [tail]="'top'"></rwa-tooltip>
        <p class="h-padding"></p>
        <rwa-tooltip [textObj]="tooltip3" [type]="'withDetail'" [width]="'184px'" [tail]="'bottom'"></rwa-tooltip>
        <p class="h-padding"></p>
        <rwa-tooltip
            [textObj]="tooltip3"
            [type]="'withDetail'"
            [colorType]="'white'"
            [width]="'184px'"
            [tail]="'bottom'"
        ></rwa-tooltip>
        <p class="h-padding"></p>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <p class="h-padding"></p>
        <rwa-tooltip
            [textObj]="tooltip5"
            colorType="red"
            [type]="'normal'"
            [tail]="'bottom'"
            [movable]="true"
        ></rwa-tooltip>
        <p class="h-padding"></p>
        <rwa-tooltip
            [textObj]="tooltip5"
            colorType="red"
            [type]="'normal'"
            [tail]="'top'"
            [movable]="true"
        ></rwa-tooltip>
        <p class="h-padding"></p>
        <rwa-tooltip
            [textObj]="tooltip5"
            colorType="red"
            [type]="'normal'"
            [tail]="'right'"
            [movable]="true"
        ></rwa-tooltip>
        <p class="h-padding"></p>
        <rwa-tooltip
            [textObj]="tooltip5"
            colorType="red"
            [type]="'normal'"
            [tail]="'left'"
            [movable]="true"
        ></rwa-tooltip>
        <p class="h-padding"></p>
        <p class="h-padding"></p>
        <rwa-tooltip
            [textObj]="tooltip4"
            [type]="'title-content'"
            [width]="'110px'"
            [tail]="'bottom'"
            [movable]="true"
        ></rwa-tooltip>
        <p class="h-padding"></p>
        <rwa-tooltip
            [textObj]="tooltip4"
            [type]="'title-content'"
            [width]="'110px'"
            [tail]="'top'"
            [movable]="true"
        ></rwa-tooltip>
        <p class="h-padding"></p>
        <rwa-tooltip
            [textObj]="tooltip4"
            [type]="'title-content'"
            [width]="'110px'"
            [tail]="'right'"
            [movable]="true"
        ></rwa-tooltip>
        <p class="h-padding"></p>
        <rwa-tooltip
            [textObj]="tooltip4"
            [type]="'title-content'"
            [width]="'110px'"
            [tail]="'left'"
            [movable]="true"
        ></rwa-tooltip>
        <p class="h-padding"></p>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h1>- badge</h1>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-badge
            [color]="'var(--red-100)'"
            [fontSize]="'1.4rem'"
            [fontWeight]="'400'"
            [lineHeight]="'19px'"
            [bgColor]="'var(--red-5)'"
        >
            사용중
        </rwa-badge>
        <p class="h-padding"></p>
        <rwa-badge
            [color]="'var(--state-warning-100)'"
            [fontSize]="'1.4rem'"
            [fontWeight]="'400'"
            [lineHeight]="'19px'"
            [bgColor]="'var(--state-warning-5)'"
        >
            홀딩
        </rwa-badge>
        <p class="h-padding"></p>
        <rwa-badge> 사용 만료 </rwa-badge>
        <p class="h-padding"></p>
        <rwa-badge
            [color]="'var(--red-100)'"
            [fontSize]="'1.4rem'"
            [fontWeight]="'400'"
            [lineHeight]="'19px'"
            [bgColor]="'var(--red-10)'"
            [borderRadius]="'33px'"
            [padding]="'3px 9px 2px'"
        >
            1
        </rwa-badge>
        <p class="h-padding"></p>
        <rwa-badge
            [color]="'var(--red-100)'"
            [fontSize]="'1.4rem'"
            [fontWeight]="'400'"
            [lineHeight]="'19px'"
            [bgColor]="'var(--red-10)'"
            [borderRadius]="'33px'"
            [padding]="'3px 9px 2px'"
        >
            99
        </rwa-badge>
        <p class="h-padding"></p>
        <rwa-badge
            [color]="'var(--red-100)'"
            [fontSize]="'1.4rem'"
            [fontWeight]="'400'"
            [lineHeight]="'19px'"
            [bgColor]="'var(--red-10)'"
            [borderRadius]="'33px'"
            [padding]="'3px 9px 2px'"
        >
            999+
        </rwa-badge>
        <p class="h-padding"></p>
    </div>

    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h1>- buttons</h1>
    <p class="v-padding"></p>
    <div>
        <p class="v-padding"></p>
        <h2>기본 L</h2>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본 - bg:red</span>
            <div class="h-padding"></div>
            <rwa-button [padding]="'9px 25px 7px'" [status]="button1.status" [loadingName]="button1.loadingName">
                <ng-template btIdleContent><span class="default">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="default">저장중</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>처리중 포함</span>
            <div class="h-padding"></div>
            <rwa-button
                [status]="button2.status"
                [loadingName]="button2.loadingName"
                [progressPercent]="button2.progress"
                (onClick)="onClickButton2()"
            >
                <ng-template btIdleContent><span class="default">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="default">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-button [padding]="'9px 25px 7px'" [disableBgColor]="'#EEEEEE'" [disable]="true">
                <ng-template btIdleContent><span class="default">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="default">저장중</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
        </div>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본 - bg:gray</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'9px 25px 7px'"
                [status]="button1_2.status"
                [bgColor]="'#2F2D2D'"
                [hoverBgColor]="'#141517'"
            >
                <ng-template btIdleContent><span class="default">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="default">저장중</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>처리중 포함</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'9px 25px 7px'"
                [status]="button2.status"
                [bgColor]="'#2F2D2D'"
                [hoverBgColor]="'#141517'"
                [progressBgColor]="'#141517'"
                [loadingName]="button2.loadingName"
                [progressPercent]="button2.progress"
                (onClick)="onClickButton2()"
            >
                <ng-template btIdleContent><span class="default">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="default">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'9px 25px 7px'"
                [bgColor]="'#2F2D2D'"
                [hoverBgColor]="'#141517'"
                [disableBgColor]="'#EEEEEE'"
                [disable]="true"
            >
                <ng-template btIdleContent><span class="default">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="default">저장중</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
        </div>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본 - bg:white</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'9px 25px 7px'"
                [status]="button1_2.status"
                [fontColor]="'var(--gray-90)'"
                [bgColor]="'var(--white)'"
                [hoverBgColor]="'var(--gray-30)'"
                [loadingColor]="'var(--font-color)'"
            >
                <ng-template btIdleContent><span class="default">초기화</span> </ng-template>
                <ng-template btPendingContent><span class="default">처리중</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>처리중 포함 (클릭 시 확인 가능)</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'9px 25px 7px'"
                [status]="button2.status"
                [fontColor]="'var(--gray-90)'"
                [bgColor]="'var(--white)'"
                [hoverBgColor]="'var(--gray-30)'"
                [progressBgColor]="'var(--gray-30)'"
                [loadingName]="button2.loadingName"
                [progressPercent]="button2.progress"
                [loadingColor]="'var(--font-color)'"
                (onClick)="onClickButton2()"
            >
                <ng-template btIdleContent><span class="default">초기화</span> </ng-template>
                <ng-template btPendingContent><span class="default">초기화</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'9px 25px 7px'"
                [bgColor]="'#2F2D2D'"
                [fontColor]="'var(--gray-90)'"
                disableFontColor="var(--gray-60)"
                [hoverBgColor]="'#141517'"
                [disableBgColor]="'#FFFFFF'"
                [loadingColor]="'var(--font-color)'"
                [disable]="true"
            >
                <ng-template btIdleContent><span class="default">초기화</span> </ng-template>
                <ng-template btPendingContent><span class="default">처리중</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
        </div>
        <p class="v-padding"></p>
        <h2>기본 M</h2>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본 - bg:red</span>
            <div class="h-padding"></div>
            <rwa-button [sizeType]="'md'" [status]="button1.status" [loadingName]="button1.loadingName">
                <ng-template btIdleContent><span class="default">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="default">저장중</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>처리중 포함</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'7.5px 16px 5.5px'"
                [sizeType]="'md'"
                [status]="button2.status"
                [loadingName]="button2.loadingName"
                [progressPercent]="button2.progress"
                (onClick)="onClickButton2()"
            >
                <ng-template btIdleContent><span class="default">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="default">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-button [padding]="'7.5px 16px 5.5px'" [sizeType]="'md'" [disableBgColor]="'#EEEEEE'" [disable]="true">
                <ng-template btIdleContent><span class="default">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="default">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
        </div>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본 - bg:gray</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'7.5px 16px 5.5px'"
                [sizeType]="'md'"
                [status]="button1_2.status"
                [bgColor]="'#2F2D2D'"
                [hoverBgColor]="'#141517'"
            >
                <ng-template btIdleContent><span class="default">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="default">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>처리중 포함</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'7.5px 16px 5.5px'"
                [sizeType]="'md'"
                [status]="button2.status"
                [bgColor]="'#2F2D2D'"
                [hoverBgColor]="'#141517'"
                [progressBgColor]="'#141517'"
                [loadingName]="button2.loadingName"
                [progressPercent]="button2.progress"
                (onClick)="onClickButton2()"
            >
                <ng-template btIdleContent><span class="default">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="default">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'7.5px 16px 5.5px'"
                [sizeType]="'md'"
                [bgColor]="'#2F2D2D'"
                [hoverBgColor]="'#141517'"
                [disableBgColor]="'#EEEEEE'"
                [disable]="true"
            >
                <ng-template btIdleContent><span class="default">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="default">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
        </div>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본 - bg:white</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'7.5px 16px 5.5px'"
                [sizeType]="'md'"
                [status]="button1_2.status"
                [fontColor]="'var(--gray-90)'"
                [bgColor]="'var(--white)'"
                [hoverBgColor]="'var(--gray-30)'"
                [loadingColor]="'var(--font-color)'"
            >
                <ng-template btIdleContent><span class="default">초기화</span> </ng-template>
                <ng-template btPendingContent><span class="default">초기화</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>처리중 포함 (클릭 시 확인 가능)</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'7.5px 16px 5.5px'"
                [sizeType]="'md'"
                [status]="button2.status"
                [fontColor]="'var(--gray-90)'"
                [bgColor]="'var(--white)'"
                [hoverBgColor]="'var(--gray-30)'"
                [progressBgColor]="'var(--gray-30)'"
                [loadingName]="button2.loadingName"
                [progressPercent]="button2.progress"
                [loadingColor]="'var(--font-color)'"
                (onClick)="onClickButton2()"
            >
                <ng-template btIdleContent><span class="default">초기화</span> </ng-template>
                <ng-template btPendingContent><span class="default">초기화</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'7.5px 16px 5.5px'"
                [sizeType]="'md'"
                [bgColor]="'#2F2D2D'"
                [fontColor]="'var(--gray-90)'"
                disableFontColor="var(--gray-60)"
                [hoverBgColor]="'#141517'"
                [disableBgColor]="'#FFFFFF'"
                [loadingColor]="'var(--font-color)'"
                [disable]="true"
            >
                <ng-template btIdleContent><span class="default">초기화</span> </ng-template>
                <ng-template btPendingContent><span class="default">초기화</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
        </div>
        <p class="v-padding"></p>
        <p class="v-padding"></p>
        <h2>기본 S</h2>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본 - bg:red</span>
            <div class="h-padding"></div>
            <rwa-button [sizeType]="'sm'" [status]="button1.status" [loadingName]="button1.loadingName">
                <ng-template btIdleContent><span class="small">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="small">저장중</span></ng-template>
                <ng-template btDoneContent><span class="small">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>처리중 포함</span>
            <div class="h-padding"></div>
            <rwa-button
                [sizeType]="'sm'"
                [status]="button2.status"
                [loadingName]="button2.loadingName"
                [progressPercent]="button2.progress"
                (onClick)="onClickButton2()"
            >
                <ng-template btIdleContent><span class="small">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="small">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="small">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-button [sizeType]="'sm'" [disableBgColor]="'#EEEEEE'" [disable]="true">
                <ng-template btIdleContent><span class="small">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="small">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="small">완료</span></ng-template>
            </rwa-button>
        </div>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본 - bg:gray</span>
            <div class="h-padding"></div>
            <rwa-button [sizeType]="'sm'" [status]="button1_2.status" [bgColor]="'#2F2D2D'" [hoverBgColor]="'#141517'">
                <ng-template btIdleContent><span class="small">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="small">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="small">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>처리중 포함</span>
            <div class="h-padding"></div>
            <rwa-button
                [sizeType]="'sm'"
                [status]="button2.status"
                [bgColor]="'#2F2D2D'"
                [hoverBgColor]="'#141517'"
                [progressBgColor]="'#141517'"
                [loadingName]="button2.loadingName"
                [progressPercent]="button2.progress"
                (onClick)="onClickButton2()"
            >
                <ng-template btIdleContent><span class="small">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="small">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="small">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-button
                [sizeType]="'sm'"
                [bgColor]="'#2F2D2D'"
                [hoverBgColor]="'#141517'"
                [disableBgColor]="'#EEEEEE'"
                [disable]="true"
            >
                <ng-template btIdleContent><span class="small">저장하기</span> </ng-template>
                <ng-template btPendingContent><span class="small">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="small">완료</span></ng-template>
            </rwa-button>
        </div>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본 - bg:white</span>
            <div class="h-padding"></div>
            <rwa-button
                [sizeType]="'sm'"
                [status]="button1_2.status"
                [fontColor]="'var(--gray-90)'"
                [bgColor]="'var(--white)'"
                [hoverBgColor]="'var(--gray-30)'"
                [loadingColor]="'var(--font-color)'"
            >
                <ng-template btIdleContent><span class="small">초기화</span> </ng-template>
                <ng-template btPendingContent><span class="small">초기화</span></ng-template>
                <ng-template btDoneContent><span class="small">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>처리중 포함 (클릭 시 확인 가능)</span>
            <div class="h-padding"></div>
            <rwa-button
                [sizeType]="'sm'"
                [status]="button2.status"
                [fontColor]="'var(--gray-90)'"
                [bgColor]="'var(--white)'"
                [hoverBgColor]="'var(--gray-30)'"
                [progressBgColor]="'var(--gray-30)'"
                [loadingName]="button2.loadingName"
                [progressPercent]="button2.progress"
                [loadingColor]="'var(--font-color)'"
                (onClick)="onClickButton2()"
            >
                <ng-template btIdleContent><span class="small">초기화</span> </ng-template>
                <ng-template btPendingContent><span class="small">초기화</span></ng-template>
                <ng-template btDoneContent><span class="small">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-button
                [sizeType]="'sm'"
                [bgColor]="'#2F2D2D'"
                [fontColor]="'var(--gray-90)'"
                disableFontColor="var(--gray-60)"
                [hoverBgColor]="'#141517'"
                [disableBgColor]="'#FFFFFF'"
                [loadingColor]="'var(--font-color)'"
                [disable]="true"
            >
                <ng-template btIdleContent><span class="small">초기화</span> </ng-template>
                <ng-template btPendingContent><span class="small">초기화</span></ng-template>
                <ng-template btDoneContent><span class="small">완료</span></ng-template>
            </rwa-button>
        </div>
        <p class="v-padding"></p>
        <p class="v-padding"></p>
        <h2>기본 + 아이콘 L</h2>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본 - case 1</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'9px 20px 7px 25px'"
                [bgColor]="'var(--red-5)'"
                [hoverBgColor]="'var(--red-10)'"
                [fontColor]="'var(--red-100)'"
                [progressBgColor]="'var(--red-10)'"
                [loadingColor]="'var(--red-100)'"
                [status]="button1.status"
                [loadingName]="button1.loadingName"
            >
                <ng-template btIdleContent>
                    <rwa-svg-arrow-left-bold
                        width="20px"
                        height="20px"
                        [color]="'var(--red-100)'"
                    ></rwa-svg-arrow-left-bold>
                    <span class="default">저장하기</span>
                </ng-template>
                <ng-template btPendingContent><span class="default">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>처리중 포함</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'9px 20px 7px 25px'"
                [bgColor]="'var(--red-5)'"
                [hoverBgColor]="'var(--red-10)'"
                [fontColor]="'var(--red-100)'"
                [progressBgColor]="'var(--red-10)'"
                [loadingColor]="'var(--red-100)'"
                [status]="button2.status"
                [loadingName]="button2.loadingName"
                [progressPercent]="button2.progress"
                (onClick)="onClickButton2()"
            >
                <ng-template btIdleContent>
                    <rwa-svg-arrow-left-bold
                        width="20px"
                        height="20px"
                        [color]="'var(--red-100)'"
                    ></rwa-svg-arrow-left-bold
                    ><span class="default">저장하기</span>
                </ng-template>
                <ng-template btPendingContent><span class="default">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'9px 20px 7px 25px'"
                [bgColor]="'var(--red-5)'"
                [hoverBgColor]="'var(--red-10)'"
                [fontColor]="'var(--red-100)'"
                [progressBgColor]="'var(--red-10)'"
                [disableBgColor]="'#EEEEEE'"
                [disable]="true"
            >
                <ng-template btIdleContent>
                    <rwa-svg-arrow-left-bold
                        width="20px"
                        height="20px"
                        [color]="'var(--white)'"
                    ></rwa-svg-arrow-left-bold>
                    <span class="default">저장하기</span>
                </ng-template>
                <ng-template btPendingContent><span class="default">저장중</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
        </div>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본 - case 2</span>
            <div class="h-padding"></div>
            <rwa-button [padding]="'9px 20px 7px 25px'" [status]="button1_2.status">
                <ng-template btIdleContent>
                    <span class="default">저장하기</span>
                    <rwa-svg-arrow-right-bold
                        width="20px"
                        height="20px"
                        [color]="'var(--white)'"
                    ></rwa-svg-arrow-right-bold>
                </ng-template>
                <ng-template btPendingContent><span class="default">저장중</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>처리중 포함</span>
            <div class="h-padding"></div>
            <rwa-button
                [padding]="'9px 20px 7px 25px'"
                [status]="button2.status"
                [loadingName]="button2.loadingName"
                [progressPercent]="button2.progress"
                (onClick)="onClickButton2()"
            >
                <ng-template btIdleContent>
                    <span class="default">저장하기</span>
                    <rwa-svg-arrow-right-bold
                        width="20px"
                        height="20px"
                        [color]="'var(--white)'"
                    ></rwa-svg-arrow-right-bold>
                </ng-template>
                <ng-template btPendingContent><span class="default">저장하기</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-button [padding]="'9px 20px 7px 25px'" [disable]="true">
                <ng-template btIdleContent>
                    <span class="default">저장하기</span>
                    <rwa-svg-arrow-right-bold
                        width="20px"
                        height="20px"
                        [color]="'var(--white)'"
                    ></rwa-svg-arrow-right-bold>
                </ng-template>
                <ng-template btPendingContent><span class="default">저장중</span></ng-template>
                <ng-template btDoneContent><span class="default">완료</span></ng-template>
            </rwa-button>
        </div>
        <p class="v-padding"></p>
    </div>

    <!----------------------------------------------------- 고스트 버튼 ------------------------------------------------------------->

    <p class="v-padding"></p>
    <h2>고스트</h2>
    <p class="v-padding"></p>
    <div>
        <h3>고스트 L</h3>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본</span>
            <div class="h-padding"></div>
            <rwa-ghost-button [borderWidth]="'1.5px'">
                <ng-template gbtIdleContent><span class="small">문의하기</span></ng-template>
            </rwa-ghost-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-ghost-button [borderWidth]="'1.5px'" [disable]="true">
                <ng-template gbtIdleContent><span class="small">문의하기</span></ng-template>
            </rwa-ghost-button>
        </div>
        <p class="v-padding"></p>
        <h3>고스트 M</h3>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본</span>
            <div class="h-padding"></div>
            <rwa-ghost-button height="42px" padding="7.5px 15px 5.5px">
                <ng-template gbtIdleContent><span class="default">전체 보기</span></ng-template>
            </rwa-ghost-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-ghost-button height="42px" padding="7.5px 15px 5.5px" [disable]="true">
                <ng-template gbtIdleContent><span class="default">전체 보기</span></ng-template>
            </rwa-ghost-button>
        </div>
        <p class="v-padding"></p>
        <h3>고스트 S</h3>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본</span>
            <div class="h-padding"></div>
            <rwa-ghost-button height="37px" padding="5px 11px 3px">
                <ng-template gbtIdleContent><span class="small">전체 보기</span></ng-template>
            </rwa-ghost-button>
            <div class="h-padding"></div>
            <span>기본</span>
            <div class="h-padding"></div>
            <rwa-ghost-button height="37px" padding="5px 11px 3px" [disable]="true">
                <ng-template gbtIdleContent><span class="small">전체 보기</span></ng-template>
            </rwa-ghost-button>
        </div>
        <p class="v-padding"></p>
        <h3>고스트 M + 아이콘</h3>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <span>기본</span>
            <div class="h-padding"></div>
            <rwa-ghost-button
                padding="7.5px 15px 5.5px 11px"
                [height]="'42px'"
                [borderColor]="'var(--red-100)'"
                [hoverBorderColor]="'var(--red-100)'"
                [fontColor]="'var(--red-100)'"
                [hoverBgColor]="'var(--red-5)'"
                (onClick)="gbt1.disable = !gbt1.disable"
            >
                <ng-template gbtIdleContent>
                    <rwa-svg-plus [margin]="'0 5px 2.5px 0'" [color]="'var(--red-100)'"></rwa-svg-plus>
                    <span class="default">수업 추가</span></ng-template
                >
            </rwa-ghost-button>
            <div class="h-padding"></div>
            <span>처리중 포함 (클릭 시 확인 가능)</span>
            <div class="h-padding"></div>
            <rwa-ghost-button
                padding="7.5px 15px 5.5px 11px"
                [height]="'42px'"
                [borderColor]="'var(--red-100)'"
                [hoverBorderColor]="'var(--red-100)'"
                [fontColor]="'var(--red-100)'"
                [hoverBgColor]="'var(--red-5)'"
                [disableBgColor]="'var(--white)'"
                [disableFontColor]="'var(--red-20)'"
                [disableBorderColor]="'var(--red-20)'"
                [loadingColor]="'var(--red-100)'"
                [status]="gbt2.progress"
                [progressPercent]="75"
                [progressBgColor]="'var(--red-5)'"
                (onClick)="gbt2Click()"
            >
                <ng-template gbtIdleContent>
                    <rwa-svg-plus [margin]="'0 5px 2.5px 0'" [color]="'var(--red-100)'"></rwa-svg-plus>
                    <span class="default">수업 추가</span>
                </ng-template>
                <ng-template gbtPendingContent> <span class="default">처리중</span> </ng-template>
                <ng-template gbtDoneContent>
                    <span class="default">완료</span>
                </ng-template>
            </rwa-ghost-button>
            <div class="h-padding"></div>
            <span>비활성화</span>
            <div class="h-padding"></div>
            <rwa-ghost-button
                padding="7.5px 15px 5.5px 11px"
                [height]="'42px'"
                [borderColor]="'var(--red-100)'"
                [hoverBorderColor]="'var(--red-100)'"
                [fontColor]="'var(--red-100)'"
                [hoverBgColor]="'var(--red-5)'"
                [disableBgColor]="'var(--white)'"
                [disableFontColor]="'var(--red-20)'"
                [disableBorderColor]="'var(--red-20)'"
                [disable]="true"
            >
                <ng-template gbtIdleContent>
                    <rwa-svg-plus *ngIf="true" [margin]="'0 5px 0 0'" [color]="'var(--red-20)'"></rwa-svg-plus>
                    <rwa-svg-plus *ngIf="false" [margin]="'0 5px 0 0'"></rwa-svg-plus
                    ><span class="default">수업 추가</span>
                </ng-template>
            </rwa-ghost-button>
        </div>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <!----------------------------------------------------- 텍스트 버튼 ------------------------------------------------------------->
    <h2>텍스트 버튼</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <h3>gray w.underline / wo.underline</h3>
        <div class="h-padding"></div>
        <rwa-text-button [showUnderLine]="true"> 사용 불가 해제하기 </rwa-text-button>
        <div class="h-padding"></div>
        <rwa-text-button> 사용 불가 해제하기 </rwa-text-button>
        <div class="h-padding"></div>
        <h3>gray 비활성화 w.underline / wo.underline</h3>
        <div class="h-padding"></div>
        <rwa-text-button [showUnderLine]="true" [disable]="true"> 사용 불가 해제하기 </rwa-text-button>
        <div class="h-padding"></div>
        <rwa-text-button [disable]="true"> 사용 불가 해제하기 </rwa-text-button>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <h3>gray - b w.underline / wo.underline</h3>
        <div class="h-padding"></div>
        <rwa-text-button fontWeight="700" [showUnderLine]="true"> 사용 불가 해제하기 </rwa-text-button>
        <div class="h-padding"></div>
        <rwa-text-button fontWeight="700"> 사용 불가 해제하기 </rwa-text-button>
        <div class="h-padding"></div>
        <h3>gray - b 비활성화 w.underline / wo.underline</h3>
        <div class="h-padding"></div>
        <rwa-text-button fontWeight="700" [disable]="true" [showUnderLine]="true"> 사용 불가 해제하기 </rwa-text-button>
        <div class="h-padding"></div>
        <rwa-text-button fontWeight="700" [disable]="true"> 사용 불가 해제하기 </rwa-text-button>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <h3>red w.underline / wo.underline</h3>
        <div class="h-padding"></div>
        <rwa-text-button [color]="'var(--red-100)'" [hoverColor]="'var(--red-200)'" [showUnderLine]="true">
            사용 불가 해제하기
        </rwa-text-button>
        <div class="h-padding"></div>
        <rwa-text-button [color]="'var(--red-100)'" [hoverColor]="'var(--red-200)'">
            사용 불가 해제하기
        </rwa-text-button>
        <div class="h-padding"></div>
        <h3>red 비활성화 w.underline / wo.underline</h3>
        <div class="h-padding"></div>
        <rwa-text-button
            [color]="'var(--red-100)'"
            [hoverColor]="'var(--red-200)'"
            [showUnderLine]="true"
            [disable]="true"
        >
            사용 불가 해제하기
        </rwa-text-button>
        <div class="h-padding"></div>
        <rwa-text-button [color]="'var(--red-100)'" [hoverColor]="'var(--red-200)'" [disable]="true">
            사용 불가 해제하기
        </rwa-text-button>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <h3>red - b w.underline / wo.underline</h3>
        <div class="h-padding"></div>
        <rwa-text-button
            [color]="'var(--red-100)'"
            [hoverColor]="'var(--red-200)'"
            fontWeight="700"
            [showUnderLine]="true"
        >
            사용 불가 해제하기
        </rwa-text-button>
        <div class="h-padding"></div>
        <rwa-text-button [color]="'var(--red-100)'" [hoverColor]="'var(--red-200)'" fontWeight="700">
            사용 불가 해제하기
        </rwa-text-button>
        <div class="h-padding"></div>
        <h3>red - b 비활성화 w.underline / wo.underline</h3>
        <div class="h-padding"></div>
        <rwa-text-button
            [color]="'var(--red-100)'"
            [hoverColor]="'var(--red-200)'"
            fontWeight="700"
            [disable]="true"
            [showUnderLine]="true"
        >
            사용 불가 해제하기
        </rwa-text-button>
        <div class="h-padding"></div>
        <rwa-text-button [color]="'var(--red-100)'" [hoverColor]="'var(--red-200)'" fontWeight="700" [disable]="true">
            사용 불가 해제하기
        </rwa-text-button>
        <div class="h-padding"></div>
    </div>

    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center" [style.background-color]="'var(--gray-100)'">
        <h3 [style.color]="'white'">light - 1 w.underline / 2 wo.underline</h3>
        <div class="h-padding"></div>
        <rwa-text-button
            [color]="'var(--gray-60)'"
            [hoverColor]="'var(--white)'"
            [disableColor]="'var(--gray-90)'"
            fontWeight="700"
            [showUnderLine]="true"
        >
            사용 불가 해제하기
        </rwa-text-button>
        <div class="h-padding"></div>
        <rwa-text-button
            [color]="'var(--gray-60)'"
            [hoverColor]="'var(--white)'"
            [disableColor]="'var(--gray-90)'"
            fontWeight="400"
        >
            사용 불가 해제하기
        </rwa-text-button>
        <div class="h-padding"></div>
        <h3 [style.color]="'white'">light - 1 비활성화 w.underline / 2 wo.underline</h3>
        <div class="h-padding"></div>
        <rwa-text-button
            [color]="'var(--gray-60)'"
            [hoverColor]="'var(--white)'"
            [disableColor]="'var(--gray-90)'"
            fontWeight="700"
            [disable]="true"
            [showUnderLine]="true"
        >
            사용 불가 해제하기
        </rwa-text-button>
        <div class="h-padding"></div>
        <rwa-text-button
            [color]="'var(--gray-60)'"
            [hoverColor]="'var(--white)'"
            [disableColor]="'var(--gray-90)'"
            [disable]="true"
            fontWeight="400"
        >
            사용 불가 해제하기
        </rwa-text-button>
        <div class="h-padding"></div>
    </div>

    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h2>아이콘 고스트 버튼</h2>
    <p class="v-padding"></p>
    <div>
        <h3>아이콘 XL</h3>
        <div class="v-padding"></div>
        <div class="d-flex align-items-center">
            <p class="h-padding"></p>
            <rwa-icon-ghost-button
                #igb1
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="회원 리스트"
                borderColor="var(--gray-60)"
            >
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-user-list [width]="'24px'" [height]="'24px'"></rwa-svg-user-list>
                </ng-template>
            </rwa-icon-ghost-button>
            <p class="h-padding"></p>
            <rwa-icon-ghost-button
                #igb1
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="회원 리스트"
                [rwTooltipDisabled]="true"
                [disabled]="true"
                borderColor="var(--gray-60)"
            >
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-user-list
                        [width]="'24px'"
                        [height]="'24px'"
                        [color]="'var(--gray-60)'"
                    ></rwa-svg-user-list>
                </ng-template>
            </rwa-icon-ghost-button>
        </div>
        <p class="v-padding"></p>
        <h3>아이콘 M</h3>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <p class="h-padding"></p>
            <rwa-icon-ghost-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="자세히 보기"
                [sizeType]="'md'"
                borderRadius="14px"
                borderColor="var(--gray-60)"
            >
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-pencil [width]="'22px'" [height]="'22px'"></rwa-svg-pencil>
                </ng-template>
            </rwa-icon-ghost-button>
            <p class="h-padding"></p>
            <rwa-icon-ghost-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="자세히 보기"
                [sizeType]="'md'"
                borderRadius="14px"
                borderColor="var(--red-100)"
                hoverBgColor="var(--red-5)"
            >
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-pencil [width]="'22px'" [height]="'22px'" [color]="'var(--red-100)'"></rwa-svg-pencil>
                </ng-template>
            </rwa-icon-ghost-button>
            <p class="h-padding"></p>
            <rwa-icon-ghost-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="자세히 보기"
                [rwTooltipDisabled]="true"
                [disabled]="true"
                [sizeType]="'md'"
                borderRadius="14px"
                borderColor="var(--red-100)"
                hoverBgColor="var(--red-5)"
            >
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-pencil [width]="'22px'" [height]="'22px'" [color]="'var(--gray-60)'"></rwa-svg-pencil>
                </ng-template>
            </rwa-icon-ghost-button>
        </div>
        <p class="v-padding"></p>
        <h3>아이콘 S</h3>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <p class="h-padding"></p>
            <rwa-icon-ghost-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="자세히 보기"
                [sizeType]="'sm'"
                borderRadius="12px"
                borderColor="var(--gray-60)"
            >
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-dots-three [width]="'19px'" [height]="'19px'"></rwa-svg-dots-three>
                </ng-template>
            </rwa-icon-ghost-button>
            <p class="h-padding"></p>
            <rwa-icon-ghost-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="자세히 보기"
                [rwTooltipDisabled]="true"
                [disabled]="true"
                [sizeType]="'sm'"
                borderRadius="12px"
                borderColor="var(--gray-60)"
            >
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-dots-three
                        [width]="'19px'"
                        [height]="'19px'"
                        [color]="'var(--gray-60)'"
                    ></rwa-svg-dots-three>
                </ng-template>
            </rwa-icon-ghost-button>
        </div>
    </div>

    <p class="v-padding"></p>
    <h2>아이콘 버튼</h2>
    <p class="v-padding"></p>
    <div>
        <h3>아이콘 XL</h3>
        <div class="v-padding"></div>
        <div class="d-flex align-items-center">
            <p class="h-padding"></p>
            <rwa-icon-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="회원 추가하기"
                [showTail]="true"
                sizeType="lg"
                bgColor="var(--red-100)"
                hoverBgColor="var(--red-200)"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-plus color="var(--white)" [width]="'24px'" [height]="'24px'"></rwa-svg-plus>
                </ng-template>
            </rwa-icon-button>
            <p class="h-padding"></p>
            <rwa-icon-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="회원 추가하기"
                sizeType="lg"
                bgColor="var(--gray-90)"
                hoverBgColor="var(--gray-100)"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-plus color="var(--white)" [width]="'24px'" [height]="'24px'"></rwa-svg-plus>
                </ng-template>
            </rwa-icon-button>
            <p class="h-padding"></p>
            <rwa-icon-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="수정하기"
                [sizeType]="'lg'"
                bgColor="var(--white)"
                hoverBgColor="var(--gray-30)"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-pencil [width]="'24px'" [height]="'24px'"></rwa-svg-pencil>
                </ng-template>
            </rwa-icon-button>
            <p class="h-padding"></p>
            <rwa-icon-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="수정하기"
                [rwTooltipDisabled]="true"
                [disabled]="true"
                [sizeType]="'lg'"
                bgColor="var(--white)"
                hoverBgColor="var(--gray-30)"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-pencil [width]="'24px'" [height]="'24px'" [color]="'var(--gray-60)'"></rwa-svg-pencil>
                </ng-template>
            </rwa-icon-button>
        </div>
        <p class="v-padding"></p>
        <h3>아이콘 M</h3>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <p class="h-padding"></p>
            <rwa-icon-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="회원 추가하기"
                [sizeType]="'md'"
                borderRadius="14px"
                bgColor="var(--red-100)"
                hoverBgColor="var(--red-200)"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-plus color="var(--white)" [width]="'22px'" [height]="'22px'"></rwa-svg-plus>
                </ng-template>
            </rwa-icon-button>
            <p class="h-padding"></p>
            <rwa-icon-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="회원 추가하기"
                [sizeType]="'md'"
                borderRadius="14px"
                bgColor="var(--gray-90)"
                hoverBgColor="var(--gray-100)"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-plus color="var(--white)" [width]="'22px'" [height]="'22px'"></rwa-svg-plus>
                </ng-template>
            </rwa-icon-button>
            <p class="h-padding"></p>
            <rwa-icon-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="수정하기"
                [sizeType]="'md'"
                borderRadius="14px"
                bgColor="var(--white)"
                hoverBgColor="var(--gray-30)"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-pencil [width]="'22px'" [height]="'22px'"></rwa-svg-pencil>
                </ng-template>
            </rwa-icon-button>
            <p class="h-padding"></p>
            <rwa-icon-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="수정하기"
                [rwTooltipDisabled]="true"
                [disabled]="true"
                [sizeType]="'md'"
                borderRadius="14px"
                bgColor="var(--white)"
                hoverBgColor="var(--gray-30)"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-pencil [width]="'22px'" [height]="'22px'" [color]="'var(--gray-60)'"></rwa-svg-pencil>
                </ng-template>
            </rwa-icon-button>
        </div>
        <p class="v-padding"></p>
        <h3>아이콘 S</h3>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <p class="h-padding"></p>
            <rwa-icon-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="회원 추가하기"
                [sizeType]="'sm'"
                borderRadius="12px"
                bgColor="var(--red-100)"
                hoverBgColor="var(--red-200)"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-plus color="var(--white)" [width]="'19px'" [height]="'19px'"></rwa-svg-plus>
                </ng-template>
            </rwa-icon-button>
            <p class="h-padding"></p>
            <rwa-icon-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="회원 추가하기"
                [sizeType]="'sm'"
                borderRadius="12px"
                bgColor="var(--gray-90)"
                hoverBgColor="var(--gray-100)"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-plus color="var(--white)" [width]="'19px'" [height]="'19px'"></rwa-svg-plus>
                </ng-template>
            </rwa-icon-button>
            <p class="h-padding"></p>
            <rwa-icon-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="수정하기"
                [sizeType]="'sm'"
                borderRadius="12px"
                bgColor="var(--white)"
                hoverBgColor="var(--gray-30)"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-pencil [width]="'19px'" [height]="'19px'"></rwa-svg-pencil>
                </ng-template>
            </rwa-icon-button>
            <p class="h-padding"></p>
            <rwa-icon-button
                rwTooltip
                rwTooltipPlacement="bottom"
                rwTooltipTitle="수정하기"
                [rwTooltipDisabled]="true"
                [disabled]="true"
                [sizeType]="'sm'"
                borderRadius="12px"
                bgColor="var(--white)"
                hoverBgColor="var(--gray-30)"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-pencil [width]="'19px'" [height]="'19px'" [color]="'var(--gray-60)'"></rwa-svg-pencil>
                </ng-template>
            </rwa-icon-button>
        </div>
        <p class="v-padding"></p>
        <p class="v-padding"></p>
        <h3>아이콘 XS</h3>
        <p class="v-padding"></p>
        <div class="d-flex align-items-center">
            <p class="h-padding"></p>
            <rwa-xs-icon-button [width]="'26px'" [height]="'26px'">
                <div class="ccd"></div>
            </rwa-xs-icon-button>
            <p class="h-padding"></p>
            <div
                class="d-flex align-items-center justify-content-center"
                [ngStyle]="{
                    width: '40px',
                    height: '40px',
                    'background-color': 'var(--gray-90)',
                    'border-radius': '10px'
                }"
            >
                <rwa-xs-icon-button [type]="'fill'" width="27px" height="27px" borderRadius="12px">
                    <rwa-svg-close color="var(--white)" [width]="'15px'" [height]="'15px'"></rwa-svg-close>
                </rwa-xs-icon-button>
            </div>
        </div>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h2>체크박스</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <h3>no label [디폴트 / 비활성화]</h3>
        <div class="h-padding"></div>
        <rwa-checkbox [checked]="checkbox1" (onClick)="onCheckBox1($event)"></rwa-checkbox>
        <div class="h-padding"></div>
        <rwa-checkbox [disable]="true" [checked]="checkbox1" (onClick)="onCheckBox1($event)"></rwa-checkbox>
        <div class="h-padding"></div>
        <h3>with label [디폴트 / 비활성화]</h3>
        <div class="h-padding"></div>
        <rwa-checkbox [checked]="checkbox2" (onClick)="onCheckBox2($event)" label="label"></rwa-checkbox>
        <div class="h-padding"></div>
        <rwa-checkbox
            [checked]="checkbox2"
            (onClick)="onCheckBox2($event)"
            [disable]="true"
            label="label"
        ></rwa-checkbox>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <h2>라디오 버튼</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <h3>no label [디폴트 / 비활성화]</h3>
        <div class="h-padding"></div>
        <rwa-radio-button [checked]="radio1" (onClick)="onRadio1($event)"></rwa-radio-button>
        <div class="h-padding"></div>
        <rwa-radio-button [disable]="true" [checked]="radio1" (onClick)="onRadio1($event)"></rwa-radio-button>
        <div class="h-padding"></div>
        <h3>with label [디폴트 / 비활성화]</h3>
        <div class="h-padding"></div>
        <rwa-radio-button [checked]="radio2" (onClick)="onRadio2($event)" label="label"></rwa-radio-button>
        <div class="h-padding"></div>
        <rwa-radio-button
            [checked]="radio2"
            (onClick)="onRadio2($event)"
            [disable]="true"
            label="label"
        ></rwa-radio-button>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <h2>토글 버튼</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <h3>no label [디폴트 / 비활성화]</h3>
        <div class="h-padding"></div>
        <rwa-toggle-button [checked]="toggle1" (onClick)="ontoggle1($event)"></rwa-toggle-button>
        <div class="h-padding"></div>
        <rwa-toggle-button [disable]="true" [checked]="toggle1" (onClick)="ontoggle1($event)"></rwa-toggle-button>
        <div class="h-padding"></div>
        <h3>with label [디폴트 / 비활성화]</h3>
        <div class="h-padding"></div>
        <rwa-toggle-button [checked]="toggle2" (onClick)="ontoggle2($event)" label="label"></rwa-toggle-button>
        <div class="h-padding"></div>
        <rwa-toggle-button
            [checked]="toggle2"
            (onClick)="ontoggle2($event)"
            [disable]="true"
            label="label"
        ></rwa-toggle-button>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <h2>탭 M</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-tab [tabs]="tabInputs1" [label]="'레이블'" (onTabItemSelected)="onItemSelected1($event)"></rwa-tab>
        <div class="h-padding"></div>
        <rwa-tab [tabs]="tabInputs2" [label]="'레이블'" (onTabItemSelected)="tabInputs2 = $event"></rwa-tab>
    </div>
    <p class="v-padding"></p>
    <h2>탭 S</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-tab-sm [tabs]="tabInputs3" (onTabItemSelected)="onItemSelected3($event)"></rwa-tab-sm>
        <div class="h-padding"></div>
        <rwa-tab-sm [tabs]="tabInputs4" (onTabItemSelected)="tabInputs4 = $event"></rwa-tab-sm>
    </div>
    <p class="v-padding"></p>
    <h2>탭 S- 폴더</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-sm-icon-tab [tabs]="tabInputs3" (onTabItemSelected)="onItemSelected3($event)"></rwa-sm-icon-tab>
    </div>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-sm-icon-tab
            [tabs]="tabInputs4"
            [tabColor]="'rgba(255, 232, 133, 1)'"
            (onTabItemSelected)="tabInputs4 = $event"
        ></rwa-sm-icon-tab>
    </div>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-sm-icon-tab
            [tabs]="tabInputs4"
            [tabColor]="'rgba(187, 225, 123, 1)'"
            (onTabItemSelected)="tabInputs4 = $event"
        ></rwa-sm-icon-tab>
    </div>
    <p class="v-padding"></p>
    <h2>페이지 버튼</h2>
    <p class="v-padding"></p>
    <h3>10페이지 이하</h3>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-page-button [pageNumber]="1" (onPageNumberClick)="onPb1Click($event)"></rwa-page-button>
        <div class="h-padding"></div>
        <rwa-page-button [pageNumber]="6" (onPageNumberClick)="onPb1Click($event)"></rwa-page-button>
        <div class="h-padding"></div>
        <rwa-page-button [pageNumber]="7" (onPageNumberClick)="onPb1Click($event)"></rwa-page-button>
        <div class="h-padding"></div>
        <rwa-page-button [pageNumber]="10" (onPageNumberClick)="onPb1Click($event)"></rwa-page-button>
    </div>
    <p class="v-padding"></p>
    <h3>10페이지 초과</h3>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-page-button [pageNumber]="12" [disable]="true"></rwa-page-button>
        <div class="h-padding"></div>
        <rwa-page-button
            [pageNumber]="999"
            [disable]="false"
            (onPageNumberClick)="onPb1Click($event)"
        ></rwa-page-button>
        <div class="h-padding"></div>
        <rwa-page-button [pageNumber]="999" [disable]="false"></rwa-page-button>
    </div>
    <p class="v-padding"></p>
    <h3>비활성화</h3>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-page-button [pageNumber]="6" [disable]="true"></rwa-page-button>
        <div class="h-padding"></div>
        <rwa-page-button [pageNumber]="10" [disable]="true"></rwa-page-button>
    </div>
    <p class="v-padding"></p>
    <h2>페이지 버튼</h2>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h3>슬라이드 1장</h3>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-slide-button [slideNumber]="slideNumber1" [(curNumber)]="curSlideNumber1"></rwa-slide-button>
        <div class="h-padding"></div>
        <rwa-slide-button
            [allowAdditionalNext]="true"
            [slideNumber]="slideNumber1_1"
            [(curNumber)]="curSlideNumber1_1"
        ></rwa-slide-button>
    </div>
    <p class="v-padding"></p>
    <h3>슬라이드 2장 이상</h3>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-slide-button [slideNumber]="slideNumber2" [(curNumber)]="curSlideNumber2"></rwa-slide-button>
        <div class="h-padding"></div>
        <rwa-slide-button
            [allowAdditionalNext]="true"
            [slideNumber]="slideNumber2_1"
            [(curNumber)]="curSlideNumber2_1"
        ></rwa-slide-button>
    </div>
    <p class="v-padding"></p>

    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h1>- 텍스트 필드</h1>
    <p class="v-padding"></p>
    <h2 (click)="text1 = '일반 텍스트 다른 곳에서 변경 테스트'">일반</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-text-field
            [isImportant]="true"
            [value]="text1"
            (onValueChange)="onText1Change($event)"
            [placeholder]="'플레이스 홀더'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [value]="text2"
            (onValueChange)="onText2Change($event)"
            [type]="'wordLimit'"
            [inputLimit]="90"
            [placeholder]="'플레이스 홀더'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [value]="text1"
            (onValueChange)="onText1Change($event)"
            [placeholder]="'플레이스 홀더'"
            [type]="'timeLimit'"
            [advice]="'도움말'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field [placeholder]="'플레이스 홀더'" [disable]="true"></rwa-text-field>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-text-field
            [status]="'error'"
            [statusText]="'에러 메시지'"
            [placeholder]="'플레이스 홀더'"
            [advice]="'도움말'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [type]="'wordLimit'"
            [inputLimit]="50"
            [placeholder]="'플레이스 홀더'"
            [status]="'warning'"
            [statusText]="'경고 메시지'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [type]="'timeLimit'"
            [placeholder]="'플레이스 홀더'"
            [status]="'error'"
            [statusText]="'에러 메시지'"
        ></rwa-text-field>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-text-field
            [status]="'success'"
            [statusText]="'에러 메시지'"
            [placeholder]="'플레이스 홀더'"
            [advice]="'도움말'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [type]="'wordLimit'"
            [placeholder]="'플레이스 홀더'"
            [status]="'success'"
            [statusText]="'경고 메시지'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [type]="'timeLimit'"
            [placeholder]="'플레이스 홀더'"
            [status]="'success'"
            [statusText]="'에러 메시지'"
        ></rwa-text-field>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-text-field [placeholder]="'플레이스 홀더'" [hint]="'힌트 메시지'"></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field [placeholder]="'플레이스 홀더'" [disable]="true"></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field [labelVisible]="false" [placeholder]="'플레이스 홀더'" [advice]="'도움말'"></rwa-text-field>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>

    <p class="v-padding"></p>
    <h2>
        일반 -- value accessor version (기능 구현 방식의 차이만 있는 테스트 용이므로 위에 있는 "일반" 컴포넌트만
        확인하시면 됩니다.)
    </h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-text-input
            [isImportant]="true"
            [ngModel]="textInput1"
            [maxlength]="5"
            pattern="^\d{4}$"
            (ngModelChange)="onTextInput1Change($event)"
            [placeholder]="'플레이스 홀더'"
        ></rwa-text-input>
        <div class="h-padding"></div>
        <rwa-text-input
            [(ngModel)]="textInput2"
            [type]="'wordLimit'"
            [maxLength]="10"
            [placeholder]="'플레이스 홀더'"
        ></rwa-text-input>
        <div class="h-padding"></div>
        <rwa-text-input
            [formControl]="textInput3"
            [placeholder]="'플레이스 홀더'"
            [type]="'timeLimit'"
            [advice]="'도움말'"
        ></rwa-text-input>
        <div class="h-padding"></div>
        <rwa-text-input [placeholder]="'플레이스 홀더'" [(ngModel)]="textInput2" [disabled]="true"></rwa-text-input>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-text-input
            [status]="'error'"
            [statusText]="'에러 메시지'"
            [placeholder]="'플레이스 홀더'"
            [advice]="'도움말'"
        ></rwa-text-input>
        <div class="h-padding"></div>
        <rwa-text-input
            [type]="'wordLimit'"
            [maxLength]="50"
            [placeholder]="'플레이스 홀더'"
            [status]="'warning'"
            [statusText]="'경고 메시지'"
        ></rwa-text-input>
        <div class="h-padding"></div>
        <rwa-text-input
            [type]="'timeLimit'"
            [placeholder]="'플레이스 홀더'"
            [status]="'error'"
            [statusText]="'에러 메시지'"
        ></rwa-text-input>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-text-input
            [status]="'success'"
            [statusText]="'에러 메시지'"
            [placeholder]="'플레이스 홀더'"
            [advice]="'도움말'"
            [(ngModel)]="textInput1"
        ></rwa-text-input>
        <div class="h-padding"></div>
        <rwa-text-input
            [type]="'wordLimit'"
            [placeholder]="'플레이스 홀더'"
            [status]="'success'"
            [statusText]="'경고 메시지'"
            [(ngModel)]="textInput1"
        ></rwa-text-input>
        <div class="h-padding"></div>
        <rwa-text-input
            [type]="'timeLimit'"
            [placeholder]="'플레이스 홀더'"
            [status]="'success'"
            [statusText]="'에러 메시지'"
            [(ngModel)]="textInput2"
        ></rwa-text-input>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-text-input
            [placeholder]="'플레이스 홀더'"
            [(ngModel)]="textInput1"
            [hint]="'힌트 메시지'"
        ></rwa-text-input>
        <div class="h-padding"></div>
        <rwa-text-input [placeholder]="'플레이스 홀더'" [(ngModel)]="textInput1" [disabled]="true"></rwa-text-input>
        <div class="h-padding"></div>
        <rwa-text-input
            [labelVisible]="false"
            [(ngModel)]="textInput2"
            [placeholder]="'플레이스 홀더'"
            [advice]="'도움말'"
        ></rwa-text-input>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <p class="v-padding"></p>

    <h2>패스워드</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-text-field
            [value]="text1"
            (onValueChange)="onText1Change($event)"
            [label]="'비밀번호'"
            [textFieldType]="'password'"
            [placeholder]="'플레이스 홀더'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [labelVisible]="false"
            [value]="text1"
            (onValueChange)="onText1Change($event)"
            [textFieldType]="'password'"
            [placeholder]="'플레이스 홀더'"
            [hint]="'힌트 메시지'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [value]="text1"
            (onValueChange)="onText1Change($event)"
            [label]="'비밀번호'"
            [textFieldType]="'password'"
            [placeholder]="'플레이스 홀더'"
            [hint]="'힌트 메시지'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [value]="text2"
            [label]="'비밀번호'"
            [status]="'error'"
            [statusText]="'에러 메시지'"
            (onValueChange)="onText2Change($event)"
            [textFieldType]="'password'"
            [placeholder]="'플레이스 홀더'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [value]="text1"
            [label]="'비밀번호'"
            [status]="'warning'"
            [statusText]="'경고 메시지'"
            (onValueChange)="onText1Change($event)"
            [textFieldType]="'password'"
            [placeholder]="'플레이스 홀더'"
            [advice]="'도움말'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [value]="text2"
            [label]="'비밀번호'"
            [status]="'success'"
            (onValueChange)="onText2Change($event)"
            [textFieldType]="'password'"
            [placeholder]="'플레이스 홀더'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field [textFieldType]="'password'" [placeholder]="'플레이스 홀더'" [disable]="true"></rwa-text-field>
    </div>
    <p class="v-padding"></p>
    <h2>검색</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-text-field
            [value]="text1"
            (onValueChange)="onText1Change($event)"
            [textFieldType]="'search'"
            [placeholder]="'플레이스 홀더'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [value]="text1"
            [labelVisible]="false"
            (onValueChange)="onText1Change($event)"
            [textFieldType]="'search'"
            [placeholder]="'플레이스 홀더'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [value]="text1"
            (onValueChange)="onText1Change($event)"
            [textFieldType]="'search'"
            [placeholder]="'플레이스 홀더'"
            [hint]="'힌트 메시지'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [labelVisible]="false"
            [value]="text1"
            (onValueChange)="onText1Change($event)"
            [textFieldType]="'search'"
            [placeholder]="'플레이스 홀더'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [value]="text2"
            (onValueChange)="onText2Change($event)"
            [status]="'error'"
            [statusText]="'에러 메시지'"
            [textFieldType]="'search'"
            [placeholder]="'플레이스 홀더'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [value]="text1"
            (onValueChange)="onText1Change($event)"
            [status]="'warning'"
            [statusText]="'경고 메시지'"
            [placeholder]="'플레이스 홀더'"
            [textFieldType]="'search'"
            [advice]="'도움말'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field
            [value]="text1"
            (onValueChange)="onText1Change($event)"
            [status]="'success'"
            [placeholder]="'플레이스 홀더'"
            [textFieldType]="'search'"
            [advice]="'도움말'"
        ></rwa-text-field>
        <div class="h-padding"></div>
        <rwa-text-field [textFieldType]="'search'" [placeholder]="'플레이스 홀더'" [disable]="true"></rwa-text-field>
    </div>

    <p class="v-padding"></p>
    <h2>숫자</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-number-text-field
            [labelVisible]="false"
            [value]="numberText1"
            (onValueChange)="onNumberText1Change($event)"
        ></rwa-number-text-field>
        <div class="h-padding"></div>
        <rwa-number-text-field
            [value]="numberText2"
            (onValueChange)="onNumberText2Change($event)"
            [hint]="'힌트 메시지'"
        ></rwa-number-text-field>
        <div class="h-padding"></div>
        <rwa-number-text-field [advice]="'도움말'"></rwa-number-text-field>
        <div class="h-padding"></div>
        <rwa-number-text-field
            [value]="numberText1"
            (onValueChange)="onNumberText1Change($event)"
            [disable]="true"
        ></rwa-number-text-field>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-number-text-field
            [status]="'error'"
            [statusText]="'에러 메시지'"
            [value]="numberText1"
            (onValueChange)="onNumberText1Change($event)"
        ></rwa-number-text-field>
        <div class="h-padding"></div>
        <rwa-number-text-field
            [status]="'warning'"
            [statusText]="'경고 메시지'"
            [value]="numberText2"
            (onValueChange)="onNumberText2Change($event)"
        ></rwa-number-text-field>
        <div class="h-padding"></div>
        <rwa-number-text-field [advice]="'도움말'"></rwa-number-text-field>
        <div class="h-padding"></div>
        <rwa-number-text-field [disable]="true"></rwa-number-text-field>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-number-text-field
            [value]="numberText1"
            (onValueChange)="onNumberText1Change($event)"
        ></rwa-number-text-field>
        <div class="h-padding"></div>
        <rwa-number-text-field
            [value]="numberText2"
            (onValueChange)="onNumberText2Change($event)"
        ></rwa-number-text-field>
        <div class="h-padding"></div>
        <rwa-number-text-field [advice]="'도움말'"></rwa-number-text-field>
        <div class="h-padding"></div>
        <rwa-number-text-field [disable]="true"></rwa-number-text-field>
    </div>
    <p class="v-padding"></p>
    <h2>인증번호</h2>
    <div class="d-flex align-items-center">
        <rwa-verification-field
            [labelVisible]="false"
            [value]="verifForm.value"
            (onValueChange)="onVerifFormChange($event)"
        ></rwa-verification-field>
        <div class="h-padding"></div>
        <div class="h-padding"></div>
        <rwa-verification-field [formControl]="verifForm1" [hint]="'힌트 메시지'"></rwa-verification-field>
        <div class="h-padding"></div>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-verification-field
            [labelVisible]="false"
            [value]="verificationNumber"
            [status]="'warning'"
            [statusText]="'경고 메시지'"
            (onValueChange)="onVerificationNumberChange($event)"
        ></rwa-verification-field>
        <div class="h-padding"></div>
        <div class="h-padding"></div>
        <rwa-verification-field
            [value]="verificationNumber"
            (onValueChange)="onVerificationNumberChange($event)"
            [status]="'error'"
            [statusText]="'에러 메시지'"
            [hint]="'힌트 메시지'"
        ></rwa-verification-field>
        <div class="h-padding"></div>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h2>텍스트 필드 - 버튼</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-textfield-button [value]="'입력한 정보'"></rwa-textfield-button>
        <div class="h-padding"></div>
        <rwa-textfield-button
            [tagText]="'자세히 보기'"
            [advice]="'도움말'"
            [value]="'입력한 정보'"
        ></rwa-textfield-button>
        <div class="h-padding"></div>
        <rwa-textfield-button [hint]="'힌트 메시지'" [value]="'입력한 정보'"></rwa-textfield-button>
        <div class="h-padding"></div>
        <rwa-textfield-button [disable]="true" [value]="'입력한 정보'"></rwa-textfield-button>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-textfield-button [value]="'입력한 정보'"></rwa-textfield-button>
        <div class="h-padding"></div>
        <rwa-textfield-button
            [value]="'입력한 정보'"
            [status]="'error'"
            [statusText]="'에러 메시지'"
        ></rwa-textfield-button>
        <div class="h-padding"></div>
        <rwa-textfield-button
            [value]="'입력한 정보'"
            [status]="'warning'"
            [statusText]="'경고 메시지'"
        ></rwa-textfield-button>
        <div class="h-padding"></div>
    </div>

    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h2>텍스트 필드 - 드롭다운</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-textfield-dropdown
            [label]="'label'"
            [items]="dropdown1"
            [value]="ddV01"
            (onSelectValue)="ddV01 = $event"
        ></rwa-textfield-dropdown>
        <div class="h-padding"></div>
        <rwa-textfield-dropdown
            [label]="'label'"
            [items]="dropdown1"
            [value]="ddV1"
            (onSelectValue)="ddV1 = $event"
        ></rwa-textfield-dropdown>
        <div class="h-padding"></div>
        <rwa-textfield-dropdown
            advice="도움말"
            label="레이블"
            [items]="dropdown1"
            [value]="ddV1"
            (onSelectValue)="ddV1 = $event"
        ></rwa-textfield-dropdown>
        <div class="h-padding"></div>
        <rwa-textfield-dropdown
            [items]="dropdown2"
            [value]="ddV2"
            (onSelectValue)="ddV2 = $event"
            label="레이블"
            hint="힌트 메시지"
        ></rwa-textfield-dropdown>
        <div class="h-padding"></div>
        <rwa-textfield-dropdown
            [items]="dropdown2"
            [value]="ddV2"
            (onSelectValue)="ddV2 = $event"
            [labelVisible]="false"
            [disable]="true"
        ></rwa-textfield-dropdown>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <div class="d-flex align-items-center">
            <rwa-textfield-dropdown
                [items]="dropdown1"
                [value]="ddV1"
                (onSelectValue)="ddV1 = $event"
                [label]="'label'"
            ></rwa-textfield-dropdown>
            <div class="h-padding"></div>
            <rwa-textfield-dropdown
                [items]="dropdown1"
                [value]="ddV1"
                (onSelectValue)="ddV1 = $event"
                advice="도움말"
                label="레이블"
                status="warning"
                statusText="경고 메시지"
            ></rwa-textfield-dropdown>
            <div class="h-padding"></div>
            <rwa-textfield-dropdown
                [items]="dropdown2"
                [value]="ddV2"
                (onSelectValue)="ddV2 = $event"
                label="레이블"
                hint="힌트 메시지"
                status="error"
                statusText="에러 메시지"
            ></rwa-textfield-dropdown>
            <div class="h-padding"></div>
            <rwa-textfield-dropdown
                [items]="dropdown2"
                [value]="ddV2"
                (onSelectValue)="ddV2 = $event"
                [labelVisible]="false"
            ></rwa-textfield-dropdown>
            <div class="h-padding"></div>
        </div>
    </div>

    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h2>텍스트 필드 - 메모</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-memo [formControl]="memo1" (onButtonClick)="onMemo1ButtonClick()"></rwa-memo>
        <div class="h-padding"></div>
        <rwa-memo
            [bgColor]="'var(--gray-30)'"
            [formControl]="memo2"
            [showButton]="true"
            (onButtonClick)="onMemo2ButtonClick()"
        ></rwa-memo>
        <div class="h-padding"></div>
        <rwa-memo [bgColor]="'var(--gray-30)'" [formControl]="memo3" [showButton]="true"></rwa-memo>
        <div class="h-padding"></div>
        <rwa-memo [bgColor]="'var(--gray-30)'" [(ngModel)]="memo4"></rwa-memo>
        <div class="h-padding"></div>
    </div>

    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h2>모달 - 기본</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <div [style.cursor]="'pointer'" (click)="modalBasic1 = true">modal basic1</div>
        <rwa-modal
            [(visible)]="modalBasic1"
            [data]="modalBasic1Data"
            (cancel)="modalBasic1 = false"
            (confirm)="modalBasic1 = false"
        ></rwa-modal>
        <div class="h-padding"></div>
        <div [style.cursor]="'pointer'" (click)="modalBasic1_5 = true">modal basic2</div>
        <rwa-modal
            [(visible)]="modalBasic1_5"
            [data]="modalBasic1_5Data"
            [type]="'oneButton'"
            [blockClickOutside]="true"
            (cancel)="modalBasic1_5 = false"
            (confirm)="modalBasic1_5 = false"
        ></rwa-modal>
        <div class="h-padding"></div>
        <div [style.cursor]="'pointer'" (click)="modalBasic2 = true">modal basic3</div>
        <rwa-modal
            [(visible)]="modalBasic2"
            [data]="modalBasic2Data"
            (cancel)="modalBasic2 = false"
            (confirm)="onModal2Confirm($event)"
        ></rwa-modal>
        <div class="h-padding"></div>
        <div [style.cursor]="'pointer'" (click)="modalSendLink = true">비밀번호 링크 재전송 모반</div>
        <rwa-modal
            [(visible)]="modalSendLink"
            [data]="modalSendLinkData"
            (cancel)="modalSendLink = false"
            (confirm)="modalSendLink = false"
        ></rwa-modal>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <h2>모달 - 단일 정보 입력 L (textarea)</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <div [style.cursor]="'pointer'" (click)="modalTa1 = true">modal textarea1</div>
        <rwa-textarea-modal
            [(visible)]="modalTa1"
            (cancel)="modalTa1 = false"
            (confirm)="modalTa1 = false"
        ></rwa-textarea-modal>
        <div class="h-padding"></div>
        <div [style.cursor]="'pointer'" (click)="modalTa2 = true">modal textarea2</div>
        <rwa-textarea-modal
            [(visible)]="modalTa2"
            [type]="'twoButton'"
            [text]="modalTa2Text"
            [blockClickOutside]="true"
            (cancel)="onModalTa2Cancel()"
            (confirm)="onModalTa2Confirm($event)"
        ></rwa-textarea-modal>
        <div class="h-padding"></div>
        <div [style.cursor]="'pointer'" (click)="modalTa3 = true">modal textarea3</div>
        <rwa-textarea-modal
            [title]="'모달 정보 입력 타이틀'"
            [(visible)]="modalTa3"
            [text]="modalTa3Text"
            (cancel)="onModalTa3Cancel()"
            (confirm)="onModalTa3Confirm($event)"
        ></rwa-textarea-modal>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h2>캘린더 - 한 달</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <div>
            <span>{{ datepicker1 | json }}</span>
            <div class="v-padding"></div>
            <rwm-datepicker [mode]="'date'" [(data)]="datepicker1"></rwm-datepicker>
        </div>
        <div class="h-padding"></div>
        <div>
            <span>{{ datepicker2 | json }}</span>
            <div class="v-padding"></div>
            <rwm-datepicker [mode]="'multiline'" [(data)]="datepicker2"></rwm-datepicker>
        </div>
        <div class="h-padding"></div>
        <div>
            <span>{{ datepicker3 | json }}</span>
            <div class="v-padding"></div>
            <rwm-datepicker [mode]="'multiline'" [option]="'register'" [(data)]="datepicker3"></rwm-datepicker>
        </div>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h2>캘린더 - 두 달</h2>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwm-tm-datepicker [mode]="'date'" [(data)]="datepicker4"></rwm-tm-datepicker>
        <div class="h-padding"></div>
        <span>{{ datepicker4 | json }}</span>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwm-tm-datepicker [mode]="'multiline'" [(data)]="datepicker5"></rwm-tm-datepicker>
        <div class="h-padding"></div>
        <span>{{ datepicker5 | json }}</span>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwm-tm-datepicker [mode]="'multiline'" [option]="'register'" [(data)]="datepicker6"></rwm-tm-datepicker>
        <div class="h-padding"></div>
        <span>{{ datepicker6 | json }}</span>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h2>매출</h2>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h3>매출 요약</h3>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwm-sale-summary [curSummary]="saleSum0.this_month" [prevSummary]="saleSum0.last_month"></rwm-sale-summary>
        <div class="h-padding"></div>
        <rwm-sale-summary
            [summaryType]="'today'"
            [curSummary]="saleSum0.today"
            [prevSummary]="saleSum0.yesterday"
        ></rwm-sale-summary>
    </div>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwm-sale-summary [curSummary]="saleSum1.this_month" [prevSummary]="saleSum1.last_month"></rwm-sale-summary>
        <div class="h-padding"></div>
        <rwm-sale-summary
            [summaryType]="'today'"
            [curSummary]="saleSum1.today"
            [prevSummary]="saleSum1.yesterday"
        ></rwm-sale-summary>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwm-sale-summary [curSummary]="saleSum2.this_month" [prevSummary]="saleSum2.last_month"></rwm-sale-summary>
        <div class="h-padding"></div>
        <rwm-sale-summary
            [summaryType]="'today'"
            [curSummary]="saleSum2.today"
            [prevSummary]="saleSum2.yesterday"
        ></rwm-sale-summary>
    </div>

    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h3>매출 필터</h3>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwm-sale-filter [filterType]="'date'" [disabled]="true"></rwm-sale-filter>
        <div class="h-padding"></div>
        <rwm-sale-filter [filterType]="'date'" [(date)]="dateFilter"></rwm-sale-filter>
        <div class="h-padding"></div>
        <rwm-sale-filter
            [filterType]="'paymentType'"
            [(paymentType)]="saleFilter1"
            (paymentTypeChange)="onSaleFilter1Change($event)"
        ></rwm-sale-filter>
        <div class="h-padding"></div>
        <rwm-sale-filter [filterType]="'member'"></rwm-sale-filter>
        <div class="h-padding"></div>
        <rwm-sale-filter [filterType]="'productType'"></rwm-sale-filter>
        <div class="h-padding"></div>
        <rwm-sale-filter [filterType]="'productName'"></rwm-sale-filter>
        <div class="h-padding"></div>
        <rwm-sale-filter [filterType]="'personInCharge'"></rwm-sale-filter>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h2>결제 상품</h2>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h2>pc 화면</h2>
    <p class="v-padding"></p>

    <div class="d-flex align-items-center">
        <rwa-payment-product-item
            [paymentItem]="paymentItemList[0]"
            [(selected)]="pi1Selected"
            (onClick)="onPi1Selected($event)"
        ></rwa-payment-product-item>
        <div class="h-padding"></div>
        <rwa-payment-product-item
            [paymentItem]="paymentItemList[1]"
            [(selected)]="pi2Selected"
            (onClick)="onPi2Selected()"
        ></rwa-payment-product-item>
        <div class="h-padding"></div>
        <rwa-payment-product-item
            [paymentItem]="paymentItemList[2]"
            [(selected)]="pi3Selected"
            (onClick)="onPi3Selected()"
        ></rwa-payment-product-item>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <h2>tablet 화면</h2>
    <p class="v-padding"></p>
    <p class="v-padding"></p>

    <div class="d-flex align-items-center">
        <rwa-payment-product-item
            [mode]="'tablet'"
            [paymentItem]="paymentItemList[0]"
            [(selected)]="pi1Selected"
            (onClick)="onPi1Selected($event)"
        ></rwa-payment-product-item>
        <div class="h-padding"></div>
        <rwa-payment-product-item
            [mode]="'tablet'"
            [paymentItem]="paymentItemList[1]"
            [(selected)]="pi2Selected"
            (onClick)="onPi2Selected()"
        ></rwa-payment-product-item>
        <div class="h-padding"></div>
        <rwa-payment-product-item
            [mode]="'tablet'"
            [paymentItem]="paymentItemList[2]"
            [(selected)]="pi3Selected"
            (onClick)="onPi3Selected()"
        ></rwa-payment-product-item>
        <div class="h-padding"></div>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <div class="d-flex align-items-center">
        <rwa-button (onClick)="showPaymentError = !showPaymentError">
            <ng-template btIdleContent>결제 에러 모달</ng-template>
        </rwa-button>
    </div>
    <p class="v-padding"></p>
    <p class="v-padding"></p>
    <!--    <h1>내 정보</h1>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->

    <!--    <rwa-button (onClick)="showMyProfileModal = !showMyProfileModal">-->
    <!--        <ng-template btIdleContent>내 정보 버튼</ng-template>-->
    <!--    </rwa-button>-->

    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <rwa-button (onClick)="showMyProfileModal2 = !showMyProfileModal2">-->
    <!--        <ng-template btIdleContent>내 정보 버튼</ng-template>-->
    <!--    </rwa-button>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h2>내 정보 / 프로필 사진</h2>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <div class="d-flex align-items-center">-->
    <!--        <rwm-profile-photo [user]="dummyUser"></rwm-profile-photo>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-profile-photo [user]="dummyUser"></rwm-profile-photo>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-profile-photo [user]="dummyUser2"></rwm-profile-photo>-->
    <!--    </div>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h1>내 센터</h1>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h2>내 센터 / 센터 리스트</h2>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h3>내 센터 / 센터 리스트 / 초대 및 기본</h3>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <div class="d-flex align-items-center">-->
    <!--        <rwm-center-list-item [center]="centerList[0]"></rwm-center-list-item>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-list-item [center]="centerList[0]"></rwm-center-list-item>-->
    <!--    </div>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <div class="d-flex align-items-center">-->
    <!--        <rwm-center-list-item [center]="centerList[0]"></rwm-center-list-item>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-list-item [center]="centerList[0]"></rwm-center-list-item>-->
    <!--    </div>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h3>내 센터 / 센터 리스트 / 무료 체험</h3>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <div class="d-flex align-items-center">-->
    <!--        <rwm-center-list-item [center]="centerList[0]"></rwm-center-list-item>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-list-item [center]="centerList[0]"></rwm-center-list-item>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-list-item [center]="centerList[1]"></rwm-center-list-item>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-list-item [center]="centerList[2]"></rwm-center-list-item>-->
    <!--    </div>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h3>내 센터 / 센터 리스트 / 1년, 2년 이용권</h3>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <div class="d-flex align-items-center">-->
    <!--        <rwm-center-list-item [center]="centerList[3]"></rwm-center-list-item>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-list-item [center]="centerList[4]"></rwm-center-list-item>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-list-item [center]="centerList[5]"></rwm-center-list-item>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-list-item [center]="centerList[6]"></rwm-center-list-item>-->
    <!--    </div>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h3>내 센터 / 센터 리스트 / 월 이용권</h3>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <div class="d-flex align-items-center">-->
    <!--        <rwm-center-list-item [center]="centerList[7]"></rwm-center-list-item>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-list-item [center]="centerList[8]"></rwm-center-list-item>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-list-item [center]="centerList[9]"></rwm-center-list-item>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-list-item [center]="centerList[10]"></rwm-center-list-item>-->
    <!--    </div>-->

    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h2>메인 메뉴 / 센터 정보 드롭다운</h2>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <div class="d-flex align-items-center">-->
    <!--        <rwm-center-info-dropdown [center]="centers[0]"></rwm-center-info-dropdown>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-info-dropdown [center]="centers[1]" [centerList]="centers"></rwm-center-info-dropdown>-->
    <!--    </div>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h2>메인 메뉴 / 무료 체험 및 이용권 기간 안내</h2>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h3>메인 메뉴 / 무료 체험 및 이용권 기간 안내 &#45;&#45; 무료 체험</h3>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <div class="d-flex align-items-center">-->
    <!--        <rwm-center-product-info-box [center]="centerList[0]"></rwm-center-product-info-box>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-product-info-box [center]="centerList[1]"></rwm-center-product-info-box>-->
    <!--    </div>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h3>메인 메뉴 / 무료 체험 및 이용권 기간 안내 &#45;&#45; 1년, 2년 이용권</h3>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <div class="d-flex align-items-center">-->
    <!--        <rwm-center-product-info-box [center]="centerList[4]"></rwm-center-product-info-box>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-product-info-box [center]="centerList[5]"></rwm-center-product-info-box>-->
    <!--    </div>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h3>메인 메뉴 / 무료 체험 및 이용권 기간 안내 &#45;&#45; 월 이용권</h3>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <div class="d-flex align-items-center">-->
    <!--        <rwm-center-product-info-box [center]="centerList[8]"></rwm-center-product-info-box>-->
    <!--        <div class="h-padding"></div>-->
    <!--        <rwm-center-product-info-box [center]="centerList[9]"></rwm-center-product-info-box>-->
    <!--    </div>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h1>센터 설정</h1>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <div class="d-flex align-items-center">-->
    <!--        <rwa-button (onClick)="openSetCenter = true">-->
    <!--            <ng-template btIdleContent>센터 설정</ng-template>-->
    <!--        </rwa-button>-->
    <!--    </div>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <h2>센터 설정 / 직원 카드</h2>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <div class="d-flex align-items-center"></div>-->
    <!--    <p class="v-padding"></p>-->
    <!--    <p class="v-padding"></p>-->
</div>

<rwm-my-profile-modal
    [(visible)]="showMyProfileModal"
    (close)="showMyProfileModal = false"
    [user]="dummyUser"
></rwm-my-profile-modal>
<rwm-my-profile-modal
    [(visible)]="showMyProfileModal2"
    (close)="showMyProfileModal2 = false"
    [user]="dummyUser3"
></rwm-my-profile-modal>

<rwa-vacant-modal width="345px" height="481px" padding="25px 25px 30px" [(visible)]="showPaymentError">
    <div class="payment-error-modal">
        <div class="header">
            <div class="error-icon"></div>
            <div class="header-title">
                {{ paymentErrorData.text }}
            </div>
            <div class="header-desc">
                {{ paymentErrorData.subText }}
            </div>
        </div>
        <div class="body">
            <div class="body-title">
                {{ paymentErrorData.detailList[0].title }}
            </div>
            <div class="body-desc">
                {{ paymentErrorData.detailList[0].desc }}
            </div>
        </div>
        <div class="button">
            <rwa-button width="80px" height="45px" (onClick)="showPaymentError = false">
                <ng-template btIdleContent>확인</ng-template>
            </rwa-button>
        </div>
    </div>
</rwa-vacant-modal>
