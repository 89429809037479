<div class="rw-modal-background" #modalBackgroundElement></div>
<div
    class="rw-modal-wrapper justify-content-center align-items-center"
    (mouseup)="!isMouseModalDown && !blockClickOutside && onClose()"
    (mousedown)="resetMouseModalDown()"
    #modalWrapperElement
>
    <div class="rw-modal user-select-none" (mousedown)="onMouseModalDown(); $event.stopPropagation()">
        <div class="header">
            <div class="header-title">전화번호</div>
            <rwa-icon-ghost-button sizeType="md" borderRadius="14px" borderColor="var(--gray-60)" (onClick)="onClose()">
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-close width="22px" height="22px" color="var(--gray-100)"></rwa-svg-close>
                </ng-template>
            </rwa-icon-ghost-button>
        </div>
        <div class="body">
            <div class="l-text-input">
                <div class="text-input__label">실제로 연락이 가능한 전화번호를 입력해 주세요.</div>
                <div class="text-input__content">
                    <rwa-text-field
                        #phoneNumberRef
                        [width]="'110px'"
                        [placeholder]="'숫자'"
                        [labelVisible]="false"
                        [type]="'normal'"
                        [inputLimit]="4"
                        [formControl]="firstPhoneNumber"
                        (keyup.enter)="formCheck() && save()"
                    ></rwa-text-field>
                    <div class="dash">-</div>
                    <rwa-text-field
                        [width]="'180px'"
                        [placeholder]="'-없이 숫자만 입력'"
                        [labelVisible]="false"
                        [type]="'normal'"
                        [inputLimit]="9"
                        [formControl]="secondPhoneNumber"
                        (keyup.enter)="formCheck() && save()"
                    ></rwa-text-field>
                </div>
            </div>

            <rwa-button
                [disable]="firstPhoneNumber.invalid && secondPhoneNumber.invalid"
                class="save-button"
                padding="9px 25px 7px"
                (onClick)="onConfirm()"
                [status]="confirmButtonLoading"
                [loadingName]="'name-save'"
            >
                <ng-template btIdleContent><span class="save-button-text">저장하기</span></ng-template>
                <ng-template btPendingContent><span class="save-button-text">저장하기</span></ng-template>
            </rwa-button>
        </div>
    </div>
</div>
