<button
    #l_button
    class="l-radio-button user-select-none"
    [class.without-label]="!label && !isMouseDown"
    [class.with-label]="label && !isMouseDown"
    [class.look-normal-when-disabled]="lookNormalWhenDisabled"
    [disabled]="disable"
    (click)="_onClick()"
    (mousedown)="onMouseDown()"
    (mouseup)="onMouseUp()"
>
    <input [disabled]="disable" tabindex="-1" type="radio" [checked]="checked" />
    <label *ngIf="label">
        <span>{{ label }}</span>
    </label>
</button>
