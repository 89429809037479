<section class="rw-modal">
    <div class="rw-modal-background" #modalBackgroundElement></div>
    <div class="rw-modal-wrapper justify-content-center align-items-center" (click)="onCancel()" #modalWrapperElement>
        <div class="rw-modal user-select-none" (click)="$event.stopPropagation()">
            <div class="title">
                <span class="rw-typo-bodytext1">개인정보 보호 및 이용</span>
                <rwa-icon-ghost-button borderColor="var(--gray-60)" [sizeType]="'md'" (click)="onCancel()">
                    <ng-template iconGhostBtIdleContent>
                        <rwa-svg-close [width]="'22px'" [height]="'22px'" ></rwa-svg-close>
                    </ng-template>
                </rwa-icon-ghost-button>
            </div>

            <div class="content rw-typo-subtext1 thin-scroll-y-overlay">
                <div class="text__title_small_chap">1. 개인정보의 처리 목적</div>

                <div class="text__content text--content_margin">
                    개인정보는 다음의 목적 외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할
                    예정입니다.
                </div>

                <ol class="first circle_number">
                    <li>
                        회원가입 및 관리, 회원제 서비스 이용, 제한적 본인 확인제에 따른 본인확인, 개인식별, 부정이용
                        방지, 비인가 사용방지, 가입의사 확인, 만 14세 미만 아동 개인정보 수집 시 법정대리인 동의여부
                        확인, 추후 법정대리인 본인확인, 사고조사, 분쟁해결, 민원처리 및 고지사항 전달 등의 목적으로
                        개인정보를 처리합니다.
                    </li>
                    <li>
                        서비스 개발, 서비스 제공 및 개선, 인구 통계학적 분석, 서비스 방문 및 이용기록의 분석, 개인정보
                        및 관심에 기반한 고객간 관계의 형성, 지인 및 관심사 등에 기반한 맞춤형 서비스 제공 등 신규
                        서비스 요소의 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 처리합니다.
                    </li>
                    <li>
                        마케팅 및 프로모션, 인구통계학적 특성에 따른 광고의 게재, 서비스의 유효성 확인, 경품지급,
                        사은행사 등 센터와 회원의 편의 및 참여기회 제공, 접속빈도 파악, 센터와 회원의 서비스 이용에 대한
                        통계 등의 목적으로 개인정보를 처리합니다. 제휴사 서비스(상품) 및 이벤트 제공 및 광고, SMS 서비스
                        제공 등의 마케팅 활동, 시장조사 및 상품 개발·연구, 우편물·E-mail 등 배송, 전화상담 등에
                        이용됩니다.
                    </li>
                    <li>
                        안전한 인터넷 이용환경 구축, 보안, 프라이버시, 안전 측면에서 센터와 회원이 안심하고 이용할 수
                        있는 서비스 이용환경 구축을 위해 개인정보를 처리합니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">2. 개인정보의 처리 및 보유 기간</div>

                <ol class="first circle_number">
                    <li class="text-top-margin text--content_margin">
                        회원 가입 및 관리 목적으로 수집된 개인(신용)정보는 센터와 회원의 회원 가입일로부터 회원
                        탈퇴일까지 보유·이용됩니다. 다만, 센터의 회원이 탈퇴한 후에는 제1조의 목적과 관련된 사고조사,
                        분쟁해결, 민원처리, 법령상 의무이행만을 위하여 보유·이용됩니다.
                    </li>

                    <li>
                        <div class="li_margin text--content_margin">
                            고객의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기되나, 아래
                            각 항목에 해당하는 경우에는 명시한 기간 동안 보관할 수 있으며, 그 외 다른 목적으로 사용하지
                            않습니다.
                        </div>

                        <ul class="second">
                            <li>
                                불건전한 서비스 이용으로 서비스에 물의를 일으킨 고객의 경우 사법기관 수사의뢰를 하거나
                                다른 고객을 보호할 목적으로 1년간 해당 개인정보를 보유·이용할 수 있습니다.
                            </li>

                            <li>
                                <div class="li_margin">
                                    관계법령의 규정에 의하여 보관할 필요가 있는 경우 회사는 수집 및 이용 목적 달성
                                    후에도 관계법령에서 정한 일정 기간 동안 고객의 개인정보를 보유·이용할 수 있습니다.
                                </div>
                                <ol class="third">
                                    <li>계약 또는 청약 철회 등에 관한 기록 : 5년</li>
                                    <li>대금 결제 및 재화의 공급에 관한 기록 : 5년</li>
                                    <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                                    <li>표시, 광고에 관한 기록 : 1년</li>
                                    <li>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류 : 5년</li>
                                    <li>전자금융 거래에 관한 기록 : 5년</li>
                                    <li>서비스 방문 기록 : 3개월</li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">3. 개인정보의 제3자 제공 및 위탁</div>

                <div class="text__content text--content_margin">
                    회사는 원칙적으로 이용자 동의 없이 개인정보를 외부에 제공하지 않습니다.
                </div>

                <ol class="first circle_number">
                    <li>
                        <div class="li_margin">
                            회사는 원칙적으로 센터와 회원의 개인정보를 제1조에서 명시한 목적 범위 내에서 처리하며,
                            센터와 회원의 사전 동의 없는 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다.
                            다만, 다음과 같은 경우 센터와 회원의 개인 정보를 제3자에게 제공할 수 있습니다.
                        </div>

                        <ul class="second">
                            <li>고객이 사전에 제3자 제공에 동의한 경우</li>
                            <li>다른 법률에 특별한 규정이 있는 경우</li>
                            <li>
                                고객 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를
                                받을 수 없는 경우로서 명백히 고객 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여
                                필요하다고 인정되는 경우
                            </li>
                            <li>
                                통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는
                                형태로 개인정보를 제공하는 경우
                            </li>
                        </ul>
                    </li>

                    <li>
                        <div class="li_margin">
                            제1항에 따라 회원이 사전에 동의한 경우 센터가 제공하는 서비스의 이용을 위하여 관련된 회원의
                            정보를 필요한 범위 내에서 센터에게 제공할 수 있습니다. 이 때 회사는 회원에게 아래의
                            ‘개인정보를 제공받는 자, 제공목적, 제공하는 개인정보 항목, 제공하는 개인정보의 보유 및
                            이용기간’을 사전에 고지하고 이에 대하여 명시적, 개별적 동의를 얻습니다. 회원은 개인정보
                            제공을 거부하실 수 있으나 이 경우 센터가 제공하는 서비스 이용이 제한될 수 있습니다.
                        </div>

                        <table class="type1">
                            <tr>
                                <td>제공받는 자</td>
                                <td>제공 목적</td>
                                <td class="third_width">제공 항목</td>
                                <td>보유 및 이용 기간</td>
                            </tr>
                            <tr>
                                <td class="span_align" rowspan="3">텐엑스 서비스에 가입된 센터 또는 브랜드</td>
                                <td>가입된 센터의 회원 관리</td>
                                <td>이름, 이메일 주소, 전화번호, 성별, 생년월일</td>
                                <td class="span_align" rowspan="3">
                                    센터 계약 종료 시 또는 회원 탈퇴 시 또는 서비스 종료 시까지
                                </td>
                            </tr>
                            <tr>
                                <td>가입된 센터의 회원권, 수강 관리</td>
                                <td>회원권 구매 상세 내역, 예약 및 수강 내역</td>
                            </tr>
                            <tr>
                                <td>가입된 센터의 출결 관리</td>
                                <td>이름, 전화번호, 출결 현황</td>
                            </tr>
                        </table>

                        <ol class="second text-top-margin list-style-none">
                            <li class="text--content_margin">
                                서비스의 개인정보를 제공 받는 자는 회원이 등록된 센터 및 회원이 제3자 정보 제공에 동의한
                                센터에 한정되며, 그러한 센터의 변동에 따른 개인정보 처리방침의 수시 개정이 어려워 업체
                                리스트 열거는 생략합니다.
                            </li>
                        </ol>

                        <table class="type2">
                            <tr>
                                <td>위탁 업체(수탁자)</td>
                                <td>위탁 업무</td>
                                <td>위탁정보</td>
                                <td>근거법령</td>
                                <td>위탁정보</td>
                            </tr>
                            <tr>
                                <td>Amazon Web Services, Inc.</td>
                                <td>대량 메일 발송</td>
                                <td>이메일</td>
                                <td>-</td>
                                <td>이용 목적 달성 시까지</td>
                            </tr>
                            <tr>
                                <td>(주)써머스플랫폼 스윗트래커</td>
                                <td>SMS, LMS, 알림톡 발송</td>
                                <td>휴대폰 번호</td>
                                <td>-</td>
                                <td>이용 목적 달성 시까지</td>
                            </tr>
                            <tr>
                                <td>토스페이먼트(주)</td>
                                <td>유료서비스 이용 대금결제</td>
                                <td>신용카드 정보</td>
                                <td>전자금융거래법</td>
                                <td>5년</td>
                            </tr>
                        </table>
                    </li>

                    <div class="text-top-margin">
                        <div class="text--content_margin_small">
                            또한 위의 수탁업체 외에 서비스 이용 및 통계 분석을 목적으로 로그 분석툴인 Google Analytics,
                            Amplitude 도구를 사용하고 있습니다. 외부 분석툴을 통한 로그 분석을 중단하고 싶으신 경우,
                            아래 안내 페이지를 통해 설정을 해제하실 수 있습니다.
                        </div>
                        <ul class="second">
                            <li><span class="underline">Google Analytics 설정 해제 안내</span></li>
                            <li><span class="underline">Amplitude 설정 해제 안내</span></li>
                        </ul>
                    </div>

                    <li class="text-top-margin">
                        영업의 전부 또는 일부를 양도하거나, 합병/상속 등으로 서비스 제공자의 권리, 의무를 이전 승계하는
                        경우 개인정보 보호 관련 고객의 권리를 보장하기 위하여 반드시 그 사실을 센터와 회원에게
                        통지합니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">4. 고객의 권리, 의무 및 행사 방법</div>

                <ol class="first circle_number">
                    <li>센터와 회원은 본인 및 14세 미만 아동(법정대리인만 해당)의 개인정보를 조회할 수 있습니다.</li>
                    <li>
                        개인정보를 조회한 센터와 회원은 사실과 다르거나 확인할 수 없는 개인정보에 대하여 모바일 웹,
                        전화, 서면 등을 통하여 고객센터에 정정 또는 삭제를 요구할 수 있습니다. 다만, 다른 법령에서 그
                        개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
                    </li>
                    <li>
                        <div class="li_margin text--content_margin">
                            센터와 회원은 회사에 대하여 자신의 개인정보 처리의 정지를 요구할 수 있습니다. 다만, 다음
                            각호의 어느 하나에 해당하는 경우에는 회사는 해당 사유를 센터와 회원에게 알리고, 처리 정지
                            요구를 거절할 수 있습니다.
                        </div>
                        <ol class="second">
                            <li>법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</li>
                            <li>
                                다른 사람의 생명, 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게
                                침해할 우려가 있는 경우
                            </li>
                            <li>
                                개인정보를 처리하지 아니하면 고객과 약정한 서비스를 제공하지 못하는 등 계약의 이행이
                                곤란한 경우로서 고객이 그 계약의 해지의사를 명확하게 밝히지 아니한 경우
                            </li>
                        </ol>
                    </li>
                    <li>
                        센터와 회원은 개인정보를 보호 받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을
                        의무도 가지고 있습니다. 고객은 아이디(ID), 비밀번호를 포함한 개인정보가 유출되지 않도록
                        조심하여야 하며, 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해야 합니다. 만약 이 같은
                        책임을 다하지 못하고 타인의 정보 및 타인의 존엄성을 훼손할 경우에는 「정보통신망 이용촉진 및
                        정보보호 등에 관한 법률」 등에 의해 처벌 받을 수 있습니다.
                    </li>
                    <li>
                        회사는 센터와 회원의 요청에 의해 해지 또는 삭제된 개인정보는 “개인정보의 처리 및 보유 기간”에
                        명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
                    </li>
                    <li>
                        회사는 타인의 개인정보를 도용하여 회원가입 등을 하였음을 알게 된 때 지체 없이 해당 아이디에 대한
                        서비스 이용정지 또는 회원탈퇴 등 필요한 조치를 취합니다. 또한 자신의 개인정보 도용을 인지한
                        센터와 회원이 해당 아이디에 대한 서비스 이용정지 또는 회원탈퇴를 요구하는 경우에도 회사는 즉시
                        조치를 취합니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">5. 처리하는 개인정보의 항목</div>

                <div class="text__content text--content_margin">
                    서비스 이용을 위해 필요한 최소한의 개인정보를 처리합니다.
                </div>

                <ol class="first circle_number">
                    <li>
                        <div class="li_margin text--content_margin">
                            회사는 상담, 서비스 신청 등 서비스 제공 및 계약 이행을 위해 센터로부터 아래와 같은
                            개인정보를 수집할 수 있습니다.
                        </div>
                        <ul class="second">
                            <li>
                                센터명, 아이디(이메일 주소), 비밀번호, 담당자 이름 및 휴대전화번호, 연계정보(CI), 중복
                                확인정보(DI)
                            </li>
                        </ul>
                    </li>

                    <li>
                        <div class="li_margin text--content_margin">
                            회사는 센터가 유료 서비스를 이용하고자 하는 경우 결제 시점에 아래와 같이 결제에 필요한
                            정보를 수집할 수 있습니다.
                        </div>
                        <ul class="second">
                            <li>
                                결제 수단 소유자 정보 (생년월일 6자리 또는 사업자등록번호), 신용카드 정보 (카드번호,
                                만료일, 카드 비밀번호 앞 2자리)
                            </li>
                        </ul>
                    </li>

                    <li>
                        <div class="li_margin text--content_margin">
                            회사는 회원으로부터 아래와 같은 개인 정보를 수집할 수 있습니다.
                        </div>
                        <ul class="second">
                            <li>
                                프로필 이미지, 아이디(이메일 주소), 비밀번호, 이름, 주민등록번호 앞 7자리, 성별,
                                휴대전화번호, 센터명, 연계정보(CI), 중복 확인정보(DI)
                            </li>
                        </ul>
                    </li>

                    <li>
                        <div class="li_margin text--content_margin">
                            회사는 고객이 마케팅 프로모션에 참여하는 경우 아래의 정보를 수집할 수 있습니다.
                        </div>
                        <ul class="second">
                            <li>이름, 이메일 주소, 휴대전화번호, 주소, 주민등록번호 앞 7자리</li>
                        </ul>
                    </li>

                    <li>
                        <div class="li_margin text--content_margin">
                            회사의 서비스 이용 과정에서 다음과 같은 정보가 생성되어 수집될 수 있습니다.
                        </div>
                        <ul class="second">
                            <li>
                                PC웹, 모바일 웹/앱 이용 과정에서 단말기정보 (OS, 화면사이즈, 디바이스 아이디), IP주소,
                                쿠키, 방문일시, 부정 이용기록, 서비스 이용기록
                            </li>
                        </ul>
                    </li>

                    <li>
                        회사는 앞에서 언급된 정보 외에 온라인 및 오프라인 데이터 제공업체 등 다른 소스로부터 확보된
                        정보도 추가 수집할 수 있습니다. 이러한 추가 정보에는 인구통계 데이터, 이해관계 관련 데이터 및
                        인터넷 브라우징 패턴 등이 포함될 수 있습니다.
                    </li>

                    <li>
                        <div class="li_margin text--content_margin">개인정보를 수집하는 방법은 아래와 같습니다.</div>
                        <ul class="second">
                            <li>
                                회원가입 및 서비스 이용 과정에서 고객이 개인정보 수집에 대해 동의를 하고 직접 정보를
                                입력하는 경우
                            </li>
                            <li>제휴 서비스 또는 단체 등으로부터 개인정보를 제공받은 경우</li>
                            <li>고객센터를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등</li>
                            <li>온오프라인에서 진행되는 이벤트/행사 등 참여</li>
                        </ul>
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">6. 개인정보의 파기</div>

                <div class="text__content text--content_margin">
                    개인정보는 수집 및 이용 목적이 달성되면 지체 없이 파기하며, 절차 및 방법은 아래와 같습니다.
                </div>

                <ol class="first circle_number">
                    <li>
                        <div class="li_margin text--content_margin">파기 절차</div>
                        <ul class="second">
                            <li>
                                고객이 서비스 가입 등을 위해 기재한 개인정보는 이용계약 해지 등 이용 목적이 달성된 후
                                내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정 기간 저장하는 자료를
                                제외하고는 지체 없이 삭제하거나 파기합니다.
                            </li>
                            <li>
                                1년 동안 이용 기록이 없는 장기 미이용 센터와 회원의 개인정보를 보호하기 위하여
                                개인정보를 파기 처리합니다.
                            </li>
                        </ul>
                    </li>

                    <li>
                        <div class="li_margin text--content_margin">파기 방법</div>
                        <ul class="second">
                            <li>
                                전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고, 그 밖에 기록물,
                                인쇄물, 서면 등의 경우 분쇄하거나 소각하여 파기합니다.
                            </li>
                        </ul>
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">7. 개인정보의 안전성 확보 조치</div>

                <div class="text__content text--content_margin">
                    회사는 개인정보보호법 제20조에 따라 다음 각호와 같이 안전성 확보에 필요한 기술적/관리적 및 물리적
                    조치를 하고 있습니다.
                </div>

                <ol class="first circle_number">
                    <li>
                        <div class="li_margin text--content_margin">관리적 조치</div>
                        <ul class="second">
                            <li>내부 관리 계획 수립 및 시행</li>
                            <li>
                                개인정보 취급 직원의 최소화 및 교육 : 개인정보를 취급하는 직원을 지정하고 담당자에
                                한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.
                            </li>
                        </ul>
                    </li>

                    <li>
                        <div class="li_margin text--content_margin">기술적 조치</div>
                        <ul class="second">
                            <li>
                                개인정보처리시스템 접근권한 관리 : 개인정보를 처리하는 데이터베이스 시스템에 대한
                                접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를
                                하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단접근을 통제하고 있습니다.
                            </li>
                            <li>
                                개인정보의 암호화 : 센터와 회원의 개인정보인 비밀번호는 암호화 되어 저장 및 관리되고
                                있어 본인만이 알수 있으며, 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금
                                기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.
                            </li>
                            <li>
                                해킹 등에 대비한 기술적 대책 : 당행은 해킹이나 바이러스 등에 의한 개인정보 유출 및
                                훼손을 막기 위하여 보안 프로그램을 설치하여 주기적인 갱신, 점검을 하며 외부로부터 접근이
                                통제된 구역에 시스템을 설치하는 등 기술적, 물리적으로 감시 및 차단하고 있습니다.
                            </li>
                        </ul>
                    </li>

                    <li>물리적 조치 : 전산실, 자료 보관함 등의 접근 통제</li>
                </ol>

                <div class="text__title_small_chap p-top-margin">8. 쿠키 및 기타 기술</div>

                <ol class="first circle_number">
                    <li class="text-top-margin text--content_margin">
                        회사의 웹 사이트, 온라인 서비스, 이메일 메시지 및 광고에서는 ‘쿠키’ 및 기타 기술을 사용할 수
                        있습니다. 쿠키란 웹 서버가 웹 브라우저에 보내어 저장했다가 서버의 부가적인 요청이 있을 때 다시
                        서버로 보내주는 문자열 정보0(텍스트 파일)로 센터와 회원의 컴퓨터 하드디스크에 저장되며 쿠키에는
                        사용한 웹사이트의 정보 및 고객의 개인정보가 담길 수 있습니다.
                    </li>
                    <li class="text-top-margin text--content_margin">
                        회사에서 운영하는 서비스는 인터넷을 통하여 센터와 회원의 정보를 저장하고 수시로 찾아내는 쿠키를
                        설치, 운용하고 있습니다. 센터와 회원이 웹사이트에 접속을 하면 브라우저에 있는 쿠키의 내용을
                        읽고, 추가 정보를 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.
                    </li>
                    <li class="text-top-margin text--content_margin">
                        센터와 회원은 쿠키 설치에 대한 선택권을 가지고 있으며 센터와 회원은 웹 브라우저에서 옵션을
                        설정함으로서 모든 쿠키를 허용하거나, 또는 쿠키가 저장될 때마다 확인을 거치거나, 혹은 모든 쿠키의
                        저장을 거부할 수도 있습니다. 다만, 센터와 회원이 쿠키 설치를 거부했을 경우 서비스 제공에
                        어려움이 발생할 수도 있습니다.
                    </li>
                    <li>
                        <div class="li_margin text--content_margin">
                            서비스 쿠키를 통해 수집하는 정보는 센터와 회원의 고유번호에 한하며, 그 외의 다른 정보는
                            수집하지 않습니다. 서비스가 쿠키를 통해 수집한 고객의 고유번호는 다음의 목적을 위해 사용될
                            수 있습니다.
                        </div>

                        <ul class="second">
                            <li>
                                고객과 비고객의 웹/앱 접속 빈도 또는 머문시간 등을 분석하여 고객의 취향과 관심분야를
                                파악하여 타겟 마케팅에 활용
                            </li>
                            <li>서비스 이용 내역을 추적하여 분석을 통한 추후 서비스 개편 등의 척도로 활용</li>
                            <li>유료 서비스 이용 시 이용기간 안내 등</li>
                        </ul>
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">9. 권익침해 구제 방법</div>

                <div class="text__content text--content_margin">
                    고객은 개인정보 침해로 인한 신고나 상담이 필요하신 경우 아래 기관에 문의하시기 바랍니다.
                </div>

                <ol class="first circle_number">
                    <li>
                        개인정보분쟁조정위원회 (<span (click)="openKoplco()" class="underline">koplco.go.kr/</span>)
                        1833-6972
                    </li>
                    <li>
                        개인정보침해신고센터 (<span (click)="openPrivacy()" class="underline">privacy.klsa.or.kr/</span
                        >) 118
                    </li>
                    <li>
                        대검찰청 첨단범죄수사과 (<span (click)="openSpo()" class="underline">spo.go.kr/</span>)
                        02-3480-2000
                    </li>
                    <li>
                        경찰청 사이버안전국 (<span (click)="openCyberbureau()" class="underline"
                            >cyberbureau.police.go.kr/</span
                        >) 182
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">10. 개인정보 보호 책임자</div>

                <div class="text__content text--content_margin">
                    회사는 고객의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 아래와 같이 개인정보 보호 책임자를
                    지정하고 있습니다.
                </div>

                <ul class="first">
                    <li class="text--content_margin">책임자 : 강신호</li>
                    <li>문의 : hohde3@gmail.com</li>
                </ul>

                <div class="text__title_small_chap p-top-margin">11. 개인정보 처리방침의 변경</div>

                <div class="text__content text--content_margin">
                    회사는 개인정보 처리방침을 변경하는 경우에는 변경 및 시행의 시기, 변경된 내용을 지속적으로 공개하며,
                    변경된 내용은 고객이 쉽게 확인할 수 있도록 변경 전후를 비교하여 공개합니다.
                </div>

                <div class="text__content text--content_margin p-bottom-margin">시행일자 : 2019년 7월 30일</div>
            </div>
        </div>
    </div>
</section>
