<div class="rw-modal-background" #modalBackgroundElement></div>
<div class="rw-modal-wrapper justify-content-center align-items-center" #modalWrapperElement>
    <div class="rw-modal user-select-none">
        <div class="text d-flex flex-column">
            <span>센터 입장이 처음이시네요. 🙌</span>
            <span>딱 한 번! 전화번호로 본인을 인증해 주세요.</span>
        </div>
        <div class="sub-text d-flex flex-column">
            <span>인증번호는 카카오톡으로 전송되며,</span>
            <span>카카오톡 미설치 또는 알림톡 수신 차단 시에는</span>
            <span>문자 메시지로 전송됩니다.</span>
        </div>
        <div class="l-phone-field">
            <rwa-text-field
                #phoneNumberRef
                [width]="sendVerifCodeStatus != 'pending' ? '245px' : '213px'"
                class="phone-field"
                [inputLimit]="13"
                label="전화번호"
                placeholder="-없이 숫자만 입력"
                (keydown)="restrictToNumber($event)"
                (keyup)="onKeyup($event, 'phoneNumber')"
                [formControl]="phoneNumber"
            ></rwa-text-field>

            <rwa-button
                [height]="'42px'"
                [padding]="'7.5px 16px 5.5px'"
                [bgColor]="'var(--gray-100)'"
                [hoverBgColor]="'var(--font-color)'"
                (onClick)="sendVerificationCodeSMSChange()"
                [status]="sendVerifCodeStatus"
                [disable]="!phoneNumber.valid"
            >
                <ng-template btIdleContent>
                    <span class="phone-button">인증번호 받기</span>
                </ng-template>
                <ng-template btPendingContent>
                    <span class="phone-button">인증번호 받기</span>
                </ng-template>
            </rwa-button>
        </div>

        <rwa-text-field
            class="code-field"
            [type]="verifTextType"
            [timeLimit]="timeLeft"
            [(status)]="phoneNumberStatus"
            [statusText]="phoneNumberError"
            [width]="'380px'"
            [inputLimit]="4"
            [formControl]="verificationCode"
            #verificationCodeRef
            label="인증번호"
            placeholder="인증번호"
            (keydown)="restrictToNumber($event)"
            (keyup)="onKeyup($event, 'verificationCode')"
            (keyup.enter)="formCheck() && next()"
        ></rwa-text-field>

        <div class="l-button">
            <rwa-button
                [padding]="'9px 25px 7px'"
                [height]="'45px'"
                [loadingName]="'info-next-button'"
                [disable]="!formCheck()"
                (onClick)="formCheck() && next()"
                [status]="finishVerificationStatus"
            >
                <ng-template btIdleContent><span class="next-text">인증 완료</span></ng-template>
                <ng-template btPendingContent><span class="next-text">인증 완료</span></ng-template>
            </rwa-button>
        </div>
    </div>
</div>
