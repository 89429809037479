<div class="rw-modal-background" #modalBackgroundElement></div>
<div
    class="rw-modal-wrapper justify-content-center align-items-center"
    (mouseup)="!isMouseModalDown && !blockClickOutside && onClose()"
    (mousedown)="resetMouseModalDown()"
    #modalWrapperElement
>
    <div class="rw-modal user-select-none" (mousedown)="onMouseModalDown(); $event.stopPropagation()">
        <div class="step-one" [class.show-step]="step == 'one'" [class.hide-step]="step != 'one'">
            <div class="header">
                <span>비밀번호 변경을 위해</span>
                <span>기존 비밀번호를 입력해 주세요.</span>
            </div>
            <div class="body">
                <rwa-text-field
                    [label]="'기존 비밀번호'"
                    placeholder="비밀번호"
                    width="380px"
                    [inputLimit]="15"
                    [textFieldType]="'password'"
                    [formControl]="curPassword"
                ></rwa-text-field>
                <div class="l-button">
                    <rwa-button
                        [padding]="'9px 25px 7px'"
                        [height]="'45px'"
                        [fontColor]="'var(--gray-90)'"
                        [bgColor]="'var(--white)'"
                        [hoverBgColor]="'var(--gray-30)'"
                        [loadingColor]="'var(--font-color)'"
                        (onClick)="onClose()"
                        class="cancel-button"
                    >
                        <ng-template btIdleContent><span class="cancel-text">취소</span></ng-template>
                        <ng-template btPendingContent><span class="cancel-text">취소</span></ng-template>
                    </rwa-button>
                    <rwa-button
                        [disable]="curPassword.invalid"
                        class="check-cur-password-button"
                        padding="9px 25px 7px"
                        (onClick)="matchCurPassword()"
                        [loadingName]="'match-password'"
                        [status]="matchCurPasswordLoading"
                    >
                        <ng-template btIdleContent><span class="save-button-text">다음</span></ng-template>
                        <ng-template btPendingContent><span class="save-button-text">다음</span></ng-template>
                    </rwa-button>
                </div>
            </div>
        </div>

        <div class="step-two" [class.show-step]="step == 'two'" [class.hide-step]="step != 'two'">
            <div class="header">
                <span>새로운 비밀번호를 입력해 주세요.</span>
            </div>
            <div class="body">
                <div class="l-password">
                    <rwa-text-field
                        [label]="'새로운 비밀번호'"
                        [hint]="'영어, 숫자, 특수문자 조합 8~15자 이내'"
                        placeholder="비밀번호"
                        width="380px"
                        [inputLimit]="15"
                        [textFieldType]="'password'"
                        [formControl]="newPassword"
                        [(status)]="newPasswordStatus"
                        [statusText]="newPasswordError"
                    ></rwa-text-field>
                    <rwa-text-field
                        [label]="'비밀번호 확인'"
                        placeholder="비밀번호를 한 번 더 입력해 주세요."
                        width="380px"
                        [inputLimit]="15"
                        [textFieldType]="'password'"
                        [formControl]="confirmNewPassword"
                        [(status)]="confirmNewPasswordStatus"
                        [statusText]="confirmNewPasswordError"
                    ></rwa-text-field>
                </div>

                <div class="l-button">
                    <rwa-button
                        [padding]="'9px 25px 7px'"
                        [height]="'45px'"
                        [fontColor]="'var(--gray-90)'"
                        [bgColor]="'var(--white)'"
                        [hoverBgColor]="'var(--gray-30)'"
                        [loadingColor]="'var(--font-color)'"
                        (onClick)="onClose()"
                        class="cancel-button"
                    >
                        <ng-template btIdleContent><span class="cancel-text">취소</span></ng-template>
                        <ng-template btPendingContent><span class="cancel-text">취소</span></ng-template>
                    </rwa-button>
                    <rwa-button
                        [disable]="confirmNewPassword.invalid || newPassword.invalid"
                        class="check-cur-password-button"
                        padding="9px 25px 7px"
                        (onClick)="changePassword()"
                        [loadingName]="'match-password'"
                        [status]="matchNewPasswordLoading"
                    >
                        <ng-template btIdleContent><span class="save-button-text">저장하기</span></ng-template>
                        <ng-template btPendingContent><span class="save-button-text">저장하기</span></ng-template>
                    </rwa-button>
                </div>
            </div>
        </div>
    </div>
</div>
