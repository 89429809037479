<div class="rw-modal-background" #modalBackgroundElement></div>
<div
    class="rw-modal-wrapper justify-content-center align-items-center"
    (mouseup)="!isMouseModalDown && !blockClickOutside && onCancel()"
    (mousedown)="resetMouseModalDown()"
    #modalWrapperElement
>
    <div class="rw-modal user-select-none" (mousedown)="onMouseModalDown(); $event.stopPropagation()">
        <div class="text d-flex">카드 등록이 완료되었습니다!</div>
        <div class="sub-text d-flex">등록한 카드의 정보는 [결제 수단 관리]에서 변경하실 수 있어요.</div>
        <div class="l-card-info">
            <div class="card-title">등록한 카드</div>
            <div class="card-name">
                {{ cardData?.card_name + ' ' }} ({{ cardData?.card_number | wordSlice : 0 : 4 }})
            </div>
        </div>
        <div class="button-group">
            <rwa-button width="80px" height="45px" (click)="onConfirm(); $event.stopPropagation()">
                <ng-template btIdleContent>
                    <span class="button-text">확인</span>
                </ng-template>
            </rwa-button>
        </div>
    </div>
</div>
