<div class="l-text-field">
    <div class="label" *ngIf="labelVisible">
        {{ label }} <span class="red">*</span>
        <div
            *ngIf="advice"
            class="info-icon"
            rwTooltip
            rwTooltipPlacement="top"
            [showTail]="true"
            [rwTooltipTitle]="advice"
        ></div>
    </div>
    <div
        class="l-input"
        (mouseover)="isMouseOn = true"
        (mouseleave)="isMouseOn = false"
        (mousedown)="isMouseDown = true"
        (mouseup)="isMouseDown = false"
    >
        <input
            #input
            type="text"
            (keydown)="restrictToNumber($event)"
            [style.width]="width"
            [style.height]="height"
            [class.in-type]="textField.value.length > 0 && !disable"
            [class.warning]="status == 'warning'"
            [class.error]="status == 'error'"
            [class.still-focus]="isMouseDown && isMouseOn"
            [placeholder]="placeholder"
            [formControl]="textField"
            (focus)="onFocus()"
            (focusout)="onFocusOut()"
            (rwClickOutside)="isMouseOn = false; isMouseDown = false"
        />
        <div class="l-input-tag user-select-none" (click)="input.focus()">
            <span class="currency">원</span>
            <div class="input-tag-normal">
                <rwa-svg-close-fill
                    [margin]="'0 0 0 0'"
                    *ngIf="!disable && textField.value.length > 0 && isFocused"
                    (click)="resetTextField()"
                ></rwa-svg-close-fill>
                <rwa-svg-warning
                    *ngIf="status == 'error' || status == 'warning'"
                    [color]="status == 'warning' ? 'var(--state-warning-100)' : 'var(--state-error-100)'"
                ></rwa-svg-warning>
            </div>
        </div>
        <div *ngIf="hint" class="hint"><span class="info-icon"></span>{{ hint }}</div>
        <div
            *ngIf="status == 'warning' || status == 'error'"
            class="status"
            [class.warning]="status == 'warning'"
            [class.error]="status == 'error'"
        >
            {{ statusText }}
        </div>
    </div>
</div>
