<button
    #l_button
    [disabled]="disabled"
    class="l-sale-filter"
    [class.value-exist]="filterValueExist"
    [class.open]="openDropdown"
    (mousedown)="onMouseDown()"
    (mouseup)="onMouseUp()"
    (focusin)="onFocus()"
    (focusout)="onFocusOut()"
    (click)="_onClick()"
>
    <span *ngIf="filterType != 'date'" class="type-name">{{ typeName }}</span>
    <ng-container *ngIf="!filterValueExist">
        <rwa-icon-button
            [style.margin-left]="'6px'"
            [disabled]="disabled"
            [borderRadius]="'2px'"
            [width]="'18px'"
            [height]="'18px'"
        >
            <ng-template iconBtIdleContent>
                <rwa-svg-caret-down [width]="'18px'" [height]="'18px'" [margin]="'0'"></rwa-svg-caret-down>
            </ng-template>
        </rwa-icon-button>
    </ng-container>
    <ng-container *ngIf="filterValueExist">
        <div *ngIf="filterType == 'paymentType'" class="d-flex align-items-center">
            <div class="filter-value-divider"></div>
            <span class="filter-value-text">{{ selectedPaymentType }}</span>
            <rwa-icon-button
                [disabled]="disabled"
                [borderRadius]="'2px'"
                [width]="'24px'"
                [height]="'24px'"
                (click)="resetData('paymentType'); openDropdown = false; $event.stopPropagation()"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-close-fill [width]="'24px'" [height]="'24px'"></rwa-svg-close-fill>
                </ng-template>
            </rwa-icon-button>
        </div>
        <div *ngIf="filterType == 'member'" class="d-flex align-items-center">
            <div class="filter-value-divider"></div>
            <span class="filter-value-text">{{ curMember }}</span>
            <rwa-icon-button
                [disabled]="disabled"
                [borderRadius]="'2px'"
                [width]="'24px'"
                [height]="'24px'"
                (click)="resetData('member'); openDropdown = false; $event.stopPropagation()"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-close-fill [width]="'24px'" [height]="'24px'"></rwa-svg-close-fill>
                </ng-template>
            </rwa-icon-button>
        </div>
        <div *ngIf="filterType == 'productType'" class="d-flex align-items-center">
            <div class="filter-value-divider"></div>
            <span class="filter-value-text">{{ selectedProductType }}</span>
            <rwa-icon-button
                [disabled]="disabled"
                [borderRadius]="'2px'"
                [width]="'24px'"
                [height]="'24px'"
                (click)="resetData('productType'); openDropdown = false; $event.stopPropagation()"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-close-fill [width]="'24px'" [height]="'24px'"></rwa-svg-close-fill>
                </ng-template>
            </rwa-icon-button>
        </div>
        <div *ngIf="filterType == 'productName'" class="d-flex align-items-center">
            <div class="filter-value-divider"></div>
            <span class="filter-value-text">{{ curProductName }}</span>
            <rwa-icon-button
                [disabled]="disabled"
                [borderRadius]="'2px'"
                [width]="'24px'"
                [height]="'24px'"
                (click)="resetData('productName'); openDropdown = false; $event.stopPropagation()"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-close-fill [width]="'24px'" [height]="'24px'"></rwa-svg-close-fill>
                </ng-template>
            </rwa-icon-button>
        </div>
        <div *ngIf="filterType == 'personInCharge'" class="d-flex align-items-center">
            <div class="filter-value-divider"></div>
            <span class="filter-value-text">{{ curPersonInCharge }}</span>
            <rwa-icon-button
                [disabled]="disabled"
                [borderRadius]="'2px'"
                [width]="'24px'"
                [height]="'24px'"
                (click)="resetData('personInCharge'); openDropdown = false; $event.stopPropagation()"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-close-fill [width]="'24px'" [height]="'24px'"></rwa-svg-close-fill>
                </ng-template>
            </rwa-icon-button>
        </div>
        <div *ngIf="filterType == 'date'" class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center" [style.margin-right]="'12px'">
                <div class="calendar-icon" [style.margin-right]="'5px'"></div>
                <span class="date-text">{{ dateText }}</span>
            </div>
            <rwa-icon-button
                [style.margin-left]="'6px'"
                [disabled]="disabled"
                [borderRadius]="'2px'"
                [width]="'18px'"
                [height]="'18px'"
            >
                <ng-template iconBtIdleContent>
                    <rwa-svg-caret-down [width]="'18px'" [height]="'18px'" [margin]="'0'"></rwa-svg-caret-down>
                </ng-template>
            </rwa-icon-button>
        </div>
    </ng-container>
</button>

<div class="rw-modal-background" #modalBackgroundElement></div>
<div class="rw-modal-wrapper justify-content-center align-items-center" #modalWrapperElement>
    <div class="rw-modal user-select-none">
        <div class="modal-header">
            <div class="d-flex align-items-center">
                <span class="modal-header__text">필터</span>
                <div class="modal-header__divider"></div>
                <span class="modal-header__text">{{ typeName }}</span>
            </div>
            <rwa-icon-ghost-button [sizeType]="'md'" (onClick)="closeDropDown()">
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-close [width]="'22px'" [height]="'22px'"></rwa-svg-close>
                </ng-template>
            </rwa-icon-ghost-button>
        </div>
        <ng-container *ngIf="filterType == 'paymentType'">
            <div
                class="payment-type-dropdown"
                [class.open]="openDropdown && filterType == 'paymentType'"
                (click)="$event.stopPropagation()"
            >
                <div class="l-checkbox">
                    <rwa-checkbox
                        [checked]="curPaymentType.payment_type_payment"
                        (onClick)="curPaymentType.payment_type_payment = $event"
                        [label]="'결제'"
                    ></rwa-checkbox>
                    <rwa-checkbox
                        [checked]="curPaymentType.payment_type_refund"
                        (onClick)="curPaymentType.payment_type_refund = $event"
                        [label]="'환불'"
                    ></rwa-checkbox>
                    <rwa-checkbox
                        [checked]="curPaymentType.payment_type_transfer"
                        (onClick)="curPaymentType.payment_type_transfer = $event"
                        [label]="'양도'"
                    ></rwa-checkbox>
                </div>
                <div class="d-flex justify-content-end">
                    <rwa-button
                        [width]="'109px'"
                        [height]="'45px'"
                        (onClick)="onPaymentTypeSave(); closeDropDown()"
                        [disable]="
                            !curPaymentType.payment_type_payment &&
                            !curPaymentType.payment_type_refund &&
                            !curPaymentType.payment_type_transfer
                        "
                    >
                        <ng-template btIdleContent><span class="save-bt-text">저장하기</span></ng-template>
                    </rwa-button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="filterType == 'member'">
            <div
                class="member-dropdown"
                [class.open]="openDropdown && filterType == 'member'"
                (click)="$event.stopPropagation()"
            >
                <rwa-text-field
                    #rwa_text_field
                    [label]="'회원명 또는 전화번호 검색'"
                    [labelVisible]="true"
                    [placeholder]="'회원명'"
                    [width]="'320px'"
                    [textFieldType]="'search'"
                    [(ngModel)]="curMember"
                    (click)="$event.stopPropagation()"
                ></rwa-text-field>
                <rwa-button
                    [width]="'109px'"
                    [height]="'45px'"
                    (onClick)="onMemberSave(); closeDropDown()"
                    [disable]="!curMember"
                >
                    <ng-template btIdleContent><span class="save-bt-text">검색하기</span></ng-template>
                </rwa-button>
            </div>
        </ng-container>
        <ng-container *ngIf="filterType == 'productType'">
            <div
                class="product-type-dropdown"
                [class.open]="openDropdown && filterType == 'productType'"
                (click)="$event.stopPropagation()"
            >
                <div class="l-checkbox">
                    <rwa-checkbox
                        [checked]="curProductType.user_membership"
                        (onClick)="curProductType.user_membership = $event"
                        [label]="'회원권'"
                    ></rwa-checkbox>
                    <rwa-checkbox
                        [checked]="curProductType.user_locker"
                        (onClick)="curProductType.user_locker = $event"
                        [label]="'락커'"
                    ></rwa-checkbox>
                    <rwa-checkbox
                        [checked]="curProductType.user_sportswear"
                        (onClick)="curProductType.user_sportswear = $event"
                        [label]="'운동복'"
                    ></rwa-checkbox>
                </div>
                <div class="d-flex justify-content-end" (click)="$event.stopPropagation()">
                    <rwa-button
                        [width]="'109px'"
                        [height]="'45px'"
                        (onClick)="onProductTypeSave(); closeDropDown()"
                        [disable]="
                            !curProductType.user_membership &&
                            !curProductType.user_locker &&
                            !curProductType.user_sportswear
                        "
                    >
                        <ng-template btIdleContent><span class="save-bt-text">저장하기</span></ng-template>
                    </rwa-button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="filterType == 'productName'">
            <div
                class="product-name-dropdown"
                [class.open]="openDropdown && filterType == 'productName'"
                (click)="$event.stopPropagation()"
            >
                <rwa-text-field
                    #rwa_text_field
                    [label]="'상품명 검색'"
                    [labelVisible]="true"
                    [placeholder]="'상품명'"
                    [width]="'320px'"
                    [textFieldType]="'search'"
                    [(ngModel)]="curProductName"
                ></rwa-text-field>
                <rwa-button
                    [width]="'109px'"
                    [height]="'45px'"
                    (onClick)="onProductNameSave(); closeDropDown()"
                    [disable]="!curProductName"
                >
                    <ng-template btIdleContent><span class="save-bt-text">검색하기</span></ng-template>
                </rwa-button>
            </div>
        </ng-container>
        <ng-container *ngIf="filterType == 'personInCharge'">
            <div
                class="person-in-charge-dropdown"
                [class.open]="openDropdown && filterType == 'personInCharge'"
                (click)="$event.stopPropagation()"
            >
                <rwa-text-field
                    #rwa_text_field
                    [label]="'담당자명 또는 전화번호 검색'"
                    [labelVisible]="true"
                    [placeholder]="'담당자명'"
                    [width]="'320px'"
                    [textFieldType]="'search'"
                    [(ngModel)]="curPersonInCharge"
                ></rwa-text-field>
                <rwa-button
                    [width]="'109px'"
                    [height]="'45px'"
                    (onClick)="onPersonInChargeSave(); closeDropDown()"
                    [disable]="!curPersonInCharge"
                >
                    <ng-template btIdleContent><span class="save-bt-text">검색하기</span></ng-template>
                </rwa-button>
            </div>
        </ng-container>
        <ng-container *ngIf="filterType == 'date'">
            <div
                class="date-dropdown"
                [class.open]="openDropdown && filterType == 'date'"
                (click)="$event.stopPropagation()"
            >
                <rwm-dropdown-datepicker
                    [mode]="'child'"
                    [(date)]="curDate"
                    (onDateSave)="onDateSave($event); closeDropDown()"
                ></rwm-dropdown-datepicker>
            </div>
        </ng-container>
    </div>
</div>
