<div class="l-profile-photo">
    <div class="profile-photo" [class.empty]="!!!pictureSrc">
        <img
            class="default-image"
            *ngIf="!!!pictureSrc"
            [src]="'/assets/icons/etc/muscle.svg'"
            [style.width]="'140px'"
            [style.height]="'140px'"
            alt="muscle.svg"
        />
        <img class="center-image" *ngIf="!!pictureSrc" [src]="pictureSrc" loading="lazy" alt="user-picture.png" />
        <input
            type="file"
            accept="image/*"
            #userProfile
            (change)="setPhoto(userProfile)"
            (click)="onPhotoClicked($event)"
            hidden
        />
    </div>
    <rwa-icon-ghost-button
        class="add-picture"
        [class.hide-icon]="!!pictureSrc"
        [sizeType]="'md'"
        (onClick)="userProfile.click()"
        rwTooltip
        rwTooltipPlacement="bottom"
        rwTooltipTitle="센터 사진 등록하기"
    >
        <ng-template iconGhostBtIdleContent>
            <rwa-svg-plus [color]="'var(--gray-90)'" [width]="'22px'" [height]="'22px'"></rwa-svg-plus>
        </ng-template>
    </rwa-icon-ghost-button>
    <rwa-icon-ghost-button
        class="edit-picture"
        [class.hide-icon]="!!!pictureSrc"
        [sizeType]="'md'"
        (onClick)="userProfile.click()"
        rwTooltip
        rwTooltipPlacement="bottom"
        rwTooltipTitle="센터 사진 변경하기"
    >
        <ng-template iconGhostBtIdleContent>
            <rwa-svg-pencil [width]="'22px'" [height]="'22px'"></rwa-svg-pencil>
        </ng-template>
    </rwa-icon-ghost-button>
    <rwa-xs-icon-button
        *ngIf="!!pictureSrc"
        class="remove-picture"
        [type]="'fill'"
        width="27px"
        height="27px"
        borderRadius="12px"
        (onClick)="pictureRemove.emit(pictureSrc)"
    >
        <rwa-svg-close color="var(--white)" [width]="'15px'" [height]="'15px'"></rwa-svg-close>
    </rwa-xs-icon-button>
</div>
