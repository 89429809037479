<ng-scrollbar [track]="'vertical'" class="rw-scroll">
    <div scrollViewport>
        <div class="l-payment-management">
            <div class="l-pm-button">
                <rwa-ghost-button
                    class="purchase-membership-button"
                    height="42px"
                    padding="7.5px 15px 5.5px 11px"
                    [borderColor]="'var(--red-100)'"
                    [hoverBorderColor]="'var(--red-100)'"
                    [fontColor]="'var(--red-100)'"
                    [hoverBgColor]="'var(--red-5)'"
                    (onClick)="goPayment()"
                >
                    <ng-template gbtIdleContent>
                        <rwa-svg-coin [color]="'var(--red-100)'"></rwa-svg-coin>
                        <span class="text">이용권 구매</span>
                    </ng-template>
                </rwa-ghost-button>
                <rwa-ghost-button
                    class="payment-management-button"
                    height="42px"
                    padding="7.5px 15px 5.5px"
                    (onClick)="setPaymentMethodModalVisible(true)"
                >
                    <ng-template gbtIdleContent><span class="text">결제 수단 관리</span></ng-template>
                </rwa-ghost-button>
            </div>

            <div class="l-pm-items">
                <ng-container *ngIf="paymentLoading == 'pending'">
                    <div class="pm-items__tab_pending">
                        <ngx-skeleton-loader
                            *ngFor="let _ of [1, 2]"
                            count="1"
                            appearance="line"
                            [theme]="{
                                width: '490px',
                                height: '180px',
                                'border-radius': '20px',
                                margin: '0'
                            }"
                        ></ngx-skeleton-loader>
                    </div>
                    <div class="pm-items__pc_pending">
                        <ngx-skeleton-loader
                            *ngFor="let _ of [1, 2]"
                            count="1"
                            appearance="line"
                            [theme]="{
                                width: '665px',
                                height: '180px',
                                'border-radius': '20px',
                                margin: '0'
                            }"
                        ></ngx-skeleton-loader>
                    </div>
                </ng-container>
                <ng-container *ngIf="paymentLoading == 'idle'">
                    <rwm-center-payment-card
                        *ngFor="let item of paymentItemList; let isLast = last; let isFirst = first"
                        [center]="center"
                        [paymentItems]="paymentItemList"
                        [isLast]="isLast"
                        [paymentItem]="item"
                        (onCancelPayment)="onCancelPayment($event)"
                    ></rwm-center-payment-card>
                </ng-container>
            </div>
        </div>
    </div>
</ng-scrollbar>

<rwa-modal
    [(visible)]="showDisableToGoPaymentPageModal"
    [data]="disableToGoPaymentPageModalData"
    [type]="'oneButton'"
    (confirm)="showDisableToGoPaymentPageModal = false"
></rwa-modal>
