<div
    class="badge user-select-none"
    [style.background-color]="bgColor"
    [style.color]="color"
    [style.font-weight]="fontWeight"
    [style.font-size]="fontSize"
    [style.line-height]="lineHeight"
    [style.padding]="padding"
    [style.border-radius]="borderRadius"
>
    <ng-content></ng-content>
</div>
