<router-outlet></router-outlet>

<rwa-toast
    [(visible)]="toastState.visible"
    [text]="toastState.text"
    [timeOutCount]="3"
    (cancel)="hideToast()"
></rwa-toast>
<rwa-modal
    [(visible)]="modalState.isVisible"
    [data]="modalState.data"
    [type]="'oneButton'"
    (cancel)="hideModal()"
></rwa-modal>
<rwm-set-center-modal
    [center]="selectedCenter"
    [(visible)]="setCenterModalVisible"
    (close)="setCenterModalVisible = false"
></rwm-set-center-modal>

<rwm-payment-method-management-modal
    [(visible)]="paymentMethodModalVisible"
    [center]="selectedCenter"
    (close)="setPaymentMethodModalVisible(false)"
    (open)="setPaymentMethodModalVisible(true)"
></rwm-payment-method-management-modal>
