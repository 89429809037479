<div class="rw-modal-background" #modalBackgroundElement></div>
<div
    class="rw-modal-wrapper justify-content-center align-items-center"
    (mouseup)="!isMouseModalDown && !blockClickOutside && onCancel()"
    (mousedown)="resetMouseModalDown()"
    #modalWrapperElement
>
    <div
        class="rw-modal user-select-none"
        [style.width]="width"
        (mousedown)="onMouseModalDown(); $event.stopPropagation()"
    >
        <div class="text d-flex">{{ data?.title }}</div>
        <div class="sub-text d-flex">{{ data?.desc }}</div>
        <div class="button-group d-flex align-items-center justify-content-end">
            <rwa-button
                *ngIf="type == 'twoButton'"
                class="cancel-button"
                [padding]="'9px 25px 7px'"
                [height]="'45px'"
                [bgColor]="'var(--white)'"
                [hoverBgColor]="'var(--gray-30)'"
                (onClick)="onCancel()"
            >
                <ng-template btIdleContent>
                    <span class="cancel-text">{{ data?.cancel || '취소' }}</span>
                </ng-template>
                <ng-template btPendingContent>{{ data?.cancel || '취소' }}</ng-template>
            </rwa-button>

            <rwa-button
                [padding]="'9px 25px 7px'"
                [height]="'45px'"
                [status]="confirmButtonLoading"
                [loadingMargin]="'0'"
                (onClick)="onConfirm()"
            >
                <ng-template btIdleContent>
                    <span class="confirm-text">{{ data?.confirm || '확인' }}</span>
                </ng-template>
                <ng-template btPendingContent
                    ><span class="confirm-text">{{ data?.confirm || '확인' }}</span></ng-template
                >
            </rwa-button>
        </div>
    </div>
</div>
