<button
    class="button"
    #l_button
    [style.width]="width"
    [style.height]="height"
    [style.border-radius]="borderRadius"
    [class.mode-fill]="type == 'fill'"
    (mousedown)="onMouseDown()"
    (mouseup)="onMouseUp()"
    (focusin)="onFocus(l_button)"
    (focusout)="onFocusOut(l_button)"
    (click)="_onClick()"
>
    <ng-content></ng-content>
</button>
