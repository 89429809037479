<button
    #l_button
    type="button"
    class="l-button"
    [style.width]="width"
    [style.height]="height"
    [style.border-radius]="borderRadius"
    [style.border-width]="borderWidth"
    [style.padding]="padding"
    [style.border-color]="disable ? disableBorderColor : borderColor"
    [style.background-color]="disable ? disableBgColor : bgColor"
    [style.color]="disable ? disableFontColor : fontColor"
    [disabled]="disable"
    (click)="_onClick()"
    (mouseenter)="onHover()"
    (mouseleave)="onHoverOut()"
    (mousedown)="onMouseDown()"
    (mouseup)="onMouseUp()"
    (focusin)="onFocus()"
    (focusout)="onFocusOut()"
>
    <div *ngIf="status == 'idle'" class="idle d-flex align-items-center justify-content-center">
        <ng-container [ngTemplateOutlet]="idleRef.templateRef"></ng-container>
    </div>

    <div *ngIf="status == 'pending'" class="pending d-flex align-items-center justify-content-center">
        <div class="l-spinner">
            <ngx-spinner
                bdColor="rgba(0, 0, 0, 0.8)"
                type="ball-clip-rotate"
                [size]="loadingSize"
                [color]="loadingColor"
                [bdColor]="'transparent'"
                [fullScreen]="false"
                [name]="loadingName"
            >
            </ngx-spinner>
        </div>
        <ng-container [ngTemplateOutlet]="pendingRef.templateRef"> </ng-container>
    </div>

    <div *ngIf="status == 'done'" class="done d-flex align-items-center justify-content-center">
        <rwa-svg-check
            [color]="loadingColor"
            [width]="'22px'"
            [height]="'22px'"
            [margin]="'0 10px 0 0'"
        ></rwa-svg-check>
        <ng-container [ngTemplateOutlet]="doneRef.templateRef"></ng-container>
    </div>

    <div
        *ngIf="status == 'pending'"
        #progress
        [style.width]="progressPercent + '%'"
        [style.background-color]="progressBgColor"
        class="progress"
    ></div>
</button>
