<div class="l-profile-photo">
    <div class="profile-photo" [style.background-color]="user.color ?? '#EB7C7C'" [class.empty]="!!!user.picture">
        <img
            *ngIf="!!!user.picture"
            [src]="'/assets/icons/avatar/avatar-smiley-white.svg'"
            loading="lazy"
            [style.width]="'75px'"
            [style.height]="'75px'"
            alt="avatar-smiley-white.svg"
        />
        <img
            *ngIf="!!user.picture"
            [src]="user.picture"
            loading="lazy"
            [style.width]="'137px'"
            [style.height]="'137px'"
            alt="user-picture.png"
        />
        <input
            type="file"
            accept="image/*"
            #userProfile
            (change)="registerPhoto(userProfile)"
            (click)="onPhotoClicked($event)"
            hidden
        />
    </div>
    <rwa-icon-ghost-button
        *ngIf="!!!user.picture"
        class="add-picture"
        [sizeType]="'md'"
        (onClick)="userProfile.click()"
        rwTooltip
        rwTooltipPlacement="bottom"
        rwTooltipTitle="내 사진 등록하기"
    >
        <ng-template iconGhostBtIdleContent>
            <rwa-svg-plus [color]="'var(--gray-90)'" [width]="'22px'" [height]="'22px'"></rwa-svg-plus>
        </ng-template>
    </rwa-icon-ghost-button>
    <rwa-icon-ghost-button
        *ngIf="!!user.picture"
        class="edit-picture"
        [sizeType]="'md'"
        (onClick)="userProfile.click()"
        rwTooltip
        rwTooltipPlacement="bottom"
        rwTooltipTitle="내 사진 변경하기"
    >
        <ng-template iconGhostBtIdleContent>
            <rwa-svg-pencil [width]="'22px'" [height]="'22px'"></rwa-svg-pencil>
        </ng-template>
    </rwa-icon-ghost-button>
    <rwa-xs-icon-button
        *ngIf="!!user.picture"
        class="remove-picture"
        [type]="'fill'"
        width="27px"
        height="27px"
        borderRadius="12px"
        (onClick)="removePhoto()"
    >
        <rwa-svg-close color="var(--white)" [width]="'15px'" [height]="'15px'"></rwa-svg-close>
    </rwa-xs-icon-button>
</div>
