<div class="rw-modal-background" #modalBackgroundElement></div>
<div
    class="rw-modal-wrapper justify-content-center align-items-center"
    (mouseup)="!isMouseModalDown && !blockClickOutside && onClose()"
    (mousedown)="resetMouseModalDown()"
    #modalWrapperElement
>
    <div class="rw-modal user-select-none" (mousedown)="onMouseModalDown(); $event.stopPropagation()">
        <div class="header">
            <div class="header-title">마케팅 정보 수신</div>
            <rwa-icon-ghost-button sizeType="md" borderRadius="14px" borderColor="var(--gray-60)" (onClick)="onClose()">
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-close width="22px" height="22px" color="var(--gray-100)"></rwa-svg-close>
                </ng-template>
            </rwa-icon-ghost-button>
        </div>
        <div class="body">
            <div class="l-gender-radio">
                <rwa-checkbox
                    [checked]="checkObj.sms"
                    label="SMS"
                    (onClick)="checkObj.sms = !checkObj.sms"
                ></rwa-checkbox>
                <rwa-checkbox
                    [checked]="checkObj.email"
                    label="이메일"
                    (onClick)="checkObj.email = !checkObj.email"
                ></rwa-checkbox>
            </div>
            <rwa-button
                class="save-button"
                padding="9px 25px 7px"
                (onClick)="onConfirm()"
                [loadingName]="'marketing-save'"
                [status]="confirmButtonLoading"
            >
                <ng-template btIdleContent><span class="save-button-text">저장하기</span></ng-template>
                <ng-template btPendingContent><span class="save-button-text">저장하기</span></ng-template>
            </rwa-button>
        </div>
    </div>
</div>
