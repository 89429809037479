<div class="rw-modal-background" #modalBackgroundElement></div>
<div
    class="rw-modal-wrapper justify-content-center align-items-center"
    (mouseup)="!isMouseModalDown && !blockClickOutside && onClose(false)"
    (mousedown)="resetMouseModalDown()"
    #modalWrapperElement
>
    <div class="rw-modal user-select-none" (mousedown)="onMouseModalDown(); $event.stopPropagation()">
        <div class="header">
            <div class="header-title">
                <span>직원 등록하기</span>
            </div>
            <rwa-icon-ghost-button
                sizeType="md"
                borderRadius="14px"
                borderColor="var(--gray-60)"
                (onClick)="onClose(false)"
            >
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-close width="22px" height="22px" color="var(--gray-100)"></rwa-svg-close>
                </ng-template>
            </rwa-icon-ghost-button>
        </div>
        <ng-scrollbar class="rw-scroll body-height">
            <div class="body" #body>
                <div class="create-employee-desc">✋ 잠깐! 직원의 사진과 이름은 회원에게도 동일하게 보여져요.</div>
                <div class="create-employee-content">
                    <div class="create-employee-content__left">
                        <span class="tag"> 직원 사진 </span>
                        <rwm-employee-photo
                            [pictureSrc]="employeePictureSrc"
                            (pictureChange)="onCenterPictureChange($event)"
                        ></rwm-employee-photo>
                    </div>
                    <form [formGroup]="centerForm" class="create-employee-content__right">
                        <div class="create-employee-content__item">
                            <span class="tag">권한<span class="required">*</span></span>
                            <rwa-tab
                                [width]="308"
                                [tabs]="positionTabs"
                                (onTabItemSelected)="positionTabs = $event"
                            ></rwa-tab>
                        </div>
                        <div class="create-employee-content__item">
                            <rwa-text-field
                                [width]="'308px'"
                                [label]="'이름'"
                                [placeholder]="'이름'"
                                [isImportant]="true"
                                [type]="'normal'"
                                [inputLimit]="30"
                                [formControlName]="'employeeName'"
                            ></rwa-text-field>
                        </div>
                        <div class="create-employee-content__item">
                            <rwa-text-field
                                [width]="'308px'"
                                [label]="'전화번호'"
                                [placeholder]="'-없이 숫자만 입력'"
                                [isImportant]="true"
                                [type]="'normal'"
                                [inputLimit]="13"
                                [formControlName]="'phoneNumber'"
                            ></rwa-text-field>
                        </div>
                        <div class="create-employee-content__item">
                            <rwa-text-field
                                [width]="'308px'"
                                [label]="'이메일 주소'"
                                [placeholder]="'이메일 주소'"
                                [isImportant]="false"
                                [type]="'normal'"
                                [inputLimit]="50"
                                [formControlName]="'email'"
                                [(status)]="emailStatus"
                                [statusText]="'이미 센터에 등록되어 있는 이메일 주소예요.'"
                            ></rwa-text-field>
                            <div class="email-terms">
                                <span class="terms-title"> ✨ 이메일 주소로 계정을 연동할 수 있어요! </span>
                                <span class="terms-desc">
                                    직원 계정과 동일한 이메일 주소를 입력한 후<br />
                                    저장하면, 직원 계정에 센터 초대가 발송돼요.<br />
                                    초대를 수락한 직원은 본인의 계정으로 센터에<br />
                                    입장할 수 있어요.
                                </span>
                            </div>
                        </div>
                        <div class="create-employee-content__item">
                            <span class="tag">이메일 주소로 계정 연동</span>
                            <rwa-toggle-button
                                [disable]="email.value?.length == 0"
                                [checked]="linkEmailAccount"
                                (onClick)="linkEmailAccount = !linkEmailAccount"
                                [label]="
                                    linkEmailAccount ? '직원 계정을 연동할게요.' : '직원 계정을 연동하지 않을게요.'
                                "
                            ></rwa-toggle-button>
                        </div>
                        <rwa-button
                            padding="9px 25px 7px"
                            loadingName="create-employee-button"
                            [status]="createButtonLoading"
                            [disable]="phoneNumber.invalid || employeeName.invalid || (email.value && email.invalid)"
                            (onClick)="createEmployee()"
                        >
                            <ng-template btIdleContent>
                                <span class="create-employee-button-text">저장하기</span>
                            </ng-template>
                            <ng-template btPendingContent>
                                <span class="create-employee-button-text">저장하기</span>
                            </ng-template>
                        </rwa-button>
                    </form>
                </div>
            </div>
        </ng-scrollbar>
    </div>
</div>
