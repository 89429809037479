<div class="rw-modal-background" #modalBackgroundElement></div>
<div
    class="rw-modal-wrapper justify-content-center align-items-center"
    (mouseup)="!isMouseModalDown && !blockClickOutside && onClose(false)"
    (mousedown)="resetMouseModalDown()"
    #modalWrapperElement
>
    <div class="rw-modal user-select-none" (mousedown)="onMouseModalDown(); $event.stopPropagation()">
        <div class="header">
            <div class="header-title">내 정보</div>
            <rwa-icon-ghost-button
                sizeType="md"
                borderRadius="14px"
                borderColor="var(--gray-60)"
                (onClick)="onClose(false)"
            >
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-close width="22px" height="22px" color="var(--gray-100)"></rwa-svg-close>
                </ng-template>
            </rwa-icon-ghost-button>
        </div>
        <div class="body scroll-y-overlay" #body>
            <div class="l-profile-photo body-item-gap">
                <rwm-profile-photo [user]="user"></rwm-profile-photo>
                <div class="l-profile-photo__text d-flex align-items-center justify-content-center">
                    <span
                        class="profile-photo__text"
                        rwEllipsisDropdown
                        [lineClamp]="1"
                        [edText]="basicInfo.name.value"
                        [additionalDropDownWidth]="'128px'"
                    ></span>
                    <span class="profile-photo__text"> 님, 반가워요. 🙌 </span>
                </div>

                <rwa-ghost-button width="230px" height="45px" (onClick)="logout()">
                    <ng-template gbtIdleContent>
                        <span class="logout-text">로그아웃</span>
                    </ng-template>
                </rwa-ghost-button>
            </div>
            <div class="l-basic-info body-item-gap">
                <div class="basic-info__header">
                    <span>기본 정보</span>
                    <div class="basic-info__header-tag"></div>
                </div>
                <ng-container *ngFor="let item of basicInfo | keyvalue : originalOrder">
                    <div class="basic-info__item" *ngIf="item.value['visible']">
                        <div class="basic-info__item-category">{{ item.value['category'] }}</div>
                        <div
                            class="basic-info__item-value"
                            [class.gray-value]="item.key == 'email' || item.key == 'linkedAccount'"
                        >
                            <span rwEllipsisDropdown [lineClamp]="1" [edText]="item.value['value'] ?? '-'"> </span>
                        </div>
                        <rwa-ghost-button
                            *ngIf="item.key != 'email' && item.key != 'linkedAccount'"
                            class="basic-info__item-button"
                            width="52px"
                            height="37px"
                            (onClick)="onBasicInfoClick(item.key)"
                        >
                            <ng-template gbtIdleContent>
                                <span>{{ !!item.value['value'] ? '변경' : '추가' }}</span>
                            </ng-template>
                        </rwa-ghost-button>
                    </div>
                </ng-container>
            </div>
            <div class="l-account-management body-item-gap">
                <div class="account-management__header">
                    <span>계정 관리</span>
                    <div class="account-management__header-tag"></div>
                </div>
                <div *ngIf="isEmailAccount" class="account-management__item" (click)="showChangePasswordModal = true">
                    <div class="account-management__item-value">비밀번호 변경</div>
                    <div class="caret-icon"></div>
                </div>
                <div class="account-management__item" (click)="showDeleteUserModal = true">
                    <div class="account-management__item-value">계정 삭제</div>
                    <div class="caret-icon"></div>
                </div>
            </div>
            <div class="l-terms">
                <rwa-text-button fontWeight="400" (onClick)="termsEULAVisible = true">서비스 이용약관</rwa-text-button>
                <div class="l-terms-divider"></div>
                <rwa-text-button fontWeight="400" (onClick)="termsPrivacyVisible = true"
                    >개인정보 보호 및 이용</rwa-text-button
                >
            </div>
        </div>
    </div>
</div>

<rw-terms-eula [(visible)]="termsEULAVisible" (cancel)="termsEULAVisible = false"></rw-terms-eula>
<rw-terms-privacy [(visible)]="termsPrivacyVisible" (cancel)="termsPrivacyVisible = false"></rw-terms-privacy>

<rwm-change-user-name-modal
    [(visible)]="showChangeNameModal"
    (close)="showChangeNameModal = false"
    (onNameConfirm)="onChangeName($event)"
    [name]="user?.name"
></rwm-change-user-name-modal>
<rwm-change-user-phone-number-modal
    [(visible)]="showChangePhoneNumberModal"
    (close)="showChangePhoneNumberModal = false"
    (confirm)="onChangePhoneNumber()"
    [user]="user"
></rwm-change-user-phone-number-modal>
<rwm-change-user-gender-modal
    [(visible)]="showChangeGenderModal"
    (close)="showChangeGenderModal = false"
    [gender]="user?.sex"
    (onGenderConfirm)="onChangeGender($event)"
></rwm-change-user-gender-modal>
<rwm-change-user-birth-date-modal
    [(visible)]="showChangeBirthDateModal"
    (close)="showChangeBirthDateModal = false"
    [birthDate]="user?.birth_date"
    (onBirthDateConfirm)="onChangeBirthDate($event)"
></rwm-change-user-birth-date-modal>
<rwm-change-user-marketing-modal
    [email]="user?.marketing_email"
    [sms]="user?.marketing_sms"
    [(visible)]="showChangeMarketingModal"
    (close)="showChangeMarketingModal = false"
    (onMarketingConfirm)="onChangeMarketing($event)"
></rwm-change-user-marketing-modal>
<rwm-change-user-password-modal
    [user]="user"
    [(visible)]="showChangePasswordModal"
    (close)="showChangePasswordModal = false"
    (onPasswordConfirm)="showChangePasswordModal = false"
></rwm-change-user-password-modal>

<rwm-delete-account-modal
    [user]="user"
    [(visible)]="showDeleteUserModal"
    (cancel)="showDeleteUserModal = false"
    (confirm)="showDeleteUserModal = false"
></rwm-delete-account-modal>
