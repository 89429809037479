<div class="rw-modal-background" #modalBackgroundElement></div>
<div
    class="rw-modal-wrapper justify-content-center align-items-center"
    (mouseup)="!isMouseModalDown && !blockClickOutside && onClose(false)"
    (mousedown)="resetMouseModalDown()"
    #modalWrapperElement
>
    <div
        class="rw-modal user-select-none"
        [class.free-trial-modal]="!!!user.free_trial_membership_at"
        (mousedown)="onMouseModalDown(); $event.stopPropagation()"
    >
        <div class="header">
            <div class="header-title">
                <span>센터 만들기</span>
                <span class="header-title--desc"> 모든 정보는 [센터 관리]에서 변경할 수 있어요. </span>
            </div>
            <rwa-icon-ghost-button
                sizeType="md"
                borderRadius="14px"
                borderColor="var(--gray-60)"
                (onClick)="onClose(false)"
            >
                <ng-template iconGhostBtIdleContent>
                    <rwa-svg-close width="22px" height="22px" color="var(--gray-100)"></rwa-svg-close>
                </ng-template>
            </rwa-icon-ghost-button>
        </div>

        <ng-scrollbar [track]="'vertical'" class="rw-scroll">
            <div scrollViewport>
                <div class="body" #body>
                    <div class="create-center-desc">
                        ✋ 잠깐! ‘센터 사진, 이름, 전화번호, 주소’는 회원에게도 동일하게 보여져요.
                    </div>
                    <div class="create-center-content">
                        <div class="create-center-content__left">
                            <span class="tag"> 센터 사진 </span>
                            <rwm-center-photo
                                [pictureSrc]="centerPictureSrc"
                                (pictureChange)="onCenterPictureChange($event)"
                            ></rwm-center-photo>
                        </div>
                        <form [formGroup]="centerForm" class="create-center-content__right">
                            <div class="create-center-content__item">
                                <rwa-text-field
                                    [width]="'308px'"
                                    [label]="'센터 이름'"
                                    [placeholder]="'센터 이름'"
                                    [isImportant]="true"
                                    [type]="'wordLimit'"
                                    [inputLimit]="30"
                                    [formControlName]="'centerName'"
                                ></rwa-text-field>
                            </div>
                            <div class="create-center-content__item flex-row align-items-end">
                                <rwa-text-field
                                    [width]="'110px'"
                                    [label]="'전화번호'"
                                    [placeholder]="'숫자'"
                                    [isImportant]="true"
                                    [type]="'normal'"
                                    [inputLimit]="4"
                                    [formControlName]="'firstPhoneNumber'"
                                ></rwa-text-field>
                                <div class="dash">-</div>
                                <rwa-text-field
                                    [width]="'180px'"
                                    [placeholder]="'-없이 숫자만 입력'"
                                    [labelVisible]="false"
                                    [type]="'normal'"
                                    [inputLimit]="9"
                                    [formControlName]="'secondPhoneNumber'"
                                ></rwa-text-field>
                            </div>
                            <div class="create-center-content__item">
                                <div class="l-address-box address-box-margin">
                                    <rwa-text-field
                                        [width]="'198px'"
                                        [isImportant]="true"
                                        [label]="'우편 번호'"
                                        [placeholder]="'우편 번호'"
                                        [formControlName]="'zonecode'"
                                    ></rwa-text-field>
                                    <rwa-button
                                        [padding]="'7.5px 16px 5.5px'"
                                        [bgColor]="'#2F2D2D'"
                                        [hoverBgColor]="'#141517'"
                                        (onClick)="openSearchAddressPopup()"
                                    >
                                        <ng-template btIdleContent>
                                            <span class="search-address-text"> 주소 검색 </span>
                                        </ng-template>
                                    </rwa-button>
                                </div>
                                <rwa-text-field
                                    class="address-box-margin"
                                    [labelVisible]="false"
                                    [width]="'308px'"
                                    [placeholder]="'주소'"
                                    [formControlName]="'roadAddress'"
                                ></rwa-text-field>
                                <rwa-text-field
                                    [labelVisible]="false"
                                    [width]="'308px'"
                                    [placeholder]="'상세 주소'"
                                    [formControlName]="'detailedAddress'"
                                ></rwa-text-field>
                            </div>
                            <div class="create-center-content__item">
                                <rwa-textfield-button
                                    [style.margin-bottom]="'26px'"
                                    [width]="'308px'"
                                    [label]="'사업자 등록증'"
                                    [tagText]="'파일 선택'"
                                    [value]="businessLicenseFileName"
                                    [placeHolder]="'파일 첨부 필수'"
                                    [hint]="'2MB 이하의 jpg, png, pdf 파일만 첨부 가능'"
                                    (onClick)="businessLicense.click()"
                                ></rwa-textfield-button>
                                <input
                                    type="file"
                                    accept=".png, .jpg, .pdf"
                                    #businessLicense
                                    (click)="onBusinessLicenseClicked($event)"
                                    (change)="setBusinessLicenseFile(businessLicense)"
                                    hidden
                                />
                            </div>
                            <div *ngIf="!user?.free_trial_membership_at" class="create-center-content__item">
                                <span class="tag">약관 동의<span class="required">*</span></span>
                                <rwa-checkbox
                                    [checked]="freeTrialAgree"
                                    (onClick)="freeTrialAgree = !freeTrialAgree"
                                    [label]="'(필수) 무료 체험 약관에 동의합니다.'"
                                ></rwa-checkbox>
                                <div class="free-trial-terms">
                                    <span class="terms-title"> 무료 체험 이용 약관 </span>
                                    <span class="terms-desc">
                                        무료 체험 기간이 끝난 후 별도의 결제는 이루<br />
                                        어지지 않으며, 이용권 구매 시 결제가 진행됩<br />니다.
                                    </span>
                                    <span class="terms-desc">
                                        무료 체험 기간이 끝난 후 30일 동안 접속한<br />
                                        이력이 없는 경우, 센터와 관련된 모든 정보가<br />
                                        자동으로 삭제됩니다.
                                    </span>
                                </div>
                            </div>
                            <rwa-button
                                padding="9px 25px 7px"
                                loadingName="create-center-button"
                                [status]="createButtonLoading"
                                [disable]="
                                    centerForm.invalid ||
                                    (!freeTrialAgree && !!!user?.free_trial_membership_at) ||
                                    !businessLicenseFileName
                                "
                                (onClick)="createCenter()"
                            >
                                <ng-template btIdleContent>
                                    <span class="create-center-button-text">{{
                                        user?.free_trial_membership_at ? '센터 만들기' : '2주 무료 체험 시작하기'
                                    }}</span>
                                </ng-template>
                                <ng-template btPendingContent>
                                    <span class="create-center-button-text">{{
                                        user?.free_trial_membership_at ? '센터 만들기' : '2주 무료 체험 시작하기'
                                    }}</span>
                                </ng-template>
                            </rwa-button>
                        </form>
                    </div>
                </div>
            </div>
        </ng-scrollbar>
    </div>
</div>
