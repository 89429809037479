<div
    class="l-one-month-calendar d-flex flex-column user-select-none"
    [class.is-shadow]="isShadow"
    *ngIf="mode == 'date'"
>
    <div class="header">
        <div class="header-left">
            <div class="d-flex justify-content-start align-items-center">
                <button
                    #date_prev_y
                    class="icon-button right-margin"
                    (mousedown)="onMouseDown()"
                    (mouseup)="onMouseUp()"
                    (focusin)="onFocus(date_prev_y)"
                    (focusout)="onFocusOut(date_prev_y)"
                    (click)="previousYear(); date_prev_y.blur()"
                >
                    <div class="caret-cdl"></div>
                </button>
                <button
                    #date_prev_m
                    class="icon-button"
                    (mousedown)="onMouseDown()"
                    (mouseup)="onMouseUp()"
                    (focusin)="onFocus(date_prev_m)"
                    (focusout)="onFocusOut(date_prev_m)"
                    (click)="previousMonth(); date_prev_m.blur()"
                >
                    <div class="caret-cl"></div>
                </button>
            </div>
            <div class="month-day d-flex justify-content-end align-items-center">{{ curMonth?.monthFormat }}</div>
        </div>
        <div class="header-right">
            <div class="month-day d-flex justify-content-end align-items-center">{{ postMonth?.monthFormat }}</div>
            <div class="d-flex justify-content-end align-items-center">
                <button
                    #date_next_m
                    class="icon-button"
                    (mousedown)="onMouseDown()"
                    (mouseup)="onMouseUp()"
                    (focusin)="onFocus(date_next_m)"
                    (focusout)="onFocusOut(date_next_m)"
                    (click)="nextMonth(); date_next_m.blur()"
                >
                    <div class="caret-cr"></div>
                </button>
                <button
                    #date_next_y
                    class="left-margin icon-button"
                    (mousedown)="onMouseDown()"
                    (mouseup)="onMouseUp()"
                    (focusin)="onFocus(date_next_y)"
                    (focusout)="onFocusOut(date_next_y)"
                    (click)="nextYear(); date_next_y.blur()"
                >
                    <div class="caret-cdr"></div>
                </button>
            </div>
        </div>
    </div>

    <div class="body">
        <div class="curDays">
            <div class="days-of-week">
                <div class="day">일</div>
                <div class="day">월</div>
                <div class="day">화</div>
                <div class="day">수</div>
                <div class="day">목</div>
                <div class="day">금</div>
                <div class="day">토</div>
            </div>
            <div
                class="week-row"
                *ngFor="
                    let weekRow of weekRows | slice : 0 : nextMonthRow + this.isPastMonthDateIncluded;
                    let i = index
                "
            >
                <div class="d-flex align-items-center" *ngFor="let weekCol of weekRow; let j = index">
                    <div class="l-week-col" [class.seven-week-col]="j == 6">
                        <button
                            #bt
                            class="week-col"
                            [style.visibility]="weekCol['isCurMonthVisible'] ? 'visible' : 'hidden'"
                            [style.color]="weekCol.color"
                            [style.font-weight]="weekCol.fontWeight"
                            [class.selected]="weekCol.selected"
                            (mousedown)="onMouseDown()"
                            (mouseup)="onMouseUp()"
                            (focusin)="onFocus(bt)"
                            (focusout)="onFocusOut(bt)"
                            (click)="selectDate(i, j)"
                        >
                            {{ weekCol.day }}
                        </button>
                    </div>
                    <div *ngIf="(weekCol['isEnd'] || weekCol['isSame']) && j != 6" class="week-col-padding"></div>
                </div>
            </div>
        </div>
        <div class="postDays">
            <div class="days-of-week">
                <div class="day">일</div>
                <div class="day">월</div>
                <div class="day">화</div>
                <div class="day">수</div>
                <div class="day">목</div>
                <div class="day">금</div>
                <div class="day">토</div>
            </div>
            <div class="week-row" *ngFor="let weekRow of weekRows | slice : nextMonthRow; let i = index">
                <div class="d-flex align-items-center" *ngFor="let weekCol of weekRow; let j = index">
                    <div class="l-week-col" [class.seven-week-col]="j == 6">
                        <button
                            #bt
                            class="week-col"
                            [style.visibility]="weekCol['isPostMonthVisible'] ? 'visible' : 'hidden'"
                            [style.color]="weekCol.color"
                            [style.font-weight]="weekCol.fontWeight"
                            [class.selected]="weekCol.selected"
                            (mousedown)="onMouseDown()"
                            (mouseup)="onMouseUp()"
                            (focusin)="onFocus(bt)"
                            (focusout)="onFocusOut(bt)"
                            (click)="selectDate(i + nextMonthRow, j)"
                        >
                            {{ weekCol.day }}
                        </button>
                    </div>
                    <div *ngIf="(weekCol['isEnd'] || weekCol['isSame']) && j != 6" class="week-col-padding"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="l-one-month-calendar multiline d-flex flex-column user-select-none"
    [class.is-shadow]="isShadow"
    *ngIf="mode == 'multiline'"
>
    <div class="header">
        <div class="header-left">
            <div class="d-flex justify-content-start align-items-center">
                <button
                    #ml_prev_y
                    class="icon-button right-margin"
                    (mousedown)="onMouseDown()"
                    (mouseup)="onMouseUp()"
                    (focusin)="onFocus(ml_prev_y)"
                    (focusout)="onFocusOut(ml_prev_y)"
                    (click)="previousYear(); ml_prev_y.blur()"
                >
                    <div class="caret-cdl"></div>
                </button>
                <button
                    #ml_prev_m
                    class="icon-button"
                    (mousedown)="onMouseDown()"
                    (mouseup)="onMouseUp()"
                    (focusin)="onFocus(ml_prev_m)"
                    (focusout)="onFocusOut(ml_prev_m)"
                    (click)="previousMonth(); ml_prev_m.blur()"
                >
                    <div class="caret-cl"></div>
                </button>
            </div>
            <div class="month-day d-flex justify-content-end align-items-center">{{ curMonth?.monthFormat }}</div>
        </div>
        <div class="header-right">
            <div class="month-day d-flex justify-content-end align-items-center">{{ postMonth?.monthFormat }}</div>
            <div class="d-flex justify-content-end align-items-center">
                <button
                    #ml_next_m
                    class="icon-button"
                    (mousedown)="onMouseDown()"
                    (mouseup)="onMouseUp()"
                    (focusin)="onFocus(ml_next_m)"
                    (focusout)="onFocusOut(ml_next_m)"
                    (click)="nextMonth(); ml_next_m.blur()"
                >
                    <div class="caret-cr"></div>
                </button>
                <button
                    #ml_next_y
                    class="left-margin icon-button"
                    (mousedown)="onMouseDown()"
                    (mouseup)="onMouseUp()"
                    (focusin)="onFocus(ml_next_y)"
                    (focusout)="onFocusOut(ml_next_y)"
                    (click)="nextYear(); ml_next_y.blur()"
                >
                    <div class="caret-cdr"></div>
                </button>
            </div>
        </div>
    </div>
    <div class="body">
        <div class="curDays">
            <div class="days-of-week">
                <div class="day">일</div>
                <div class="day">월</div>
                <div class="day">화</div>
                <div class="day">수</div>
                <div class="day">목</div>
                <div class="day">금</div>
                <div class="day">토</div>
            </div>
            <div
                class="week-row"
                *ngFor="
                    let weekRow of weekRows | slice : 0 : nextMonthRow + this.isPastMonthDateIncluded;
                    let i = index
                "
            >
                <div class="d-flex align-items-center pos-rel" *ngFor="let weekCol of weekRow; let j = index">
                    <div
                        class="l-week-col"
                        [class.start-date]="weekCol['isStart']"
                        [class.end-date]="weekCol['isEnd']"
                        [class.same-date]="weekCol['isSame']"
                        [class.between]="weekCol['isBetween']"
                        [class.seven-week-col]="j == 6"
                        [style.visibility]="weekCol['isCurMonthVisible'] ? 'visible' : 'hidden'"
                    >
                        <button
                            #bt
                            class="week-col"
                            [style.color]="weekCol.color"
                            [style.font-weight]="weekCol.fontWeight"
                            [class.unAvailable-date]="!weekCol['isAvailable']"
                            [disabled]="!weekCol['isAvailable']"
                            [class.selected]="
                                selectedMultiDateObj.startDate == weekCol.date ||
                                selectedMultiDateObj.endDate == weekCol.date
                            "
                            (mousedown)="onMouseDown()"
                            (mouseup)="onMouseUp()"
                            (focusin)="onFocus(bt)"
                            (focusout)="onFocusOut(bt)"
                            (mouseenter)="onWeekColHover(weekCol)"
                            (mouseleave)="onWeekColOut()"
                            (click)="multiLineSelectDate(weekCol); $event.stopPropagation()"
                            rwTooltip
                            [rwTooltipDisabled]="weekCol['tooltipDisabled']"
                            [rwTooltipTitle]="weekCol['tooltipTitle'] + '일 간'"
                            rwTooltipPlacement="bottom"
                        >
                            {{ weekCol.day }}
                        </button>
                    </div>
                    <div *ngIf="(weekCol['isEnd'] || weekCol['isSame']) && j != 6" class="week-col-padding"></div>
                    <!--                    <div-->
                    <!--                        class="between-hover"-->
                    <!--                        [class.show]="isHoverBetween(weekCol) && weekCol['isCurMonthVisible']"-->
                    <!--                    ></div>-->
                    <!--                    <div-->
                    <!--                        class="between-selected"-->
                    <!--                        [class.show]="isHoverSelect(weekCol) && weekCol['isCurMonthVisible']"-->
                    <!--                    ></div>-->
                </div>
            </div>
        </div>
        <div class="postDays">
            <div class="days-of-week">
                <div class="day">일</div>
                <div class="day">월</div>
                <div class="day">화</div>
                <div class="day">수</div>
                <div class="day">목</div>
                <div class="day">금</div>
                <div class="day">토</div>
            </div>
            <div class="week-row" *ngFor="let weekRow of weekRows | slice : nextMonthRow; let i = index">
                <div class="d-flex align-items-center pos-rel" *ngFor="let weekCol of weekRow; let j = index">
                    <div
                        class="l-week-col"
                        [class.start-date]="weekCol['isStart']"
                        [class.end-date]="weekCol['isEnd']"
                        [class.same-date]="weekCol['isSame']"
                        [class.between]="weekCol['isBetween']"
                        [class.seven-week-col]="j == 6"
                        [style.visibility]="weekCol['isPostMonthVisible'] ? 'visible' : 'hidden'"
                    >
                        <button
                            #bt
                            class="week-col"
                            [style.color]="weekCol.color"
                            [style.font-weight]="weekCol.fontWeight"
                            [class.unAvailable-date]="!weekCol['isAvailable']"
                            [disabled]="!weekCol['isAvailable']"
                            [class.selected]="
                                selectedMultiDateObj.startDate == weekCol.date ||
                                selectedMultiDateObj.endDate == weekCol.date
                            "
                            (mousedown)="onMouseDown()"
                            (mouseup)="onMouseUp()"
                            (focusin)="onFocus(bt)"
                            (focusout)="onFocusOut(bt)"
                            (mouseenter)="onWeekColHover(weekCol)"
                            (mouseleave)="onWeekColOut()"
                            (click)="multiLineSelectDate(weekCol); $event.stopPropagation()"
                            rwTooltip
                            [rwTooltipDisabled]="weekCol['tooltipDisabled']"
                            [rwTooltipTitle]="weekCol['tooltipTitle'] + '일 간'"
                            rwTooltipPlacement="bottom"
                        >
                            {{ weekCol.day }}
                        </button>
                    </div>
                    <div *ngIf="(weekCol['isEnd'] || weekCol['isSame']) && j != 6" class="week-col-padding"></div>
                    <!--                    <div-->
                    <!--                        class="between-hover"-->
                    <!--                        [class.show]="isHoverBetween(weekCol) && weekCol['isPostMonthVisible']"-->
                    <!--                    ></div>-->
                    <!--                    <div-->
                    <!--                        class="between-selected"-->
                    <!--                        [class.show]="isHoverSelect(weekCol) && weekCol['isPostMonthVisible']"-->
                    <!--                    ></div>-->
                </div>
            </div>
        </div>
    </div>
</div>
