<section class="rw-modal">
    <div class="rw-modal-background" #modalBackgroundElement></div>
    <div class="rw-modal-wrapper justify-content-center align-items-center" (click)="onCancel()" #modalWrapperElement>
        <div class="rw-modal user-select-none" (click)="$event.stopPropagation()">
            <div class="title">
                <span class="rw-typo-bodytext1">레드웨일 서비스 이용약관</span>
                <rwa-icon-ghost-button borderColor="var(--gray-60)" [sizeType]="'md'" (click)="onCancel()">
                    <ng-template iconGhostBtIdleContent>
                        <rwa-svg-close [width]="'22px'" [height]="'22px'" ></rwa-svg-close>
                    </ng-template>
                </rwa-icon-ghost-button>
            </div>

            <div class="content rw-typo-subtext1 thin-scroll-y-overlay">
                <div class="text__title_large_chap">제 1장 총칙</div>

                <div class="text__title_small_chap">제 1조 [약관의 목적]</div>

                <div class="text__content text--paragraph">
                    이 약관은 레드웨일(이하 ‘회사’라고 합니다)에서 온라인으로 제공하는 레드웨일 및 제반 서비스의 이용과
                    관련하여 회사와 센터의 권리, 의무 및 책임 사항 등을 규정함을 목적으로 합니다.
                </div>

                <div class="text__title_small_chap">제2조 [용어의 정리]</div>
                <div class="text__content text--content_margin">
                    이 약관에서 사용하는 용어의 정의는 다음 각호와 같습니다.
                </div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        “서비스”는 센터가 센터 및 수업 관리에 필요한 업무를 모바일 및 PC 온라인으로 이용할 수 있도록
                        회사가 제공하는 일체 서비스의 집합을 의미합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        “센터”는 회사의 홈페이지를 통해 회원 가입을 하고, 이 약관에 따라 회사와 이용계약을 체결하여 유상
                        또는 무상으로 솔루션을 이용하는 고객을 의미합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        “회원”은 회사의 홈페이지 또는 애플리케이션을 통해 회원 가입을 하고 정상적으로 서비스를 이용할 수
                        있는 권한을 부여 받은 고객을 의미합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        “이용료”는 서비스를 통해 제공되는 기본 서비스 및 부가 서비스 비용을 의미합니다.
                    </li>
                    <li class="text__content">
                        “무료 체험”은 회사가 별도로 지정한 조건으로 지속되며, 신규 센터 및 특정 기존 센터가 서비스를
                        실질적으로 이용해 볼 수 있도록 하기 위함입니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제 3조 [약관의 게시, 해석 등]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        회사는 이 약관의 내용을 센터가 언제든지 쉽게 확인할 수 있도록 홈페이지 또는 각 서비스 초기 화면
                        및 기타 눈에 띄는 위치에 게시합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        이 약관의 해석 또는 이 약관에서 정하지 않은 사항 등에 관하여 관련 법령 및 상관습에 따릅니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제 4조 [이용계약의 체결]</div>
                <ol class="first braket_first">
                    <li class="text__content text--content_margin">
                        이용계약은 서비스를 이용하고자 하는 자가 약관의 내용을 확인한 후 동의하고 서비스 운영에 필요한
                        정보를 기재하여 신청하면 회사가 이를 승낙함으로써 성립합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 이용 약관에 동의한 후 접수 순서에 따라 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 업무
                        수행상 또는 기술상 지장이 있으면 일정 시간 가입 승인을 유보할 수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        <div class="text--content_margin_small">
                            회사는 다음 각호에 해당하는 신청에 대해서 승낙하지 않거나 사후에 이용계약을 해지할 수
                            있습니다.
                        </div>
                        <ol class="second">
                            <li>비실명, 타인 명의로 서비스를 신청한 경우</li>
                            <li>정보를 허위로 기재하거나 필수 기재 항목을 누락, 오기한 경우</li>
                            <li>부정한 용도로 서비스를 사용하고자 하는 경우</li>
                            <li>사업자 신원 확인 및 본인 확인에 필요한 서류의 제출을 거부한 경우</li>
                            <li>
                                국내법 또는 국제법을 위반하거나 이 약관을 위반하여 이용계약이 해지된 이력이 있는 경우
                            </li>
                            <li>선정적이고 미풍양속을 해치는 용도로 서비스를 이용한 이력이 있는 경우</li>
                            <li>불법, 부당한 광고, 선전물을 게시하여 이용이 제한된 이력이 있는 경우</li>
                            <li>타인의 권리를 침해하는 등의 부정한 용도로 이용한 이력이 있는 경우</li>
                            <li>
                                만 14세 미만의 미성년자, 한정치산자, 금치산자가 법정 대리인의 동의 없이 이용 신청한 경우
                            </li>
                            <li>회사의 서비스에 대한 이용료를 체납하고 있거나 체납한 이력이 다수 있는 경우</li>
                            <li>
                                센터의 이용 목적이나 서비스 이용 방법이 회사의 재산권이나 영업권을 침해하거나 침해할
                                우려가 있는 경우
                            </li>
                            <li>기타 신청자의 귀책사유로 이용계약의 승낙이 곤란한 경우</li>
                        </ol>
                    </li>
                    <li class="text__content text--content_margin">
                        <div class="text--content_margin_small">
                            회사는 다음 각호에 해당하는 경우 이용계약의 신청을 유보할 수 있습니다.
                        </div>
                        <ol class="second">
                            <li>천재지변, 전쟁 등 국가 초유의 비상사태 또는 그 밖에 부득이한 경우</li>
                            <li>업무 수행에 지장이 초래할 정도의 지극히 곤란한 사유가 있는 경우</li>
                            <li>품질 유지가 곤란하거나 필요한 설비나 인력이 부족한 경우</li>
                            <li>기타 회사의 기술적 / 관리적 사정으로 이용계약의 승낙이 곤란한 경우</li>
                        </ol>
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 이용계약의 승낙 후 본조 3항, 4항에 해당하는 경우 사전 통지 후 이용 계약의 승낙을 철회할
                        수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        센터는 회사에 언제든지 센터 탈퇴를 요청하여 이용계약을 해지할 수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        센터는 센터 가입 시 기재한 개인 정보의 내용에 변경이 발생한 경우, 즉시 변경 사항을 정정하여
                        기재하여야 합니다. 변경의 지체로 인하여 발생한 센터의 손해에 대해 회사는 책임을 지지 않습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 관련 법률 및 회사의 개인정보 취급 방침에서 정한 바에 따라 센터에게 요청하는 센터 정보 및
                        기타 정보 항목을 추가, 삭제 등 변경하여 수집 및 이용할 수 있습니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제 5조 [개인정보의 수집 및 관리]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        회사는 관련 법령 및 회사의 개인정보 처리 방침에 따라 센터의 개인정보를 수집 및 관리합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        센터는 회사가 정하는 바에 따라 서비스 이용에 필요한 정보(개인정보 포함)을 사실에 부합하게 제공할
                        의무가 있으며, 허위의 사실을 기재하거나 타인 명의를 도용하여 서비스를 신청한 경우 서비스 이용이
                        중단되거나 이용계약이 해지될 수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 관련 법률 규정에 의거해 수사상 목적 등으로 국가 기관의 요구가 있거나 방송통신위원회 및
                        한국인터넷진흥원 등의 요청이 있는 경우 관련 정보를 법률이 규정하는 범위 내에서 제공할 수
                        있습니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제 6조 [센터에 대한 통지]</div>
                <div class="text__content text--paragraph">
                    회사가 센터에 대하여 통지를 하는 경우 이 약관에 별도의 규정이 없는 한 센터가 제공한 전자우편, SMS
                    등으로 통지합니다. 다만, 회사는 센터 전체 또는 다수에 대한 통지의 경우 7일 이상 회사의 공지사항 등에
                    게시함으로써 본문의 통지를 갈음할 수 있습니다.
                </div>

                <div class="text__title_small_chap p-top-margin">제 7조 [서비스의 개시]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        회사는 회사에서 인정하는 조건에 부합하는 센터가 이 약관에 동의하고 서비스 이용료 등을 정상적으로
                        납입하면 특별한 사정이 없으면 해당 서비스를 개시합니다. 다만, 즉시 개시하지 못할 사유가 있는
                        경우에는 그 사유 및 개시 일정을 센터에게 제 6조(센터에 대한 통지)에 따라 통지합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 회사가 정한 신원 확인 및 본인 확인 절차 등을 거치지 아니한 경우 절차의 완료 시까지
                        서비스의 개시를 연기할 수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        본조 1항에도 불구하고 센터 신규 생성시에는 이용료를 납부하지 않고 회사에서 정한 조건으로 ‘무료
                        체험’ 서비스를 통해 해당 서비스를 받을 수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        본조 1항에서 회사가 인정하는 조건이라 함은 서비스 개시 당시 해당 서비스 제공 대상을 의미하며 본
                        조건은 회사 재량에 따라 바뀔 수 있습니다.
                    </li>
                </ol>

                <div class="text__title_large_chap title-top-margin">제 2장 계약의 내용</div>

                <div class="text__title_small_chap p-top-margin">제 8조 [서비스의 종류]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        <div class="text--content_margin_small">
                            회사가 제공하는 서비스의 종류는 다음 각호와 같습니다.
                        </div>
                        <ol class="second">
                            <li>센터가 운영하는 센터의 수업, 회원권, 예약, 채팅, 문자 및 매출 관리</li>
                            <li>센터가 운영하는 센터를 다니고 있는 회원의 정보 관리</li>
                            <li>위 각호 업무 처리가 가능한 웹 서비스 제공</li>
                            <li>수업 예약, 채팅이 가능한 회원용 앱 서비스 제공</li>
                        </ol>
                    </li>

                    <li class="text__content text--content_margin">
                        전항의 서비스별 세부사항은 회사의 홈페이지 내 서비스 안내에 따릅니다.
                    </li>

                    <li class="text__content text--content_margin">
                        회사는 센터의 동의 없이 본조 1항 각호의 서비스를 거부할 수 없으며, 마찬가지로 센터는 별개의 계약
                        없이 본조 1항 각호 외의 서비스를 요구할 수 없습니다.
                    </li>

                    <li class="text__content text--content_margin">
                        본조 1항 각호 외에 추가 서비스가 필요한 경우, 센터는 이용계약의 체결과 동시에 또는 체결 후에 이
                        약관과 별개로 제시되는 약관에 따라 계약을 체결할 수 있습니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제 9조 [서비스 제공]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        회사는 제13조(이용료) 및 제14조(이용료 지불 및 결제방법)에 따른 이용료 납입을 통해 서비스가
                        정상적으로 개시된 센터에 대해 센터에서 구매한 이용권의 이용기간 내 센터에서 언제든지 솔루션을
                        이용할 수 있도록 운영해야 합니다.
                    </li>

                    <li class="text__content text--content_margin">
                        전항에도 불구하고 회사는 정기 점검, 시스템의 업그레이드, 증설 등이 필요한 경우 해당 내용, 재개
                        시점을 사전 통지한 후 서비스의 제공을 일정 기간 중단할 수 있습니다.
                    </li>

                    <li class="text__content text--content_margin">
                        <div class="text--content_margin_small">
                            본조 1항에도 불구하고 회사는 다음 각호의 어느 하나에 해당하는 때에는 사전 통지 없이도 일정
                            시간 동안 솔루션의 제공을 중단할 수 있습니다.
                        </div>
                        <ol class="second">
                            <li>
                                센터가 제14조(이용료 지불 및 결제방법)에 따른 이용료를 3회분 이상 납부하지 않은 경우
                            </li>
                            <li>서비스 및 업무 처리를 거부하는 경우</li>
                            <li>지속적인 서비스 제공이 불가능한 경우</li>
                            <li>보안상 시급한 문제로 인하여 긴급한 작업을 필요로 경우</li>
                            <li>일시적으로 서비스에 장애를 줄 만한 트래픽이 발생한 경우</li>
                            <li>기타 천재지변 등으로 정상적인 서비스의 제공이 불가능한 경우</li>
                            <li>기타 사전 통지 없이 서비스를 중단할 수 밖에 없는 명백한 이유가 있는 경우</li>
                        </ol>
                    </li>

                    <li class="text__content text--content_margin">
                        본조 1항에도 불구하고 회사는 부득이하게 사업 종료를 결정한 때에는 영구적으로 솔루션의 제공을
                        중단할 수 있습니다.
                    </li>

                    <li class="text__content text--content_margin">
                        회사는 본조에 따른 서비스 제공 중지로 인하여 센터에게 손해가 발생한 경우 그에 따른 배상 책임을
                        부담하지 아니합니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제10조 [회사의 의무]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        회사는 계속적이고 안정적인 서비스의 제공을 위하여 최선을 다하여 노력합니다.
                    </li>

                    <li class="text__content text--content_margin">
                        회사는 전항의 의무를 다하기 위하여 최적의 인력 및 시스템, 보안 등을 유지해야 합니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제11조 [센터의 의무]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        센터는 경영 지원 업무를 원활하게 진행하기 위해 회사가 요구하는 정확한 정보를 솔루션을 통해
                        제공해야 합니다.
                    </li>

                    <li class="text__content text--content_margin">
                        <div class="text--content_margin_small">
                            센터는 다음 각호의 어느 하나에 해당하는 행위를 해서는 안 됩니다.
                        </div>
                        <ol class="second">
                            <li>허위 또는 도용한 정보의 제공</li>
                            <li>공서양속에 반하는 정보의 제공</li>
                            <li>회사의 업무를 방해하려는 행위</li>
                            <li>서비스의 결과물을 영리 목적으로 제3자에게 제공하는 행위</li>
                            <li>기타 불법, 부당한 행위</li>
                        </ol>
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제12조 [평생 이용권]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        ‘평생 이용권’이라 함은 센터에서 본 이용권을 구매하고 제 4조(이용계약의 체결)에 따라 이용계약이
                        체결된 시점으로부터 회사가 서비스를 지속하는 기간 동안 제32조(데이터 제한)에 따른 데이터 제한
                        관련 변경사항을 제외하면 별도 추가 이용료 없이 계속하여 기본 서비스를 이용할 수 있는 이용권을
                        의미합니다.
                    </li>

                    <li class="text__content text--content_margin">
                        전항에도 불구하고 제20조(계약의 해지, 손해배상)에 따른 계약의 해지, 제15조(환불)에 따른 환불
                        또는 제16조(센터 만료)에 따른 센터 만료가 된 경우, 발생 시점으로부터 서비스 제공이 중단됩니다.
                    </li>

                    <li class="text__content text--content_margin">
                        본조 1항의 ‘기본 서비스’라 함은 평생 이용권 구매 시점 당시 회사에서 평생 이용권 대상 서비스로
                        정의한 서비스를 의미하며 해당 시점 이후 개발된 서비스는 평생 이용권 대상 서비스에 포함되지 않을
                        수 있습니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제13조 [이용료]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        센터는 솔루션이 제공하는 일반적인 서비스에 대하여 홈페이지에 따른 이용료를 지불해야 합니다.
                    </li>

                    <li class="text__content text--content_margin">
                        이용료와 서비스 이용과 관련해 발생하는 기타 청구 금액은 이용료 시작일에 해당하는 날짜에 계약
                        단위로 청구됩니다.
                    </li>

                    <li class="text__content text--content_margin">
                        계정 만료, 잔고 부족 또는 기타 사유로 인하여 결제가 정상적으로 처리되지 않았음에도 센터가 등록
                        결제 수단을 변경하지 않거나 이용을 해지하지 않는 경우, 유효한 결제 수단이 등록될 때까지 서비스
                        이용이 제한될 수 있습니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제 14조 [이용료 지불 및 결제방법]</div>

                <ol class="first">
                    <li class="text__content">
                        <div class="text--content_margin_small">
                            이용료에 대한 지불은 다음 호의 방식으로 진행됩니다.
                        </div>
                        <ol class="second text--content_margin_small">
                            <li>월납 : 정기 결제일에 해당하는 기본 서비스 이용료를 선결제하는 방식</li>
                            <li>
                                일시납 : ‘1년 이용권’, ‘2년 이용권’ 및 ‘평생 이용권’ 등과 같이 결제를 위해 최초 1회 각
                                이용권별 이용 기간에 해당하는 기본 서비스 이용료를 선결제하는 방식
                            </li>
                        </ol>
                    </li>

                    <li class="text__content text--content_margin text-top-margin">
                        이용료는 인터넷뱅킹 등 회사에서 제공하는 결제 방법으로 결제합니다.
                    </li>

                    <li class="text__content">
                        <div class="text--content_margin_small">
                            월납 방식을 채택한 센터에서 이용 계약 기간 중 요금제가 변경될 경우 결제 방식은 다음과
                            같습니다.
                        </div>
                        <ol class="second text--content_margin_small">
                            <li>
                                <div class="li_margin">
                                    월간 결제 : 정기 결제일에 해당하는 기본 서비스 이용료를 선결제하는 방식
                                </div>
                                <ol class="third braket_number">
                                    <li>기존 요금제가 다음 결제일까지 유지됩니다.</li>
                                    <li>다음 결제일부터 요금제가 변경되고 변경된 요금제의 이용 요금이 청구됩니다.</li>
                                </ol>
                            </li>

                            <li>
                                <div class="li_margin">요금제 하향 변경의 경우</div>
                                <ol class="third braket_number">
                                    <li>기존 요금제가 다음 결제일까지 유지됩니다.</li>
                                    <li>다음 결제일부터 요금제가 변경되고 변경된 요금제의 이용 요금이 청구됩니다.</li>
                                </ol>
                            </li>

                            <li class="p-bottom-margin">
                                <div class="li_margin">요금제 상향 변경의 경우</div>
                                <ol class="third braket_number">
                                    <li>
                                        변경한 요금제의 이용 요금이 변경 당일 청구되고 변경한 요금제의 모든 기능과 이용
                                        조건 등이 즉시 적용됩니다.
                                    </li>
                                    <li>
                                        변경한 날짜를 새로운 결제일로 하여 다음 결제일부터 변경한 요금제의 이용 요금이
                                        청구됩니다.
                                    </li>
                                    <li>기존 요금제의 잔여 사용기간에 대한 환불이나 보상은 하지 않습니다.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                </ol>

                <div class="text__title_small_chap">제15조 [환불]</div>
                <div class="text__content text--content_margin_small">
                    센터는 계약의 해제 또는 해지에 따라 회사에 환불을 요구할 수 있습니다. 환불은 회사가 안내하는 아래의
                    정책 및 방법 및 ‘콘텐츠 이용자 보호지침’ 및 관련 법령 규정에 따라 진행됩니다.
                </div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        결제 당일에는 서비스 이용 등 여부와 무관하게 전액 환불이 가능합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        결제 당일을 제외하고 결제일로부터 7일 이내에는 회원, 회원권, 수업, 스케줄, 강사 등록 및 예약 등
                        서비스 사용 이력이 없는 경우에만 환불이 가능합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        본조 2항에도 불구하고 센터 신규 생성 후 최초 결제시 30일 이내에는 환불이 가능합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        제 9조(서비스 제공) 4항에 따라 회사가 부득이하게 사업을 종료한 경우에도 본조 1~3항에 따른 환불만
                        가능합니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제16조 [센터 만료]</div>

                <ol class="first text--paragraph">
                    <li class="text__content text--content_margin">
                        제13조(이용료) 및 제14조(이용료 지불 및 결제방법)에 따른 유효한 이용권이 1년 이상 연속하여 없는
                        센터의 경우 센터가 만료되며 이 경우, 회사는 센터 관련 모든 정보를 삭제 할 수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        제13조(이용료) 및 제14조(이용료 지불 및 결제방법)에 따른 유효한 이용권이 있는 센터일지라도 본
                        센터 접속 기록이 1년 이상 연속하여 없는 경우, 회사는 센터 관련 모든 정보를 삭제 할 수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        제30조(무료 체험)에 따라 무료체험을 진행한 센터 중 제13조(이용료) 및 제14조(이용료 지불 및
                        결제방법)에 따라 최초 결제가 이뤄지지 않은 센터의 경우, 회사는 무료체험 기간 종료 후 센터 관련
                        모든 정보를 삭제 할 수 있습니다.
                    </li>
                </ol>

                <div class="text__title_large_chap title-top-margin">제3장 계약의 존속</div>

                <div class="text__title_small_chap">제17조 [계약 기간]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">계약은 약관에 동의한 날부터 효력을 가집니다.</li>
                    <li class="text__content text--content_margin">
                        당사자 일방이 제15조(환불) 또는 제20조(계약의 해지, 손해배상)를 원인으로 계약을 계속하기 어려운
                        경우를 제외하고 계약은 계속됩니다.
                    </li>
                </ol>

                <div class="text__title_small_chap">제18조 [계약의 변경]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        회사는 이 약관의 내용을 변경하고자 하는 경우 그 적용 예정일로부터 14일 전에 이메일을 통해
                        알립니다. 다만, 약관의 개정이 센터에게 불리한 내용을 담고 있는 경우에는 그 적용 예정일로부터
                        30일 전에 알려야 합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 전항의 공지에서 14일 이상의 기간을 정하고, 센터가 그 기간 내에 거부의 의사표시를 하지
                        않는 경우에는 개정된 약관에 동의한 것으로 봅니다.
                    </li>
                    <li class="text__content text--content_margin">
                        전항의 기간 내에 센터가 거부의 의사를 표시한 때에는 개정된 약관의 시행일에 계약이 해지된 것으로
                        봅니다. 이때, 센터는 그 의사표시와 동시에 계약을 해지할 수도 있습니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제19조 [계약 종료 시의 의무]</div>
                <div class="text__content text--paragraph">
                    회사가 센터를 위해 보관한 자료는 계약 종료 후 5영업일 내에 센터에게 반환합니다. 수령의 거부나 불능
                    등의 사유로 반환이 불가한 경우, 계약 종료일로부터 3개월이 경과한 이후 회사는 이를 파기할 수
                    있습니다.
                </div>

                <div class="text__title_large_chap title-top-margin">제4장 해지 및 손해배상</div>

                <div class="text__title_small_chap p-top-margin">제20조 [계약의 해지, 손해배상]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        센터는 계약의 해지를 요구할 수 있습니다. 회사는 센터의 요구에 필요한 절차를 안내해야 합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        당사자 일방이 계약에 규정된 의무를 위반하거나 불성실하게 이행하는 경우, 상대방은 그 시정이나
                        개선을 요구할 수 있으며, 그에 대하여 14일 이내에 귀책 당사자의 시정 또는 개선 조치가 없는 경우에
                        상대방은 전자우편 또는 서면에 의한 통지로써 이 약관을 해지할 수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        <div class="text--content_margin_small">
                            다음과 같은 사유가 있는 경우 상대방은 최고 없이 계약을 해지할 수 있습니다.
                        </div>
                        <ol class="second">
                            <li>당사자 일방에게 화의(和議), 회사 정리 절차의 개시 또는 파산의 신청이 있는 경우</li>
                            <li>당사자 일방이 발행한 어음이나 수표가 지급 정지된 경우</li>
                            <li>
                                당사자 일방이 부도 또는 지급 불능 등으로 인하여 사업의 정상적인 운영이 어렵다고 판단되는
                                경우
                            </li>
                            <li>
                                센터가 솔루션을 통한 자료의 제공을 상습적으로 해태(懈怠)하거나 2개월 이상 연락이 두절된
                                경우
                            </li>
                            <li>
                                센터가 회사에 지속적으로 악의적인 민원을 제기하고 문제가 해결된 후에도 지속적으로 민원을
                                제기하는 경우
                            </li>
                            <li>제9조(서비스 제공) 3항의 2호 내지 3호의 중단 사유가 발생하는 경우</li>
                            <li>
                                일방 당사자가 계약을 위반하고 이로 인해 계약의 목적을 달성할 수 없다고 객관적으로
                                인정되는 경우
                            </li>
                        </ol>
                    </li>
                    <li class="text__content text--content_margin">
                        본 조에 따른 해지의 효력은 이미 발생한 권리 관계 및 일방의 귀책사유로 인한 손해배상 청구권 등에
                        영향을 미치지 않습니다.
                    </li>
                </ol>

                <div class="text__title_large_chap title-top-margin">제 5장 기타</div>

                <div class="text__title_small_chap p-top-margin">제21조 [통지의 방법]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        이 약관에 따라 센터에게 일정한 사항을 알릴 때는 원칙적으로 센터가 등록한 전자우편을 사용합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        전항에도 불구하고 센터 전체를 대상으로 하거나 긴급한 사유가 발생한 경우에는 SMS 또는 서비스 내
                        공지사항을 통하여 알릴 수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 센터의 고의 또는 과실로 본조의 통지를 인지하지 못한 경우에 발생하는 손해에 대하여 책임을
                        지지 않습니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제22조 [비밀 유지 의무]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        각 당사자는 이 약관의 체결 및 이행 과정에서 취득한 상대방의 영업 비밀에 대하여, 계약 기간 중은
                        물론이고 계약의 종료 후에도 제3자에게 유출하거나 다른 목적에 이용해서는 안 됩니다.
                    </li>
                    <li class="text__content text--content_margin">
                        전항을 위반하여 상대방에게 손해가 발생한 경우, 손해를 입은 당사자는 그 상대방에게 손해배상을
                        청구할 수 있습니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제23조 [통지 의무]</div>
                <div class="text__content text--paragraph">
                    각 당사자는 이 약관에 규정된 사항 이외에도 계약의 이행에 중대한 영향을 미칠 수 있는 사유가 예견되는
                    경우 지체 없이 상대방에게 통지하여야 합니다.
                </div>

                <div class="text__title_small_chap p-top-margin">제24조 [권리, 의무의 양도 금지]</div>
                <div class="text__content text--paragraph">
                    각 당사자는 상대방의 서면 동의 없이 이 약관상 권리 또는 의무를 제3자에게 양도하거나 이전할 수
                    없습니다.
                </div>

                <div class="text__title_small_chap p-top-margin">제25조 [분쟁 해결]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        이 약관과 관련하여 분쟁이 발생하는 경우 각 당사자는 원만한 해결을 위하여 서로 협력합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        당사자 간에 제기된 소송은 대한민국법을 준거법으로 하며, 부산지방법원이 배타적인 제1심 관할권을
                        갖습니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제26조 [책임 제한]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        회사는 센터의 약관, 서비스 이용 방법 및 이용 기준을 준수하지 않는 등 센터의 귀책사유로 인한
                        서비스 이용의 장애에 대하여 책임을 지지 않습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 서비스를 통하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여 보증하지
                        않습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 센터 간 또는 센터와 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이
                        면제됩니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없으면 책임을 지지
                        않습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 천재지변, 전쟁, 기간통신사업자의 서비스 중지, 제3자가 제공하는 오픈 아이디의 인증 장애,
                        해결이 곤란한 기술적 결함 및 기타 불가항력으로 인하여 서비스를 제공할 수 없는 경우 책임이
                        면제됩니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 사전에 공지된 서비스용 설비의 보수, 교체, 정기 점검, 공사 등 부득이한 사유로 서비스가
                        중지되거나 장애가 발생한 경우에 대해서 책임이 면제됩니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 기간통신업자가 전기 통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한
                        경우에 책임이 면제됩니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 센터의 컴퓨터 오류, 신상 정보 및 전자우편 주소의 부정확한 기재, 비밀번호 관리의 소홀 등
                        센터의 귀책사유로 인해 손해가 발생한 경우 회사는 책임을 지지 않습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 센터의 컴퓨터 환경이나 회사의 관리 범위에 있지 아니한 보안 문제로 인하여 발생하는 제반
                        문제 또는 현재의 보안 기술 수준으로 방어가 곤란한 네트워크 해킹 등 회사의 귀책사유 없이 발생하는
                        문제에 대해서 책임을 지지 않습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 서비스가 제공한 내용에 대한 중요 정보의 정확성, 내용, 완전성, 적법성, 신뢰성 등에 대하여
                        보증하거나 책임을 지지 않으며, 사이트의 삭제, 저장 실패, 잘못된 인도, 정보에 제공에 대한
                        궁극적인 책임을 지지 않습니다. 또한, 회사는 센터가 서비스 내 또는 웹사이트 상에 게시 또는 전송한
                        정보, 자료, 사실의 신뢰도, 정확성, 완결성, 품질 등 내용에 대해서 책임을 지지 않습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 센터 상호 간 또는 센터와 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할
                        의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 센터가 서비스를 이용하여 기대하는 효용을 얻지 못한 것에 대하여 책임을 지지 않으며
                        서비스에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서 책임이 면제됩니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제27조 [약관의 해석과 예외 준칙]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        회사는 제공하는 개별 서비스에 대해서 별도의 이용 약관 및 정책을 둘 수 있으며, 해당 내용이 이
                        약관과 상충할 경우 개별 서비스의 이용 약관을 우선하여 적용합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        본 약관에 명시되지 않은 사항이 관계 법령에 규정되어 있을 경우에는 그 규정에 따릅니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제28조 [준거법 및 재판 관할]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        회사와 센터 간 제기된 소송에는 대한민국법을 준거법으로 합니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사와 센터 간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할 법원에 제소합니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제29조 [마케팅 정보 수신 동의]</div>
                <div class="text__content text--paragraph">
                    레드웨일 서비스 회원가입 과정에서 진행하는 마케팅 정보 수신 동의 여부는 회사에서 직접 전송하는
                    광고성 정보 수신 동의 여부에 대한 의사를 표시한 것으로 간주합니다. 레드웨일 서비스를 이용하는
                    센터에서 발송하는 마케팅 정보에 대해서는 080 광고성 정보 수신 거부 서비스를 통해 거부 의사를 표시할
                    수 있습니다.
                </div>

                <div class="text__title_small_chap p-top-margin">제30조 [무료 체험]</div>
                <ol class="first">
                    <li class="text__content text--content_margin">
                        회사에서 제공하는 서비스의 일부에 대해 신규 센터에서 체험해보도록 하기 위한 목적입니다.
                    </li>
                    <li class="text__content text--content_margin">
                        무료 체험 자격 요건은 신규 생성된 센터에 한하며 이용 기간은 회사에서 정한 기간을 따릅니다.
                        회사는 해당 무료 체험 관련 자격 요건이나 이용 기간을 재량껏 변경할 수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        무료 체험 서비스를 남용, 범용, 악용하는 등의 부정 이용을 할 경우 무료 체험의 제한 및 향후
                        레드웨일 관련 서비스 이용이 제한될 수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        회사는 센터에서 무료 체험 자격을 갖추지 않은 것으로 확인될 경우 무료 체험을 철회하고 계정을 보류
                        조치할 권리를 보유합니다. 회사는 기존에 사용 중이거나 최근 가입 시 사용한 디바이스 ID, 결제
                        수단, 이메일 주소 등과 같은 정보를 무료 체험 자격 판단에 활용할 수 있습니다. 다른 프로모션과
                        함께 사용할 경우 특정 제한이 가해질 수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        무료 체험 기간이 끝나면 제공받던 무료 체험 관련 서비스 일체가 중단되며 제14조에 따라 요금 결제를
                        통해 서비스를 다시 이어서 제공 받을 수 있습니다.
                    </li>
                    <li class="text__content text--content_margin">
                        무료 체험 기간 동안 결제한 일부 부가 서비스에 대해서는 각 부가 서비스 환불 규정을 따릅니다.
                    </li>
                </ol>

                <div class="text__title_small_chap p-top-margin">제31조 [데이터의 소유권]</div>
                <div class="text__content text--paragraph">
                    회원이 서비스를 이용하며 발생하는 인구 통계학적 정보 등 데이터, 회원의 서비스 사용 행태에 따라
                    수집된 데이터 및 이를 가공한 데이터 등 회원의 서비스 이용 과정에서 발생한 모든 데이터에 대한
                    소유권은 회사에 귀속됩니다.
                </div>

                <div class="text__title_small_chap p-top-margin">제32조 [데이터 제한]</div>
                <div class="text__content text--paragraph">
                    회사의 원활한 서비스 제공을 위해 이미지, 영상 등 데이터의 보관 또는 사용량에 따라 회사 재량에 따라
                    제한을 둘 수 있으며 해당 제한사항은 기존 사용중인 요금제가 있는 센터와 무료체험 중인 센터에도 함께
                    일괄 적용이 됩니다. 단, 제한을 새로 두거나 제한 조건이 변경될 경우 제21조(통지의 방법)에 따라 사전에
                    통지해야 합니다.
                </div>
            </div>
        </div>
    </div>
</section>
