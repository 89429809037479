<div class="l-text-field">
    <div class="label" *ngIf="labelVisible">
        {{ label }} <span class="red" *ngIf="isImportant">*</span>
        <div
            *ngIf="advice"
            class="info-icon"
            rwTooltip
            rwTooltipPlacement="top"
            [showTail]="true"
            [rwTooltipTitle]="advice"
        ></div>
    </div>
    <div
        class="l-input"
        (mouseover)="isMouseOn = true"
        (mouseleave)="isMouseOn = false"
        (mousedown)="isMouseDown = true"
        (mouseup)="isMouseDown = false"

    >
        <div *ngIf="textFieldType == 'search'" class="search-icon"></div>
        <input
            #input
            autocomplete="one-time-code"
            [type]="textFieldType == 'password' && passwordVisible == false ? 'password' : 'text'"
            [maxlength]="maxLength"
            [style.width]="width"
            [style.height]="height"
            [class.password]="textFieldType == 'password'"
            [class.search]="textFieldType == 'search'"
            [class.warning]="status == 'warning'"
            [class.error]="status == 'error'"
            [class.success]="status == 'success'"
            [class.type-word-limit]="type == 'wordLimit'"
            [class.type-time-limit]="type == 'timeLimit'"
            [class.still-focus]="isMouseDown && isMouseOn"
            [placeholder]="placeholder"
            [formControl]="textField"
            (focus)="onFocus()"
            (focusout)="onFocusOut()"
            (rwClickOutside)="isMouseOn = false; isMouseDown = false"
        />
        <div class="l-input-tag" (click)="input.focus()">
            <div *ngIf="type == 'normal'" class="input-tag-normal">
                <rwa-svg-close-fill
                    *ngIf="!textField.disabled && textField.value?.length > 0 && isFocused"
                    [width]="'24px'"
                    [height]="'24px'"
                    [margin]="'0'"
                    (click)="resetTextField()"
                ></rwa-svg-close-fill>
                <rwa-svg-warning
                    *ngIf="status == 'error' || status == 'warning'"
                    [width]="'24px'"
                    [height]="'24px'"
                    [color]="status == 'warning' ? 'var(--state-warning-100)' : 'var(--state-error-100)'"
                ></rwa-svg-warning>
                <rwa-svg-check *ngIf="status == 'success'" [color]="'var(--state-success-100)'"></rwa-svg-check>
                <div
                    class="pw-visible-icon"
                    *ngIf="textFieldType == 'password' && passwordVisible && textField.value?.length > 0"
                    (click)="passwordVisible = false"
                ></div>
                <div
                    class="pw-invisible-icon"
                    *ngIf="textFieldType == 'password' && !passwordVisible && textField.value?.length > 0"
                    (click)="passwordVisible = true"
                ></div>
            </div>
            <div
                *ngIf="type == 'wordLimit'"
                class="input-tag-word"
                [class.bold]="status == 'warning' || status == 'error'"
            >
                <span *ngIf="status != 'success'"
                    >{{ textField?.value?.length ?? '0' }} / {{ maxLength | padStart : 2 : '0' }}</span
                >
                <rwa-svg-close-fill
                    *ngIf="!textField.disabled && textField.value?.length > 0 && isFocused"
                    [margin]="'0'"
                    [width]="'24px'"
                    [height]="'24px'"
                    (click)="resetTextField()"
                ></rwa-svg-close-fill>
                <rwa-svg-warning
                    *ngIf="status == 'error' || status == 'warning'"
                    [width]="'24px'"
                    [height]="'24px'"
                    [color]="status == 'warning' ? 'var(--state-warning-100)' : 'var(--state-error-100)'"
                ></rwa-svg-warning>
                <rwa-svg-check *ngIf="status == 'success'" [color]="'var(--state-success-100)'"></rwa-svg-check>
            </div>
            <div *ngIf="type == 'timeLimit'" class="input-tag-time">
                <span *ngIf="status != 'success'">{{ timeLimit | minuteSeconds }}</span>
                <rwa-svg-close-fill
                    *ngIf="textField.value?.length > 0 && isFocused"
                    [margin]="'0'"
                    [width]="'24px'"
                    [height]="'24px'"
                    (click)="resetTextField()"
                ></rwa-svg-close-fill>
                <rwa-svg-warning
                    *ngIf="status == 'error' || status == 'warning'"
                    [width]="'24px'"
                    [height]="'24px'"
                    [color]="status == 'warning' ? 'var(--state-warning-100)' : 'var(--state-error-100)'"
                ></rwa-svg-warning>
                <rwa-svg-check *ngIf="status == 'success'" [color]="'var(--state-success-100)'"></rwa-svg-check>
            </div>
        </div>

        <div *ngIf="(status == 'none' || status == 'success') && hint" class="hint">
            <span class="info-icon"></span>{{ hint }}
        </div>
        <div
            *ngIf="status == 'warning' || status == 'error'"
            class="status"
            [class.warning]="status == 'warning'"
            [class.error]="status == 'error'"
        >
            {{ statusText }}
        </div>
    </div>
</div>
