<button
    #l_button
    class="text-button"
    [class.underline]="showUnderLine"
    [style.font-size]="fontSize"
    [style.line-height]="lineHeight"
    [style.font-weight]="fontWeight"
    [style.color]="disable ? disableColor : color"
    [style.text-decoration-color]="disable ? disableColor : color"
    [disabled]="disable"
    (click)="_onClick()"
    (mousedown)="onMouseDown()"
    (mouseup)="onMouseUp()"
    (focusin)="onFocus()"
    (focusout)="onFocusOut()"
    (mouseover)="onHover()"
    (mouseleave)="onHoverOut()"
>
    <ng-content></ng-content>
</button>
